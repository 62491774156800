import React from 'react';

import { Card, Col, Image, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import DetalleReserva from '../../utils/detalleReserva';
import formatNumber from '../../utils/formatNumber';

const CardCondos = ({
  t,
  item,
  huespedesGet,
  fechaStar,
  fechaEnd,
  onHandleClickCard,
  handleOnHoverItem,
  handleOnHoverOutItem,
}) => {
  return (
    <Card className="shadow-sm mb-3 card-hover">
      <Card.Body className="py-1">
        <Row className="align-items-center">
          <Col xs="12" lg="3">
            <div className="text-center clicked">
              <Image
                src={item.urlPhoto ? item.urlPhoto : '/images/no-image.jpg'}
                fluid
                className="card-img-max-height"
                onClick={() => onHandleClickCard(item.id, item.source)}
              />
            </div>
          </Col>
          <Col xs="12" lg="6">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <h3
              className="clicked"
              onClick={() => onHandleClickCard(item.id, item.source)}
              onMouseOver={() => handleOnHoverItem(item)}
              onMouseOut={handleOnHoverOutItem}
            >
              {item.name}
            </h3>
            <label className="lh-1-1">
              {item.description != null && item.description.length > 180
                ? `${item.description.substr(0, 180)}...`
                : item.description}
            </label>
          </Col>
          <Col xs="12" lg="3">
            <div className="text-right">
              <div>
                <span className="text-price">
                  {formatNumber(item.total)} USD{' '}
                  <small className="text-dark ft-1rem" />
                </span>
              </div>
              <label className="lh-1 my-2">
                <DetalleReserva
                  rooms={huespedesGet}
                  fechaStar={fechaStar}
                  fechaEnd={fechaEnd}
                />
              </label>
              <label>{t('ImpuestosIncluidos')}</label>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default withTranslation()(CardCondos);
