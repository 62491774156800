import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { MdPeople } from 'react-icons/md';

const SelectPerson = ({ adult, setAdult, setChild, text }) => {
  const [show, setShow] = useState(false);
  const [texto, setTexto] = useState(text);
  const opt = adult
    ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const onHandleClick = item => {
    // console.log(item)
    if (adult) {
      setAdult(item);
    } else {
      setChild(item);
    }
    setTexto(item);
    setShow(false);
  };

  return (
    <Dropdown
      className="filter"
      show={show}
      onToggle={() => setShow(!show)}
      drop="down"
    >
      <Dropdown.Toggle variant="success">
        <MdPeople className="text-white mr-2 icon-24" /> <span>{texto}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100 rounded-0" flip={false}>
        {opt.map(item => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            className="py-1 px-3 cursor-pointer"
            key={item}
            onClick={() => onHandleClick(item)}
          >
            {item}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectPerson;
