import React, { useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import propsMaps from '../../data/sportingEvents/map.json';
import useSportingEvents from '../../hook/useSportingEvents';
import sectionImg from '../../img/sporting-events/nfl_min@2x.png';
import CargandoOpciones from '../../loader/CargandoOpciones';
import FormSidebar from '../../shared/FormSidebar';
import MapSidebar from '../MapSidebar';
import Pagination from '../PaginationCruises';
import SectionTop from '../SectionTop';
import CardTicket from './CardTicket';
import Filters from './Filters';
import FormSearch from './FormSearch';

const Detail = () => {
  const { t } = useTranslation();
  const { query } = useSportingEvents();
  const { path } = useRouteMatch();
  const showTickets = !useRouteMatch({ path, exact: true });
  // console.log('🚀 ~ Detail.js', { path, url, showTickets });

  const [loading, setLoading] = useState(false);

  const [center, setCenter] = useState(propsMaps.center);
  const [position, setPosition] = useState(propsMaps.position);
  const [events, setEvents] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [controlhed] = useState(0);
  const [dollar, setDollar] = useState(0);

  const addData = (array, data) => {
    const newArrey = array;
    for (let i = 0; i < newArrey.length; i++) {
      newArrey[i].data = data;
    }
    return newArrey;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setLoading(false);

    const fetch = async () => {
      if (query) {
        setDollar(query.dollar);
        setLoading(true);
        const dataEnvts = {
          sportEvent: true,
          caption: query.caption,
          country: query.country,
          city: query.city,
          sport: query.sport,
          time_of_event: query.time_of_event,
          date: query.date,
          date_of_event: query.date_of_event,
          venue: query.venue,
          venue_address: query.venue_address,
          final_date: query.final_date,
          final_time: query.final_time,
          dolla: query.dollar,
        };
        const newEvents = addData(query.ticketdata, dataEnvts);
        setEvents(newEvents);

        /* const response = await getEvents(query);
        const { data, control } = response;
        if (control.totalrecords >= 1) {
          setEvents(data);
        }
        setControlhed(control); */
        setLoading(false);
      }
    };

    fetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <CargandoOpciones marginTop />;

  return (
    <>
      <SectionTop
        menu={t('Eventos Deportivos', { count: query.length || 0 })}
        destino={controlhed.title || t('Eventos Deportivos')}
        total={query.length || 0}
        product={t('Eventos Deportivos', {
          count: query.length || 0,
        })}
      />
      <Container fluid className="mt-3">
        <Row>
          <Col xs="12" lg="4">
            <Row className="justify-content-md-center">
              <FormSidebar>
                <FormSearch query={query} inline />
              </FormSidebar>
            </Row>
            {showTickets ? (
              <Row className="justify-content-md-center">
                <img src={sectionImg} className="img-fluid" alt="test" />
              </Row>
            ) : (
              <Row className="justify-content-center my-4">
                <Col className="px-4">
                  <MapSidebar
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...propsMaps}
                    containerHeight="911px"
                    position={position}
                    setPosition={setPosition}
                    center={center}
                    setCenter={setCenter}
                  />
                </Col>
              </Row>
            )}

            <Row className="justify-content-center my-4">
              <Col className="px-5">
                <Filters />
              </Col>
            </Row>
          </Col>

          <Col xs="12" lg="8">
            <Pagination
              items={events}
              page={currentPage}
              onChange={setCurrentPage}
            >
              {item => <CardTicket item={item} dolar={dollar} />}
            </Pagination>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Detail;
