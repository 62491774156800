import React, { useState } from 'react';

import { Button, Modal } from 'react-bootstrap';
import SectionPromotionNY from '../components/SectionPromotionNY';
import FormRedemption from '../components/depositYourWeek/FormRedemption';
import FormSearch from '../components/depositYourWeek/FormSearch';
import HeadImage from '../img/a95852e8cb5e6282dd0863aaa761351a.png';
import PriceBottom from '../shared/PriceBottom';

import '../css/condos/CondosList.css';

const DepositYourWeek = () => {
  const [values, setValues] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getValues = () => {
    return setValues({
      rewards: 7500,
      exchange: 10000,
      marketplace: 'Cash',
    });
  };

  return (
    <>
      <div className="!h-[450px] !w-full bg-slate-700 relative">
        <img
          src={HeadImage}
          alt=""
          className="!w-full h-full object-cover  absolute"
        />
        <div className="!w-full !flex h-full flex-col px-48 justify-center align-middle absolute bg-black/40">
          <div className="!text-center text-white font-bold text-4xl">
            Week Information
          </div>
          <div className="!pt-10">
            <FormSearch onSubmit={getValues} />
          </div>
        </div>
      </div>
      <div className="!p-10 flex space-x-10 justify-center">
        <div className="!bg-white shadow-md rounded-sm w-[300px]">
          <div className="!bg-sky-500 text-center py-3 px-3 rounded-sm text-white font-bold text-lg">
            Reward Credit
          </div>
          <div className="!p-10 text-center">
            <div className="!text-sm text-gray-600 mb-2">You recibe:</div>
            <div className="!text-md font-bold mb-2">
              {' '}
              <span className="text-gray-600 mb-2 text-lg font-bold text-blue-600">
                {' '}
                {values.rewards}
              </span>{' '}
              Reward Credit
            </div>
            <div className="!text-sm mb-2 ">
              Redeem for hotels, cars, cruises, airfare, tickets, gift cards,
              and more! Reward credits are valid for 18 months Deposit within 7
              - 12 months of arrival Only $75.00 USD per deposits
            </div>
            <div>
              {/* <button
                type="button"
                alt=""
                className="!mt-4 text-sm border-1 border-blue-400 rounded-sm py-1 px-4 bg-white text-blue-400"
              >
                View FAQs
              </button> */}
            </div>
            <div>
              {values.rewards && (
                <button
                  type="button"
                  alt=""
                  className="!mt-4 text-sm border-0 rounded-sm py-2 px-4 bg-blue-600 hover:bg-blue-800 text-white"
                  onClick={handleShow}
                >
                  Deposit Now
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="!bg-white shadow-md rounded-sm w-[300px]">
          <div className="!bg-sky-600 text-center py-3 px-3 rounded-sm text-white font-bold text-lg">
            Timeshare Exchange
          </div>
          <div className="!p-10 text-center">
            <div className="!text-sm text-gray-600 mb-2">You recibe:</div>

            <div className="!text-md font-bold mb-2">
              <span className="text-gray-600 mb-2 text-lg font-bold text-blue-600">
                {' '}
                {values.exchange}
              </span>{' '}
              Timeshare Exchange
            </div>
            <div className="!text-sm mb-2 ">
              Exchange the week you have for the week you want! View inventory
              Not ready to travel yet? Exchange Credits are valid for 5 years
              Deposit 60 days prior to arrival No exchange fees
            </div>
            <div>
              {/* <button
                type="button"
                alt=""
                className="!mt-4 text-sm border-1 border-blue-400 rounded-sm py-1 px-4 bg-white text-blue-400"
              >
                View FAQs
              </button> */}
            </div>
            <div>
              {values.exchange && (
                <button
                  type="button"
                  alt=""
                  className="!mt-4 text-sm border-0 rounded-sm py-2 px-4 bg-blue-600 hover:bg-blue-800 text-white"
                  onClick={handleShow}
                >
                  Exchange Now
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="!bg-white shadow-md rounded-sm w-[300px]">
          <div className="!bg-sky-700 text-center py-3 px-3 rounded-sm text-white font-bold text-lg">
            Marketplace
          </div>
          <div className="!p-10 text-center">
            <div className="!text-sm text-gray-600 mb-2">You recibe:</div>
            <div className="!text-md font-bold mb-2">Cash</div>
            <div className="!text-sm mb-2 ">
              List your week for rent. And set your own price! Hassle-free! We
              collect the money and pay you cash quickly Receive multiple birds
              on your week Only 10% fee of the total collected
            </div>
            <div>
              {/* <button
                type="button"
                alt=""
                className="!mt-4 text-sm border-1 border-blue-400 rounded-sm py-1 px-4 bg-white text-blue-400"
              >
                View FAQs
              </button> */}
            </div>
            <div>
              <button
                type="button"
                alt=""
                className="!mt-4 text-sm border-0 rounded-sm py-2 px-4 bg-blue-600 hover:bg-blue-800 text-white"
                onClick={handleShow}
              >
                List Your Week Now
              </button>
            </div>
          </div>
        </div>
      </div>

      <SectionPromotionNY />
      <PriceBottom />

      <Modal size="lg" show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Owner Information</Modal.Title>
        </Modal.Header>
        <Modal.Body scrollable>
          <FormRedemption />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="outline-primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DepositYourWeek;
