import axios from 'axios';

const urlLogin = `${process.env.REACT_APP_URL_LOGIN}/addJustGoPoints?`;

const addPoints = async data => {
  // llamada al metodo agregar puntos
  await axios.get(
    `${urlLogin}MEMBERSHIP=${data.membership}&DESCRIPTION=${data.describe}&POINTS=${data.points}&FECHA=20220507&PAX=2&DIAS=1&FMT=JSON&APPNAME=JUSTGONOW&PUBLICKEY=eefe10fbd84d43affb36c50d35e7e06e`,
  );
};

export default addPoints;
