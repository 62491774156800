import React from 'react';

import { Card, Col, Row } from 'react-bootstrap';
import PriceFormat from '../PriceFormat';
import styles from './CardOfferAnotherPrice.module.scss';

const CardOfferAnotherPrice = ({ offer }) => {
  return (
    <Card className={styles.cardOffer}>
      <Card.Body className="py-1">
        <Row xs="1">
          <Col className="text-center m-0 p-0">
            <span className={styles.offerVendor}>{offer.vendor}</span>
          </Col>

          <Col className="text-center m-0 p-0">
            <PriceFormat
              currency={offer.currency}
              value={offer.price}
              decimalScale={0}
            >
              {price => <span className={styles.offerPrice}>{price}</span>}
            </PriceFormat>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CardOfferAnotherPrice;
