import React from 'react';

import moment from 'moment';
import { Card, Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';

const ReservaCardTopEvent = ({ t, item }) => {
  const f1 = moment(item.checkIn, 'DD-MM-YYYY');

  return (
    <Card className="text-light mb-2">
      <Card.Body>
        <Row>
          <Col xs="12" lg="12">
            <h4>{t('YourReservationDetail')}</h4>
            <div className="d-flex justify-content-between">
              <div>
                <label className="d-block mb-0">
                  <FaCalendarAlt className="icon-14 mb-1 mr-2" />
                  {t('Fecha')}
                </label>
                <label className="d-block ft-08rem mb-0">
                  {moment(f1).format('dd, DD MMM, YYYY')}
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <label className="d-block mb-2">{item.sport}</label>
            <label className="d-block mb-2">{item.apiName}</label>
            <label className="d-block mb-0  ft-08rem">{item.direccion}</label>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default withTranslation()(ReservaCardTopEvent);
