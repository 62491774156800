/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';

import '../css/Voucher.css';
import appstore from '../img/voucher/appstore.png'; // with import
import bg1 from '../img/voucher/bg_1.jpg'; // with import
import booking from '../img/voucher/booking.png'; // with import
import checked from '../img/voucher/checked.png'; // with import
import contact from '../img/voucher/contact.png'; // with import
import googleplay from '../img/voucher/googleplay.png'; // with import
import payment from '../img/voucher/payment.png'; // with import
import unnamed1 from '../img/voucher/unnamed-1.png'; // with import

const Voucher = ({ name, destination, noBooking, children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    // console.log(infoReserva);
  }, []);

  return (
    <div className="">
      <center
        style={{ width: '100%', backgroundColor: '#f1f1f1' }}
        className="!pt-32"
      >
        <div
          style={{
            display: 'none',

            fontSize: '1px',

            maxHeight: '0px',

            maxWidth: '0px',

            opacity: 0,

            overflow: 'hidden',

            msoHide: 'all',

            fontFamily: 'sans-serif',
          }}
        />

        <div
          style={{ maxWidth: '800px', margin: '0 auto' }}
          className="email-container"
        >
          {/* BEGIN BODY */}

          <table
            align="center"
            role="presentation"
            cellSpacing={0}
            cellPadding={0}
            border={0}
            width="100%"
            style={{ margin: 'auto' }}
          >
            <tbody>
              {/* end tr */}

              <tr>
                <td
                  valign="middle"
                  className="hero bg_dark"
                  style={{ padding: '2em 0 2em 0' }}
                >
                  <table
                    role="presentation"
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td style={{ padding: '0 2.5em', textAlign: 'left' }}>
                          <div className="text">
                            <h2 style={{ color: 'aliceblue' }}>
                              Thanks {name}! Your booking at {destination} is
                              confirmed
                            </h2>

                            <h3 style={{ color: 'aliceblue' }}>
                              Your booking #{noBooking}
                            </h3>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              {/* end tr */}
            </tbody>
          </table>

          {children}

          {/* end tr */}

          {/* APP */}

          <table
            className="bg_white"
            style={{ borderTop: '.5px solid #B0B0B0' }}
          >
            <tbody>
              <tr>
                <td
                  className="bg_white email-section"
                  style={{ width: '100%' }}
                >
                  <table
                    role="presentation"
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td valign="middle" width="50%">
                          <table
                            role="presentation"
                            cellSpacing={0}
                            cellPadding={0}
                            border={0}
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <img
                                    src={bg1}
                                    alt=""
                                    style={{
                                      width: '100%',

                                      maxWidth: '600px',

                                      height: 'auto',

                                      margin: 'auto',

                                      display: 'block',
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>

                        <td valign="middle" width="50%">
                          <table
                            role="presentation"
                            cellSpacing={0}
                            cellPadding={0}
                            border={0}
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td
                                  className="text-services"
                                  style={{
                                    textAlign: 'left',

                                    paddingLeft: '25px',
                                  }}
                                >
                                  <div className="heading-section">
                                    <h2>Nuestra APP</h2>
                                  </div>

                                  <div className="services-list">
                                    <img
                                      src={checked}
                                      alt=""
                                      style={{
                                        width: '50px',

                                        maxWidth: '600px',

                                        height: 'auto',

                                        display: 'block',
                                      }}
                                    />

                                    <div className="text">
                                      <h3>Make your trip easy with the app</h3>

                                      <p>
                                        Change or cancel bookings on the go,
                                        chat directly with your property, and
                                        much more.
                                      </p>

                                      <p style={{ marginTop: '20px' }}>
                                        <img src={appstore} />

                                        <a href="#" />

                                        <img src={googleplay} />

                                        <a href="#" />
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

          <table
            className="bg_white"
            role="presentation"
            cellSpacing={0}
            cellPadding={0}
            border={0}
            width="100%"
            style={{ borderTop: '.5px solid #B0B0B0' }}
          >
            <tbody>
              <tr style={{ borderBottom: '1px solid rgba(0,0,0,.05)' }}>
                <td
                  className="text-services"
                  style={{ textAlign: 'left', paddingLeft: '25px' }}
                >
                  <div className="heading-section" />

                  <div className="services-list">
                    <img
                      src={unnamed1}
                      alt=""
                      style={{
                        width: '50px',

                        maxWidth: '600px',

                        height: 'auto',

                        display: 'block',
                      }}
                    />

                    <div className="text">
                      <h2>Important Details</h2>

                      <ul>
                        <li>
                          You will pay the hotel in the hotel’s local currency
                          (MXN) at the exchange rate on day of payment.
                        </li>

                        <li>16 % VAT is excluded.</li>

                        <li>MXN 37 Environment fee per night is excluded.</li>

                        <li>3 % Tourism fee is excluded.</li>

                        <li>
                          In response to Coronavirus (COVID-19), additional
                          safety and sanitation measures are currently in effect
                          at this property.
                        </li>

                        <li>
                          Food &amp; beverage services at this property may be
                          limited or unavailable due to Coronavirus (COVID-19).
                        </li>

                        <li>
                          Due to Coronavirus (COVID-19), this property has
                          reduced reception and service operating hours.
                        </li>

                        <li>
                          Due to Coronavirus (COVID-19), this property adheres
                          to strict physical distancing measures.
                        </li>

                        <li>
                          Guests are required to show a photo identification and
                          credit card upon check-in. Please note that all
                          Special Requests are subject to availability and
                          additional charges may apply.
                        </li>
                      </ul>

                      <a href="#" className="btn btn-primary2">
                        Get the app to show more details »&gt;
                      </a>

                      <p />
                    </div>
                  </div>

                  <div className="services-list">
                    <img
                      src={payment}
                      alt=""
                      style={{
                        width: '50px',

                        maxWidth: '600px',

                        height: 'auto',

                        display: 'block',
                      }}
                    />

                    <div className="text">
                      <h2>Payment</h2>

                      <ul>
                        <li>
                          You have now confirmed and guaranteed your reservation
                          by credit card.
                        </li>

                        <li>
                          This accommodation provider accepts the following
                          forms of payment: <b>Visa, Euro/Mastercard</b>
                        </li>
                      </ul>

                      <a href="#" className="btn btn-primary2">
                        Get the app to show more details »&gt;
                      </a>

                      <p />
                    </div>
                  </div>

                  <div className="services-list">
                    <img
                      src={booking}
                      alt=""
                      style={{
                        width: '50px',

                        maxWidth: '600px',

                        height: 'auto',

                        display: 'block',
                      }}
                    />

                    <div className="text">
                      <h2>Make changes to your booking</h2>

                      <p>
                        It’s easy to modify or cancel your reservation.
                        Depending on the type of booking you made, cancellation
                        fees may apply.
                      </p>

                      <a href="#" className="btn btn-primary2">
                        Get the app to show more details »&gt;
                      </a>

                      <p />
                    </div>
                  </div>

                  <div className="services-list">
                    <img
                      src={contact}
                      alt=""
                      style={{
                        width: '50px',

                        maxWidth: '600px',

                        height: 'auto',

                        display: 'block',
                      }}
                    />

                    <div className="text">
                      <h2>Contact your property</h2>

                      <p>
                        Have a question or special request? You can chat with
                        your property on the app.
                      </p>

                      <a href="#" className="btn btn-primary2">
                        Get the app to show more details »&gt;
                      </a>

                      <p />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          {/* end: tr */}

          {/* 1 Column Text + Button : END */}

          <table
            align="center"
            role="presentation"
            cellSpacing={0}
            cellPadding={0}
            border={0}
            width="100%"
            style={{ margin: 'auto' }}
          >
            <tbody>
              <tr>
                <td valign="middle" className="bg_light footer email-section">
                  <table>
                    <tbody>
                      <tr>
                        <td
                          valign="top"
                          width="33.333%"
                          style={{ paddingTop: '20px' }}
                        >
                          <table
                            role="presentation"
                            cellSpacing={0}
                            cellPadding={0}
                            border={0}
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    textAlign: 'left',

                                    paddingLeft: '5px',

                                    paddingRight: '5px',
                                  }}
                                >
                                  <h3 className="heading">
                                    Atención al cliente
                                  </h3>

                                  <ul>
                                    <li>
                                      <span className="text">
                                        203 Fake St. Mountain View, San
                                        Francisco, California, USA
                                      </span>
                                    </li>

                                    <li>
                                      <span className="text">
                                        +2 392 3929 210
                                      </span>
                                    </li>

                                    <li>
                                      <img src={appstore} alt="" />

                                      <a href="#" />

                                      <img src={googleplay} alt="" />

                                      <a href="#" />
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              {/* end: tr */}
            </tbody>
          </table>
        </div>
      </center>
    </div>
  );
};

export default Voucher;
