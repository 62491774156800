import moment from 'moment';

const months =
  'Enero,Febrero,Marzo,Abril,Mayo,Junio,Julio,Agosto,Septiembre,Octubre,Noviembre,Diciembre';
const monthsShort =
  'Ene.,Feb.,Mar.,Abr.,May.,Jun.,Jul.,Ago.,Sep.,Oct.,Nov.,Dic.';
const weekdaysShort = 'Dom.,Lun.,Mar.,Mie.,Jue.,Vie.,Sab.,Dom.';

moment.locale('es', {
  months: months.split(','),
  monthsShort: monthsShort.split(','),
  weekdaysShort: weekdaysShort.split(','),
});
