/* eslint-disable react/no-array-index-key */
import React from 'react';

import { Card, Col, Jumbotron, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TableCabins from './TableCabins';

const CabinsDetail = ({ cabinas, onHandleClickReservar }) => {
  const { t } = useTranslation();
  const items = cabinas;

  return (
    <Row className="mt-4">
      <Col xs="12" lg="12">
        <h2 className="text-capitalize">{t('Cabinas')}</h2>
      </Col>
      <Col>
        {items.length === 0 || undefined ? (
          <Jumbotron>
            <h4>{t('LoSentimos')}</h4>
            <p>{t('NoHabitacionesDisponibles')}</p>
          </Jumbotron>
        ) : (
          items.map((item, i) => (
            <Card key={i} className="mb-4">
              <Card.Body>
                <Row>
                  <Col xs="12" lg="12" className="mb-2">
                    <span className="text-detail-room bg-dark text-light rounded">
                      {item.text || 'Suite'}
                    </span>
                  </Col>
                  {/*     <Col xs="12" lg="12">
                    {item.detail[0].roomDescription.map((room, roomI) => (
                      <Badge variant="light" key={roomI}>
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                              <GetTextHuespedes guest={room.guest} />
                            </Tooltip>
                          }
                        >
                          <div>
                            {getHuespedesNumber(
                              room?.guest?.adults || 0,
                              'icon-16',
                            ).map((cla, clI) => (
                              <FaUser className={cla} key={clI} />
                            ))}
                            {room?.guest?.children > 0 ? '+' : ''}
                            {getHuespedesNumber(
                              room?.guest?.children || 0,
                              'icon-12',
                            ).map((cla, clI) => (
                              <FaUser className={cla} key={clI} />
                            ))}
                          </div>
                        </OverlayTrigger>
                      </Badge>
                    ))}
                  </Col> */}
                  <Col xs="12" lg="12">
                    <TableCabins
                      rates={item.description}
                      onHandleClickReservar={onHandleClickReservar}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))
        )}
      </Col>
    </Row>
  );
};

export default CabinsDetail;
