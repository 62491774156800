import { useMemo } from 'react';
import usePriceFormat from '../hook/usePriceFormat';

const PriceFormat = ({ currency, value, children, decimalScale = 2 }) => {
  const format = usePriceFormat({
    decimalScale,
  });

  const formattedValue = useMemo(
    () => format({ value, currency }),
    [format, value, currency],
  );
  if (!children) return formattedValue;

  return children(formattedValue);
};

export default PriceFormat;
