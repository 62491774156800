import React, { useState } from 'react';

import moment from 'moment';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SelectDestinations from '../SelectDestinations';

const CondosFormTopBar = ({ searching }) => {
  const { t } = useTranslation();
  const [destino, setDestino] = useState({
    value: searching.destiny,
    label: searching.destino_name,
  });
  const [startDate, setStartDate] = useState(
    new Date(moment(searching.checkIn, 'DD/MM/YYYY')),
  );
  const [endDate, setEndDate] = useState(
    new Date(moment(searching.checkOut, 'DD/MM/YYYY')),
  );
  const [errorDestino, setErrorDestino] = useState(false);
  const history = useHistory();

  const onHandleClickBuscar = () => {
    if (destino === null) setErrorDestino(true);
    else {
      const data = {
        destiny: destino === null ? null : destino.value,
        destino_name: destino === null ? null : destino.label,
        checkIn: moment(startDate).startOf('month').format('DD-MM-YYYY'),
        checkOut: moment(endDate).endOf('month').format('DD-MM-YYYY'),
        currency: 'USD',
        language: 'es',
        rooms: [
          {
            adults: 1,
            children: {
              ages: [],
            },
          },
        ],
      };

      history.push(
        `/condos?search=${encodeURIComponent(JSON.stringify(data))}`,
      );
    }
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs="12" sm="4" md="3" lg="3" xl="3">
            <SelectDestinations
              value={destino}
              onChange={value => {
                if (value === null) setErrorDestino(true);
                else setErrorDestino(false);

                setDestino(value);
              }}
            />

            {errorDestino && (
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            )}
          </Col>
          <Col xs="12" sm="4" md="3" lg="3" xl="3">
            <DatePicker
              selected={startDate}
              className="form-control-sm input-datepicker"
              onChange={date => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="MMMM-yyyy"
              showMonthYearPicker
              calendarClassName="calendar-datepicker"
            />
          </Col>
          <Col xs="12" sm="4" md="3" lg="3" xl="3">
            <DatePicker
              selected={endDate}
              className="form-control-sm input-datepicker"
              onChange={date => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="MMMM-yyyy"
              showMonthYearPicker
              calendarClassName="calendar-datepicker"
            />
          </Col>
          <Col xs="12" sm="12" md="3" lg="3" xl="3">
            <Button
              block
              variant="light"
              onClick={onHandleClickBuscar}
              className="font-weight-bold"
            >
              {t('Buscar')}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CondosFormTopBar;
