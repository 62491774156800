import React, { useEffect, useState } from 'react';

import { nanoid } from 'nanoid';
import { Alert, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import Switch from 'react-bootstrap/esm/Switch';
import { useTranslation } from 'react-i18next';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import '../../css/HotelReserva.css';
import useCruise from '../../hook/useCruise';
import LoaderReservar from '../../loader/LoaderReservar';
import { useAuth } from '../../providers/AuthProvider';
import buildPaxesInformationRerverva from '../../utils/buildPaxesInformationRerverva';
import isEmptyPaxes from '../../utils/isEmptyPaxes';
import PromoCondos from '../PromoCondos';
import ReservaCardTop from '../ReservaCardTop';
import ReservaCardTopWhite from '../ReservaCardTopWhite';
import BookingInfo from '../booking/BookingInfo';
import ReservaTop from '../ReservaTop';
import ReservacionConcluida from '../ReservacionConcluida';
import SectionTop from '../SectionTop';
import addPoints from '../../utils/addPoints';
import Voucher from './Voucher';

const Booking = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { checkout } = useCruise();
  const [roomsHuespedes, setRoomsHuespedes] = useState([]);
  const [client, setClient] = useState({
    name: '',
    sName: '',
    lName: '',
    telClient: '',
    eClient: '',
  });

  const [, setHasItem] = useState(true);
  const [payment, setPayment] = useState(null);
  const [sendindReserva, setSendingReserva] = useState(false);
  const [infoReserva, setInfoReserva] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (checkout !== null) {
      setPayment({
        currency: 'USD',
        source: checkout ? checkout.apiName : '',
        amount: checkout ? checkout.total : 0,
        paymentType: 'CREDIT_CARD',
        creditCard: {
          number: '',
          expireMonth: '01',
          expireYear: '2022',
          type: {
            name: 'VI',
          },
        },
      });

      const arrroomHuespedes = [];

      arrroomHuespedes.push({
        id: checkout.habitacion_id,
        roomType: '', // fijo
        planId: '', // fijo
        price: checkout.total,
        paxes: {
          adults: checkout.huespedes,
          children: checkout.huespedes_children,
          infant: 0, // fijo
        },
      });

      arrroomHuespedes.forEach((elem, index) => {
        const paxesInformation = buildPaxesInformationRerverva(elem);
        arrroomHuespedes[index].paxesInformation = paxesInformation;
      });
      setRoomsHuespedes(arrroomHuespedes);
    } else {
      setHasItem(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [textoError, setTextoError] = useState([]);
  const [checkReserva, setCheckReserva] = useState(true);
  const [checkRJustG, setCheckRJustG] = useState(true);

  const onHandleClickReservacion = () => {
    // console.log('reservado')
    setTextoError([]);
    const reservaBooking = {
      reservationCode: nanoid(),
      currency: 'USD',
      total: checkout.total,
      source: checkout.apiName,
      reference: 'CMOM', // fijo
      company: 'JustGo', // fij
      client: {
        country: 'OT', // fijo
        phone: {
          number: client.telClient,
          type: 'HOME', // fijo
        },
        name: `${client.name}${client.sName === '' ? '' : ` ${client.sName}`}`,
        hotel: {
          name: checkout.hotel_name,
          id: 2, // fijo por el momento
        },
        email: client.eClient,
        lastname: client.lName,
      },
      activities: [], // fijo
      hotels: [
        {
          id: checkout.hotel_id,
          source: checkout.apiName,
          name: checkout.hotel_name,
          checkin: checkout.checkIn,
          checkout: checkout.checkOut,
          destination: checkout.destino_id,
          rooms: roomsHuespedes,
          paxes: {
            adults: checkout.huespedes_adults,
            children: checkout.huespedes_children,
            infant: 0,
          },
        },
      ],
      payment,
    };

    // vemos si tiene error el formulario
    // 1.cliente principal

    const arr = [];
    if (
      reservaBooking.client.name === '' ||
      reservaBooking.client.lastname === '' ||
      reservaBooking.client.email === '' ||
      reservaBooking.client.phone.number === ''
    ) {
      const txt = t('ValidacionHuespedPrincipal');
      arr.push(txt);
    }
    if (isEmptyPaxes(reservaBooking.hotels)) {
      const txt = t('ValidacionHuespedes');
      arr.push(txt);
    }
    if (reservaBooking.payment.creditCard.number === '') {
      const txt = t('ValidacionPayment');
      arr.push(txt);
    }
    if (!checkReserva || !checkRJustG) {
      const txt = t('OkPolitica');
      arr.push(txt);
    }
    setTextoError(arr);

    if (arr.length === 0) {
      // console.log('ok')
      // console.log(reservaBooking)
      // test que devuelve error en la reserva
      // loader mientras reserva
      // setSendingReserva(true)
      // setInterval(()=>{
      //     //setInfoReserva(dataError)
      //     //setShow(true)
      //     setInfoReserva(dataSuccess)
      //     setSendingReserva(false)
      //     history.push(`${url}/voucher`)
      // },5000)
      /* setInfoReserva(reservaBooking);
      setSendingReserva(true); */
      /* 
      const htmlBody = handleEmailConfirmation(t, reservaBooking, checkout);
      const paramsEmail = {
        content: htmlBody,
        from: 'demo@gmail.com',
        subject: 'CONFIRMACION',
        to: client.eClient,
      };
      sendMail(paramsEmail); */
      setSendingReserva(false);
      setInfoReserva(reservaBooking);
      addPoints({
        membership: auth.data.membership,
        describe: checkout.hotel_name,
        points: checkout.total,
      });
      /* addPoints({
        membership: 992925,
        describe: checkout.hotel_name,
        points: checkout.total,
      }); */
      history.push(`${url}/voucher`);

      /* const urlHotelReservation = `${HOTEL_RESERVATION}?sources=${process.env.REACT_APP_SUNAPI_API_NAME_HOTEL}`;
      post({
        url: urlHotelReservation,
        data: reservaBooking,
      })
        .then(response => {
          setSendingReserva(false);
          // console.log(response)
          setInfoReserva(response.data);
          if (response.httpStatusCode === 'INTERNAL_SERVER_ERROR') {
            setShow(true);
          } else {
            //email ->infoReserva mustache
            history.push(`${url}/voucher`);
          }
        })
        .catch(error => {
          // console.log(error);
          setSendingReserva(false);
          setShow(true);
          setInfoReserva(error);
        }); */
    }
  };

  return (
    <Switch>
      <Route exact path={path}>
        {checkout && (
          <>
            {sendindReserva && <LoaderReservar />}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton />
              <Modal.Body>
                <Row>
                  <Col xs="12" lg="12">
                    <h6>{t('LoSentimos')}!!!</h6>
                    <label>{t('NoEfectuarReservación')}</label>
                  </Col>
                </Row>
                {infoReserva && infoReserva.httpStatusCode && (
                  <Row>
                    <Col xs="12" lg="12">
                      <Alert variant="outline-primary">
                        {`${infoReserva.httpStatusCode}`}
                        <br />
                        {infoReserva.message}
                      </Alert>
                    </Col>
                  </Row>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={handleClose}>
                  {t('Aceptar')}
                </Button>
              </Modal.Footer>
            </Modal>
            <SectionTop
              menu={t('Crucero')}
              destino={checkout.direccion}
              nombre={t('DatosReserva')}
              direccion={checkout.hotel_name}
            />
            <Container fluid>
              <Row>
                <Col xs="12" lg="3" className="my-3">
                  <Row className="justify-content-center">
                    <Col xs="12" lg="12">
                      <ReservaCardTop item={checkout} />
                    </Col>
                    <Col xs="12" lg="12">
                      <ReservaCardTopWhite item={checkout} />
                    </Col>
                    <Col xs="12" lg="10">
                      <h1 className="text-go text-right ft-6em">GO!</h1>
                    </Col>
                    <Col xs="12" lg="12">
                      <PromoCondos />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="9">
                  <ReservaTop
                    hotelPhotos={checkout.hotel_photos}
                    hotelName={checkout.hotel_name}
                    hotel_stars={checkout.stars}
                    hotel_direccion={checkout.direccion}
                  />

                  <BookingInfo
                    onHandleClickReservacion={onHandleClickReservacion}
                    roomsHuespedes={roomsHuespedes}
                    setRoomsHuespedes={setRoomsHuespedes}
                    client={client}
                    setClient={setClient}
                    payment={payment}
                    setPayment={setPayment}
                    textoError={textoError}
                    checkReserva={checkReserva}
                    setCheckReserva={setCheckReserva}
                    checkRJustG={checkRJustG}
                    setCheckRJustG={setCheckRJustG}
                  />
                </Col>
              </Row>
            </Container>
          </>
        )}
      </Route>
      <Route path={`${path}/voucher`}>
        {checkout ? (
          <Voucher infoReserva={infoReserva} checkout={checkout} />
        ) : (
          <ReservacionConcluida />
        )}
      </Route>
    </Switch>
  );
};

export default Booking;
