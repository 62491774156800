import React from 'react';
import { NumericFormat } from 'react-number-format';

const formatNumber = number => {
  return (
    <NumericFormat
      value={number === null ? 0 : number}
      prefix="$"
      decimalScale={2}
      fixedDecimalScale
      thousandSeparator
      displayType="text"
    />
  );
};

export default formatNumber;
