import React from 'react';

import moment from 'moment';
import { Card, Col, Container, Image, ListGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BiCalendarEdit, BiMessageRoundedError } from 'react-icons/bi';
import { BsInfoCircle } from 'react-icons/bs';
import { FiCheckSquare } from 'react-icons/fi';
import { GoCreditCard } from 'react-icons/go';
import appStore from '../../img/voucher/appstore.png';
import cels from '../../img/voucher/cels.jpg';
import googlePlay from '../../img/voucher/googleplay.png';
import SectionTop from '../SectionTop';

const Checkout = ({ infoReserva }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row className="justify-content-center mt-load">
        <Col xs="12" lg="10">
          <SectionTop
            menu={t('EventosDeportivos_one')}
            nombre={infoReserva.sport}
            // direccion="Moro"
          />
          <Card.Body>
            <Row>
              <Col xs="12" lg="12">
                <h4>{infoReserva.source}</h4>
              </Col>
              <Col xs="9" lg="8">
                <ListGroup variant="flush">
                  <ListGroup.Item className="font-weight-bold px-0">
                    {t('DetalleReservacion')}
                  </ListGroup.Item>
                  <ListGroup.Item className="px-0">
                    <strong>{t('Entrada')}:</strong>{' '}
                    {moment(
                      infoReserva.tickets[0].checkin,
                      'DD-MM-YYYY',
                    ).format('dddd DD MMMM YYYY')}
                  </ListGroup.Item>
                  <ListGroup.Item className="px-0">
                    <strong>{t('Lugar')}:</strong> {infoReserva.venue}
                  </ListGroup.Item>
                  <ListGroup.Item className="px-0">
                    <strong>{t('Direccion')}:</strong>{' '}
                    {`${infoReserva.direccion}`}
                  </ListGroup.Item>
                  <ListGroup.Item className="px-0">
                    <strong>{t('Section')}:</strong> {infoReserva.section}
                  </ListGroup.Item>
                  <ListGroup.Item className="px-0">
                    <strong>{t('Precio')}</strong> {infoReserva.total}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <hr />
            <Row className="my-5">
              <Col xs="12" lg="6">
                <Image src={cels} fluid />
              </Col>
              <Col xs="12" lg="6">
                <h4 className="display-4 ft-2-5rem">{t('Nuestra')} APP</h4>
                <p>
                  <FiCheckSquare className="icon-24 mr-2" />
                  <strong className="text-secondary">
                    {t('HazViajeNuestraApp')}
                  </strong>
                  <br />
                  {t('CambieCanceleReservas')}
                </p>
                <div className="text-center">
                  <Image src={appStore} fluid />
                </div>
                <div className="text-center">
                  <Image src={googlePlay} fluid />
                </div>
              </Col>
            </Row>
            <hr />
            <Row className="my-5 justify-content-center">
              <Col xs="12" lg="10">
                <h4 className="display-4 ft-1-5rem text-secondary">
                  <BsInfoCircle className="mr-2  icon-24" />
                  {t('DetallesImportantes')}
                </h4>
                <ul className="text-secondary">
                  <li>{t('DetallesImportantes1')}</li>
                  <li>{t('DetallesImportantes2')}</li>
                  <li>{t('DetallesImportantes3')}</li>
                  <li>{t('DetallesImportantes4')}</li>
                  <li>{t('DetallesImportantes5')}</li>
                  <li>{t('DetallesImportantes6')}</li>
                  <li>{t('DetallesImportantes7')}</li>
                  <li>{t('DetallesImportantes8')}</li>
                </ul>
                <a href="!#" className="px-4">
                  <small>
                    {t('DescargueAplicacion')} {'>>'}
                  </small>
                </a>
              </Col>
            </Row>
            <Row className="my-5 justify-content-center">
              <Col xs="12" lg="10">
                <h4 className="display-4 ft-1-5rem text-secondary">
                  <GoCreditCard className="mr-2 icon-24" />
                  {t('Pago')}
                </h4>
                <ul className="text-secondary">
                  <li>{t('Pago1')}</li>
                  <li>{t('Pago2')}</li>
                </ul>
                <a href="!#" className="px-4">
                  <small>
                    {t('DescargueAplicacion')} {'>>'}
                  </small>
                </a>
              </Col>
            </Row>
            <Row className="my-5 justify-content-center">
              <Col xs="12" lg="10">
                <h4 className="display-4 ft-1-5rem text-secondary">
                  <BiCalendarEdit className="mr-2 icon-24" />
                  {t('RealiceCambiosReserva')}
                </h4>
                <ul className="text-secondary">
                  <li>{t('RealiceCambiosReserva1')}</li>
                </ul>
                <a href="!#" className="px-4">
                  <small>
                    {t('DescargueAplicacion')} {'>>'}
                  </small>
                </a>
              </Col>
            </Row>
            <Row className="my-5 justify-content-center">
              <Col xs="12" lg="10">
                <h4 className="display-4 ft-1-5rem text-secondary">
                  <BiMessageRoundedError className="mr-2 icon-24" />
                  {t('PongaseContactoPropiedad')}
                </h4>
                <ul className="text-secondary">
                  <li>{t('PongaseContactoPropiedad1')}</li>
                </ul>
                <a href="!#" className="px-4">
                  <small>
                    {t('DescargueAplicacion')} {'>>'}
                  </small>
                </a>
              </Col>
            </Row>
          </Card.Body>
        </Col>
      </Row>
    </Container>
  );
};

export default Checkout;
