/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Modal, Card, Col, Row, Button } from 'react-bootstrap';
import { Markup } from 'interweave';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import formatNumber from '../utils/formatNumber';
import setApplyDiscount from '../utils/setApplyDiscount';
import '../css/TourDetail.css';

const TourDetail = ({ t, rates, data, onHandleClickReservar }) => {
  const [tours] = useState(rates);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [politicas, setPoliticas] = useState('');
  const onHandleClickPolitica = item => {
    // console.log(item)
    setPoliticas(item.rateDetail.cancellationPolicy);
    setShow(true);
  };

  // console.log(tours);

  return (
    <Row className="mt-4">
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('PoliticaCancelacion')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Markup content={politicas.description} />
        </Modal.Body>
      </Modal>
      <Col xs="12" lg="12">
        <h2>{t('MiraOpcionesExperiencia')}</h2>
      </Col>
      <Col>
        {tours.map((item, i) => (
          <Card key={i} className="my-4">
            <Card.Body>
              <Row>
                <Col xs="12" lg="8">
                  <span className="title-tour-rate d-block bg-primary">
                    {item.name}
                  </span>
                  <label>
                    <Markup content={item.description} />
                  </label>
                  <Row>
                    <Col xs="12" lg="6">
                      <label className="text-info">{t('QueIncluye')}</label>
                      <ul className="tour-list">
                        {item.includes.map((item, i) => {
                          return <li key={i}> {item.name}</li>;
                        })}
                      </ul>
                    </Col>
                    <Col xs="12" lg="6">
                      <label className="text-danger">{t('QueNoIncluye')}</label>
                      <ul className="tour-list">
                        {item.notIncludes.map((item, i) => {
                          return <li key={i}> {item.name}</li>;
                        })}
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <div className="line-price line-tour-price border-primary" />
                <Col xs="12" lg="4">
                  <div className="text-right mb-4">
                    <span className="text-secondary ft-12 font-weight-bold d-block lh-03">
                      {moment(data.checkIn, 'DD-MM-YYYY').format(
                        'DD MMMM YYYY',
                      )}
                    </span>
                    <span className="text-secondary ft-12 font-weight-bold">{`${
                      data.participants
                    } ${
                      data.participants > 1 ? t('personas') : t('persona')
                    }`}</span>
                  </div>
                  {item.rateDetail.paxAmounts.map((rate, i) => (
                    <Row className="text-center" key={i}>
                      <Col>
                        <span style={{ textTransform: 'capitalize' }}>
                          {rate.paxType}
                        </span>{' '}
                        {'  '}
                        <span className="text-success price-card">
                          <label>
                            {formatNumber(setApplyDiscount(rate.amount, 0))} USD
                          </label>
                        </span>
                      </Col>
                    </Row>
                  ))}
                  <Row className="justify-content-center">
                    <Col xs="10" lg="8">
                      <Button
                        block
                        variant="outline-primary"
                        className="my-3"
                        onClick={() => onHandleClickReservar(item)}
                      >
                        {t('Reservar')}
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        block
                        onClick={() => onHandleClickPolitica(item)}
                      >
                        {t('PoliticaCancelacion')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </Col>
    </Row>
  );
};

export default withTranslation()(TourDetail);
