import React, { useState } from 'react';

import moment from 'moment';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SelectDestinations from './SelectDestinations';

import 'react-datepicker/dist/react-datepicker.css';
import '../css/DatePicker.css';

const SearchCondominios = ({ searching, sendData }) => {
  const { t, i18n } = useTranslation();
  const [destino, setDestino] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [errorDestino, setErrordestino] = useState(false);
  const history = useHistory();

  // Cargar la información previamente cargada en el filtro
  if (searching) {
    setDestino({ value: searching.destiny, label: searching.destino_name });
    setStartDate(new Date(moment(searching.checkIn, 'DD/MM/YYYY')));
    setEndDate(new Date(moment(searching.checkOut, 'DD/MM/YYYY')));
  }

  const onHandleClickBuscar = () => {
    if (destino == null) setErrordestino(true);
    else {
      const data = {
        destiny: destino === null ? null : destino.value,
        destino_name: destino === null ? null : destino.label,
        checkIn: moment(startDate).startOf('month').format('DD-MM-YYYY'),
        checkOut: moment(endDate).endOf('month').format('DD-MM-YYYY'),
        currency: 'USD',
        language: `${i18n.language === 'es' ? 'CAS' : 'EN'}`,
        rooms: [
          {
            adults: 1,
            children: {
              ages: [],
            },
          },
        ],
      };

      if (sendData != null) sendData(data);

      history.push(
        `/condos?search=${encodeURIComponent(JSON.stringify(data))}`,
      );
    }
  };

  return (
    <Container className="form-padding">
      <Row className="align-items-center">
        <Col xs="12" lg="4">
          <Form.Group>
            <Form.Label className="text-light">{t('FlightGoingTo')}</Form.Label>

            <SelectDestinations
              value={destino}
              onChange={value => {
                if (value === null) setErrordestino(true);
                else setErrordestino(false);

                setDestino(value);
              }}
            />

            {errorDestino && (
              <Form.Control.Feedback type="invalid">
                {t('CampoRequerido')}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col xs="6" lg="3">
          <Form.Group>
            <Form.Label className="text-light d-block">{t('desde')}</Form.Label>
            <DatePicker
              selected={startDate}
              className="form-control-sm input-datepicker"
              onChange={date => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="MMMM-yyyy"
              showMonthYearPicker
              calendarClassName="calendar-datepicker"
            />
          </Form.Group>
        </Col>
        <Col xs="6" lg="3">
          <Form.Group>
            <Form.Label className="text-light d-block">{t('hasta')}</Form.Label>
            <DatePicker
              selected={endDate}
              className="form-control-sm input-datepicker"
              onChange={date => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="MMMM-yyyy"
              showMonthYearPicker
              calendarClassName="calendar-datepicker"
            />
          </Form.Group>
        </Col>
        <Col xs="12" lg="2">
          <Button
            block
            variant="outline-primary"
            onClick={onHandleClickBuscar}
            className="font-weight-bold"
          >
            {t('Buscar')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SearchCondominios;
