import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Button } from 'react-bootstrap';
import { MdPeople } from 'react-icons/md';
import { FaMinus, FaPlus } from 'react-icons/fa';

const supplier = ''; // process.env.REACT_APP_SUNAPI_API_NAME_FLIGHT;
const maxAge = 12;

const Counter = ({ value, min = 0, max = 1, onChange }) => {
  const [counter, _setCounter] = useState(value);

  useEffect(() => {
    if (counter !== value) {
      _setCounter(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const setCounter = newValue => {
    if (newValue >= min && newValue <= max) {
      _setCounter(newValue);
      if (onChange) {
        onChange(newValue);
      }
    }
  };

  const disabledRemove = supplier === 'Amadeus' || counter === min;
  const handleRemoveClick = () => !disabledRemove && setCounter(counter - 1);

  const disabledAdd = supplier === 'Amadeus' || counter === max;
  const handleAddClick = () => !disabledAdd && setCounter(counter + 1);

  return (
    <div className="d-flex justify-content-between">
      <span
        role="menuitem"
        tabIndex="-1"
        className={`btn-h btn-minus ${disabledRemove ? 'disabled' : ''}`}
        onClick={handleRemoveClick}
        onKeyPress={handleRemoveClick}
      >
        <FaMinus />
      </span>
      <span className="adult-number">{counter}</span>
      <span
        role="menuitem"
        tabIndex="-1"
        className={`btn-h btn-plus ${disabledAdd ? 'disabled' : ''}`}
        onClick={handleAddClick}
        onKeyPress={handleAddClick}
      >
        <FaPlus />
      </span>
    </div>
  );
};

const valueDefault = { adults: 1, children: [] };
const isValueValid = value =>
  value &&
  typeof value.adults === 'number' &&
  Array.isArray(value.children) &&
  value.children.every(age => typeof age === 'number');

const DropdownTravelers = ({ value, onChange }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [travelers, _setTravelers] = useState(null);

  const setTravelers = newValue => {
    _setTravelers(newValue);

    if (onChange) onChange(newValue);
  };

  const handleCounterAdultChange = adults =>
    setTravelers({ ...travelers, adults });

  const handleCounterChildrenChange = count => {
    const { children = [] } = travelers;
    if (children?.length > count) {
      children.pop();
    } else if (children?.length < count) {
      children.push(0);
    }

    setTravelers({ ...travelers, children });
  };

  const handleChildAgeChange = (childIndex, newAge) => {
    const { children = [] } = travelers;
    children[childIndex] = parseInt(newAge, 10);

    setTravelers({ ...travelers, children });
  };

  useEffect(() => {
    if (isValueValid(value)) {
      const { adults, children } = value;
      if (
        !travelers ||
        travelers.adults !== adults ||
        travelers.children.length !== children.length ||
        travelers.children.some((age, i) => children[i] !== age)
      ) {
        _setTravelers({ adults, children });
      }
    } else if (!travelers) {
      _setTravelers(valueDefault);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const text = useMemo(() => {
    const count = (travelers?.adults || 0) + (travelers?.children?.length || 0);
    const label = t('FlightTravelers', { count });

    return `${count} ${label}`;
  }, [t, travelers]);

  return (
    <Dropdown
      className="filter"
      show={show}
      onToggle={() => setShow(!show)}
      drop="down"
    >
      <Dropdown.Toggle variant="success" className="shadow-none">
        <MdPeople className="text-white mr-2 icon-24" /> <span>{text}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100 rounded-0" flip={false}>
        <div className="py-1 px-3">
          {isValueValid(travelers) && (
            <>
              {/* <pre>{JSON.stringify(travelers, null, 2)}</pre> */}
              <div className="d-flex justify-content-between align-items-center">
                <span>{t('Adults')}</span>
                <Counter
                  value={travelers.adults}
                  min={1}
                  max={6}
                  onChange={handleCounterAdultChange}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <span className="lh-1">
                  {t('Ninos')}
                  <small className="d-block">{t('NinosRangoEdad')}</small>
                </span>
                <Counter
                  value={travelers.children.length}
                  min={0}
                  max={travelers.adults}
                  onChange={handleCounterChildrenChange}
                />
              </div>
              {travelers.children.map((age, idx) => (
                <div
                  className="d-flex justify-content-between align-items-center"
                  key={idx}
                >
                  <span className="ft-08rem">{`${t('HotelsChildAge')} ${
                    idx + 1
                  }`}</span>
                  <select
                    className="ft-08rem"
                    onChange={e => handleChildAgeChange(idx, e.target.value)}
                    defaultValue={age}
                  >
                    <option value="0">{t('NinosMenosDe')} 1</option>
                    {new Array(maxAge).fill().map((item, index) => (
                      <option key={index} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </>
          )}

          <Button
            block
            variant="outline-primary"
            className="mt-2 shadow-none"
            onClick={() => setShow(false)}
          >
            {t('Aceptar')}
            <small className="d-block">{text}</small>
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownTravelers;
