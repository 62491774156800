/* eslint-disable no-console */
import axios from 'axios';
import login from './login';
import storage from './storage';

const { REACT_APP_SUNAPI_ENDPOINT, REACT_APP_SUNAPI_ENDPOINT_VERSION } =
  process.env;

const instance = axios.create({
  baseURL: `${REACT_APP_SUNAPI_ENDPOINT}${REACT_APP_SUNAPI_ENDPOINT_VERSION}`,
});

instance.interceptors.request.use(config => {
  const item = storage.get();
  if (!item?.access_token?.length) return config;

  const { headers } = config;
  headers.Authorization = `Bearer ${item.access_token}`;

  return { ...config, headers };
});

const processInvalidResponse = async ({ response }) => {
  const { apiCode } = response.data;
  const originalRequest = response.config;

  if (
    ['E_EXPIRED_TOKEN', 'E_SIGNATURE_INVALID', 'E_ACCESS_DENIED'].includes(
      apiCode,
    )
  ) {
    let config;
    if (apiCode === 'E_SIGNATURE_INVALID') {
      config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
    }

    const responseLogin = await login({ config });
    const item = storage.get();
    item.access_token = responseLogin.data.Bearer;
    storage.set(item);

    originalRequest.retry = true;
    originalRequest.headers.Authorization = `Bearer ${item.access_token}`;
    return axios(originalRequest);
  }

  if (apiCode) {
    console.log('🚀 ~ instance.js', { apiCode });
  }

  return response;
};

instance.interceptors.response.use(
  async response => processInvalidResponse({ response }),

  async error => {
    console.log('🚀 ~ instance.js', { error });

    return processInvalidResponse(error);
  },
);

export default instance;
