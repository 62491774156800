import React, { useState } from 'react';

import moment from 'moment';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FormSidebar from '../../shared/FormSidebar';
import SelectDestinations from '../SelectDestinations';

const CondosFormSideBar = ({ searching, sendData }) => {
  const { t, i18n } = useTranslation();
  const [destino, setDestino] = useState({
    value: searching.destiny,
    label: searching.destino_name,
  });
  const [startDate, setStartDate] = useState(
    new Date(moment(searching.checkIn, 'DD/MM/YYYY')),
  );
  const [endDate, setEndDate] = useState(
    new Date(moment(searching.checkOut, 'DD/MM/YYYY')),
  );
  const [errorDestino, setErrorDestino] = useState(false);
  const history = useHistory();

  const onHandleClickBuscar = () => {
    if (destino === null) setErrorDestino(true);
    else {
      setErrorDestino(false);
      const data = {
        destiny: destino === null ? null : destino.value,
        destino_name: destino === null ? null : destino.label,
        checkIn: moment(startDate).startOf('month').format('DD-MM-YYYY'),
        checkOut: moment(endDate).endOf('month').format('DD-MM-YYYY'),
        currency: 'USD',
        language: `${i18n.language === 'es' ? 'CAS' : 'EN'}`,
        rooms: [
          {
            adults: 1,
            children: {
              ages: [],
            },
          },
        ],
      };

      sendData(data);

      history.push(
        `/condos?search=${encodeURIComponent(JSON.stringify(data))}`,
      );
    }
  };

  return (
    <FormSidebar>
      <Row className="mb-2">
        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          <SelectDestinations
            value={destino}
            onChange={value => {
              if (value === null) setErrorDestino(true);
              else setErrorDestino(false);

              setDestino(value);
            }}
          />

          {errorDestino && (
            <Form.Control.Feedback type="invalid">
              {t('CampoRequerido')}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          <Form.Label className="text-light d-block label-search">
            {t('desde')}
          </Form.Label>
          <DatePicker
            selected={startDate}
            className="form-control-sm input-datepicker"
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="MMMM-yyyy"
            showMonthYearPicker
            calendarClassName="calendar-datepicker"
          />
        </Col>
        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          <Form.Group>
            <Form.Label className="text-light d-block label-search">
              {t('hasta')}
            </Form.Label>
            <DatePicker
              selected={endDate}
              className="form-control-sm input-datepicker"
              onChange={date => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="MMMM-yyyy"
              showMonthYearPicker
              calendarClassName="calendar-datepicker"
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          <Button
            block
            variant="light"
            onClick={onHandleClickBuscar}
            className="font-weight-bold"
          >
            {t('Buscar')}
          </Button>
        </Col>
      </Row>
    </FormSidebar>
  );
};

export default CondosFormSideBar;
