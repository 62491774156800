import React, { useState } from 'react';

import moment from 'moment';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FiltersContainer from '../shared/FiltersContainer';
import DatePicker from './DatePicker';
import SelectDestinations from './SelectDestinations';
import SelectPerson from './SelectPerson';

const FormSearchExperiencias = ({ history, sendData }) => {
  const { t, i18n } = useTranslation();
  const [destino, setDestino] = useState(null);
  const [startDate, setStartDate] = useState(moment().add(1, 'days'));
  const [adult, setAdult] = useState(1);
  const [child, setChild] = useState(0);
  const [errorDestino, setErrorDestino] = useState(false);

  const onHandleClickBuscar = () => {
    if (destino === null) setErrorDestino(true);
    else {
      setErrorDestino(false);
      const d = {
        destiny: destino === null ? null : destino.value,
        destino_name: {
          label: destino?.label ?? '',
          es: destino?.es ?? null,
          en: destino?.en ?? null,
        },
        checkIn: moment(startDate).format('DD-MM-YYYY'),
        currency: 'USD',
        language: i18n.language,
        apiName: process.env.REACT_APP_SUNAPI_API_NAME_TOUR,
        paxes: {
          adults: adult,
          children: child,
          infant: 0,
          junior: 0,
          paxes: adult + child,
        },
      };

      if (sendData) sendData(d);

      history.push(
        `/experience?search=${encodeURIComponent(JSON.stringify(d))}`,
      );
    }
  };

  return (
    <>
      <FiltersContainer title={t('Experiencias')}>
        <Row className="align-items-center">
          <Col xs="12" lg="4">
            <Form.Group>
              <Form.Label className="text-light">
                {t('FlightGoingTo')}
              </Form.Label>

              <SelectDestinations
                value={destino}
                onChange={value => {
                  if (value === null) {
                    setErrorDestino(true);
                  } else {
                    setErrorDestino(false);
                  }
                  setDestino(value);
                }}
              />

              {errorDestino && (
                <Form.Control.Feedback type="invalid">
                  {t('CampoRequerido')}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col xs="4" lg="2">
            <Form.Group>
              <Form.Label className="text-light">{t('Fecha')}</Form.Label>
              <DatePicker
                name="startDateExp"
                id="start_date_exp_id"
                date={startDate}
                onDateChange={startDateExp => {
                  // console.log("🚀 ~ FormSearchExperiencias.js", { startDateExp })
                  if (startDateExp === null) {
                    setStartDate(moment().add(1, 'days'));
                  } else {
                    setStartDate(startDateExp);
                  }
                }}
              />
            </Form.Group>
          </Col>
          <Col xs="4" lg="2">
            <Form.Group>
              <Form.Label className="text-light">{t('Adults')}</Form.Label>
              <SelectPerson adult child={false} setAdult={setAdult} text="1" />
            </Form.Group>
          </Col>
          <Col xs="4" lg="2">
            <Form.Group>
              <Form.Label className="text-light">{t('Ninos')}</Form.Label>
              <SelectPerson adult={false} child setChild={setChild} text="0" />
            </Form.Group>
          </Col>
          <Col xs="12" lg="2">
            <Button
              block
              variant="outline-primary"
              onClick={onHandleClickBuscar}
              className="font-weight-bold"
            >
              {t('Buscar')}
            </Button>
          </Col>
        </Row>
      </FiltersContainer>

      <Container fluid className="mb-5">
        <Row className="justify-content-md-center">
          <Col xs="12" md="10" lg="7">
            <iframe
              style={{ width: '100%', aspectRatio: '2 / 1' }}
              src="https://www.youtube.com/embed/AfcoksWLJiw?start=3"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FormSearchExperiencias;
