import React from 'react';

import { Image } from 'react-bootstrap';
import ReactSlick from 'react-slick';

const SliderAventura = '/images/slides/footer/slider-aventura.jpg';
const SliderMontania = '/images/slides/footer/slider-montania.jpg';
const SliderRio = '/images/slides/footer/slider-rio.jpg';

const SectionPromotionNY = () => {
  return (
    <ReactSlick
      dots={false}
      infinite
      autoplay
      speed={2000}
      autoplaySpeed={10000}
    >
      {[SliderAventura, SliderRio, SliderMontania].map((url, index) => (
        <div
          key={index}
          style={{
            background: '#EEE',
            overflow: 'hidden',
            padding: '0 10px',
          }}
        >
          <Image
            src={url}
            fluid
            className="object-cover px-1"
            style={{ width: '100%', aspectRatio: '16 / 3' }}
          />
        </div>
      ))}
    </ReactSlick>
  );
};

export default SectionPromotionNY;
