/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';

import { Card, Col, Container, Row } from 'react-bootstrap';
import ReactSlick from 'react-slick';
import styled from 'styled-components';
import useAppTranslation from '../../hook/useAppTranslation';
import slider1 from '../../img/dashboard/slider/10.png';
import slider2 from '../../img/dashboard/slider/12.png';
import slider3 from '../../img/dashboard/slider/2.png';
import slider4 from '../../img/dashboard/slider/4.png';
import slider6 from '../../img/dashboard/slider/5.png';
import slider5 from '../../img/dashboard/slider/8.png';
import sliderPlatinum1 from '../../img/dashboard/slider/Buggy Cenote_Platinum.jpg';
import sliderPlatinum4 from '../../img/dashboard/slider/COCINAMAYA_PLATINUM.jpg';
import sliderPlatinum5 from '../../img/dashboard/slider/CRUCERO-PLATINUM.jpg';
import sliderPlatinum2 from '../../img/dashboard/slider/FOOD-SAFARI_-PLATINUMjpg.jpg';
import { useAuth } from '../../providers/AuthProvider';

const CustomSlide = ({ src, destiny, price, description }) => {
  const [show, setShow] = useState(false);

  const handleClick = () => setShow(prev => !prev);

  return (
    <Card border="light" onClick={handleClick}>
      <Card.Img
        variant="top"
        src={src}
        width="100%"
        style={{
          height: show ? '300px' : '400px',
          transition: 'all .2s',
        }}
      />
      <Card.Body>
        <CardTitle>
          <span>{destiny}</span>
          <span>{price}</span>
        </CardTitle>
        <Card.Text
          style={{
            height: show ? '100px' : '0px',
            transition: 'all .2s',
            overflowX: 'hidden',
          }}
        >
          {description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const Slider = () => {
  const { t, language } = useAppTranslation();
  const { isPlatinum } = useAuth();

  const sliders = useMemo(() => {
    if (isPlatinum)
      return [
        {
          src: sliderPlatinum1,
          destiny: t('DashboardSlidePlatinumTitle1'),
          price: language === 'es' ? '$ 2,123.00 MXN' : '$ 123.00 USD',
          description: t('DashboardSlidePlatinumDescription1'),
        },
        {
          src: sliderPlatinum2,
          destiny: t('DashboardSlidePlatinumTitle2'),
          price: language === 'es' ? '$ 2,009.97 MXN' : '$ 115.00 USD',
          description: t('DashboardSlidePlatinumDescription2'),
        },
        {
          src: 'https://cdn.getyourguide.com/img/tour/63116ca5951fd.jpeg/146.jpg',
          destiny: t('DashboardSlidePlatinumTitle3'),
          price: language === 'es' ? '$ 110.00 MXN' : '$ 45.00 USD',
          description: t('DashboardSlidePlatinumDescription3'),
        },
        {
          src: sliderPlatinum4,
          destiny: t('DashboardSlidePlatinumTitle4'),
          price: language === 'es' ? '$ 1,038.64 MXN' : '$ 60.00 USD',
          description: t('DashboardSlidePlatinumDescription4'),
        },
        {
          src: sliderPlatinum5,
          destiny: t('DashboardSlidePlatinumTitle5'),
          price: language === 'es' ? '$ 2,310.95 MXN' : '$ 128.50 USD',
          description: t('DashboardSlidePlatinumDescription5'),
        },
      ];

    return [
      {
        src: slider1,
        destiny: 'Vuelo en Globo en Cappadocia',
        price: 'Desde $2,500',
        description: '',
      },
      {
        src: slider2,
        destiny: 'Caminata a Machu Picchu “Camino Inca”',
        price: 'Desde $18,000',
        description: '',
      },
      {
        src: slider3,
        destiny: 'Experiencia de linternas en Tailandia',
        price: 'Desde $15,000',
        description: '',
      },
      {
        src: slider4,
        destiny: 'Retiro Hot Springs en Islandia',
        price: 'Desde $6,500',
        description: '',
      },
      {
        src: slider5,
        destiny: 'Una noche con pingüinos',
        price: 'Desde $3,000',
        description: '',
      },
      {
        src: slider6,
        destiny: 'Go glamping',
        price: 'Desde $2,000',
        description: '',
      },
    ];
  }, [t, language, isPlatinum]);

  return (
    <Container>
      <Row>
        <Col>
          <Wrapper>
            <ReactSlick
              className="slider variable-width"
              dots={false}
              infinite
              autoplay
              speed={2000}
              autoplaySpeed={10000}
              slidesToShow={3.5}
              slidesToScroll={1}
              initialSlide={0}
              responsive={[
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ]}
            >
              {sliders.map(({ src, destiny, price, description }, i) => (
                <CustomSlide
                  key={i}
                  src={src}
                  destiny={destiny}
                  price={price}
                  description={description}
                />
              ))}
            </ReactSlick>
          </Wrapper>
        </Col>
      </Row>
    </Container>
  );
};

const CardTitle = styled(Card.Title)`
  display: flex;
  justify-content: space-between;

  & > span:first-child {
    padding-right: 3px;
  }

  & > span:last-child {
    color: var(--button-primary);
    text-align: right;
  }
`;

const Wrapper = styled.div`
  & .slick-track {
    /* background-color: gray; */
    display: flex;

    & > .slick-slide {
      flex: 1;
    }
  }
`;

export default Slider;
