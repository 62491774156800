import React from 'react';

import { Col, Container, Image, Row } from 'react-bootstrap';
import LoginForm from './LoginForm';

const WelcomeSectionTop = () => {
  return (
    <section className="welcome-bg">
      <Container fluid>
        <Row className="pt-5">
          <Col xs="6" lg="6">
            <div className="text-center mb-5">
              <Image src="/images/logo-white.png" fluid className="hp-100" />
            </div>
          </Col>
        </Row>

        <Row className="pt-4">
          <Col
            xs="12"
            sm={{ offset: 4, span: 8 }}
            md={{ offset: 6, span: 6 }}
            lg={{ offset: 7, span: 5 }}
            xl={{ offset: 7, span: 4 }}
          >
            <LoginForm />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WelcomeSectionTop;
