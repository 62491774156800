import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Select from 'react-select';

import useSportingEvents from '../../hook/useSportingEvents';
import Card from '../Card';
import Text from '../Text';

const markUP = 0.31;

const CardTicket = ({ item, dolar }) => {
  const { makeBook } = useSportingEvents();
  const [price, setPrice] = useState(Math.ceil(item.Price * dolar));
  const [nTicketS, setnTicketS] = useState(0);

  const markup = prices => {
    // eslint-disable-next-line no-console
    console.log('🚀 ~ CardTicket.js', { prices });
    const newprice = prices - prices * markUP;

    // eslint-disable-next-line no-console
    console.log('🚀 ~ CardTicket.js', { newprice });
    return Math.round(newprice);
  };

  const handleBookClick = () => {
    const id = item.ItemID;
    const newItem = item;
    newItem.nTickets = nTicketS;
    newItem.dollar = dolar;
    makeBook(newItem, id);
  };
  const Ntickets = (n, p) => {
    const e = [];
    for (let i = 0; i < n.length; i++) {
      e.push({ value: Math.ceil(p * n[i] * dolar), label: n[i] });
    }

    return e;
  };
  const optionNtickets = Ntickets(
    item.available_selling_quantities,
    item.Price,
  );

  return (
    <Card>
      <Row>
        <Col xs="12" lg="9">
          <Container className="h-100 mh-100 m-0 d-flex align-items-start flex-column justify-content-around">
            <Row>
              <Col>
                <Text bold size="25" color="#0C406B">
                  {item.Section}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text color="#0C406B">{item.data.city}</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text color="#0C406B">{item.data.time_of_event}</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text color="#0C406B">{item.data.venue}</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text color="#0C406B">{item.data.venue_address}</Text>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col xs="12" lg="3" className="bg1-danger">
          <Container className="h-100 mh-100 m-0 d-lg-flex align-items-end flex-column justify-content-around">
            <Row>
              <Row>
                <Col>
                  <Select
                    options={optionNtickets}
                    isMulti={false}
                    onChange={value => {
                      setPrice(value.value);
                      setnTicketS(value.label);
                    }}
                    isClearable
                  />

                  <Text medium size="35" lineHeight="43" color="#A1C3E1">
                    {`${markup(price)} USD`}
                  </Text>
                </Col>
              </Row>
              <Row className="w-100">
                <Col
                  xs={{ span: 6, offset: 3 }}
                  lg={{ span: 6, offset: 6 }}
                  className="px-0"
                >
                  <Button
                    variant="outline-primary"
                    className="rounded-pill w-100"
                    onClick={handleBookClick}
                  >
                    Book
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Col>
      </Row>
    </Card>
  );
};

export default CardTicket;
