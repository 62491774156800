import React from 'react';

import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PriceFormat from '../PriceFormat';
import styles from './CardCar.module.scss';
import ListFeatures from './ListFeatures';

const CardCar = ({ car, onClick }) => {
  // console.log('🚀 ~ CardCar.jsx', { car });
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <Row xs="1">
          <Col lg="4">
            <Row xs="1">
              <Col>
                <img
                  className={`${styles.imageCar} img-thumbnail`}
                  src={car.picture}
                  alt={car.name}
                />

                <img
                  className={styles.imageCompany}
                  src={car.company.image}
                  alt={car.company.name}
                />
              </Col>
            </Row>
          </Col>

          <Col lg="8" className="d-flex align-self-center">
            <Row xs="1" className="w-100">
              <Col lg="12" className="m-0 p-0">
                <h4 className="font-weight-bold">{car.name}</h4>
                <span className="d-block">{car.vehicleClass}</span>
              </Col>

              <Col xl="6" className="m-0 p-0">
                <ListFeatures car={car} />
              </Col>

              <Col xl="6">
                <Row xs="1" className={styles.containerRight}>
                  {car.pickUpLocation.length > 0 && (
                    <Col className={styles.columnContainerTotal}>
                      <h6 className="font-weight-bold">
                        {t('CarsPickUpPlace')}
                      </h6>
                      <span className="d-block">{car.pickUpLocation}</span>
                    </Col>
                  )}

                  {car.dropOffLocation.length > 0 && (
                    <Col className={styles.columnContainerTotal}>
                      <h6 className="font-weight-bold">
                        {t('CarsDropOffPlace')}
                      </h6>
                      <span className="d-block">{car.dropOffLocation}</span>
                    </Col>
                  )}

                  <Col className={styles.columnContainerTotal}>
                    <PriceFormat
                      value={parseFloat(car.price.total)}
                      currency={car.price.currency}
                    >
                      {valueText => (
                        <h4 className={styles.textTotal}>{valueText}</h4>
                      )}
                    </PriceFormat>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col className={styles.columnContainerButton}>
            <Button
              variant="primary"
              className="font-weight-bold"
              onClick={() => {
                if (typeof onClick === 'function') {
                  onClick(car);
                }
              }}
            >
              {t('CarsViewDetail')}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CardCar;
