import React, { Suspense, useEffect } from 'react';

import Switch from 'react-bootstrap/esm/Switch';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { I18nextProvider, withTranslation } from 'react-i18next';
import { BrowserRouter, Route } from 'react-router-dom';
import FloatingButtonWhatsApp from './components/FloatingButtonWhatsApp';
import PrivateRoute from './components/PrivateRoute';
import LoadingTranslations from './components/loader/LoadingTranslations';
import useAppTranslation from './hook/useAppTranslation';
import i18n from './i18n';
import Home from './pages/Home';
import Welcome from './pages/Welcome';
import sleep from './utils/sleep';

const App = () => {
  const { ready } = useAppTranslation();

  useEffect(() => {
    const { overflow } = window.document.querySelector('body').style;
    const blockScroll = async () => {
      await sleep(500);
      window.scrollTo({
        top: 0,
        left: 0,
        // behavior: 'smooth',
      });
    };

    blockScroll();
    return () => {
      window.document.querySelector('body').style.overflow = overflow;
    };
  }, []);

  if (!ready) return <LoadingTranslations />;

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Suspense fallback="loading">
            <I18nextProvider i18n={i18n}>
              <Route path="/welcome" component={Welcome} />
              <PrivateRoute path="/" component={Home} />
            </I18nextProvider>
          </Suspense>
        </Switch>
      </BrowserRouter>

      <FloatingButtonWhatsApp />
    </>
  );
};

export default withTranslation()(App);
