import React from 'react';
import moment from 'moment';
import { Card, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';

const CondosDatosReserva = ({ t, condoInfo }) => {
  const { rates: room, checking, checkout } = condoInfo;
  const { effectiveDate, expireDate } = room;
  // eslint-disable-next-line no-console
  console.log('🚀 ~ CondosDatosReserva.js', { effectiveDate, expireDate });

  const checkInDate = moment(effectiveDate, 'DD, MMM, YYYY').format(
    'DD, MMM, YYYY',
  );
  const checkOutDate = moment(expireDate, 'DD MMM, YYYY').format(
    'DD MMM, YYYY',
  );
  // eslint-disable-next-line no-console
  console.log('🚀 ~ CondosDatosReserva.js', { checkInDate, checkOutDate });
  const fecha1 = moment(room?.effectiveDate, 'DD-MM-YYYY');
  const fecha2 = moment(room?.expireDate, 'DD-MM-YYYY');
  const duration = moment.duration(fecha2.diff(fecha1)).asDays();

  return (
    <Card className="datos-reserva-container bg-primary-color container-info">
      <div className="section-title"> {t('YourReservationDetail')} </div>
      <Row>
        <Col className="br-right-1">
          <label className="d-block mb-0">
            <FaCalendarAlt className="icon-14 mb-1 mr-2" />
            {t('Entrada')}
          </label>
          <label className="d-block ft-08rem mb-0">{checkInDate}</label>
          <label className="d-block ft-08rem mb-0">{checking}&nbsp; hrs.</label>
        </Col>
        <Col>
          <label className="d-block mb-0">
            <FaCalendarAlt className="icon-14 mb-1 mr-2" />
            {t('SalidaCheckout')}
          </label>
          <label className="d-block ft-08rem mb-0">{checkOutDate}</label>
          <label className="d-block ft-08rem mb-0">{checkout}&nbsp; hrs.</label>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <label className="d-block mb-0">
            <FaCalendarAlt className="icon-14 mb-1 mr-2" />
            {t('DuracionEstancia')}
          </label>
          <label className="d-block ft-08rem mb-0">
            {duration}&nbsp;{duration > 1 ? t('Noches') : t('Noche')}
          </label>
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(CondosDatosReserva);
