import React, { useContext, useEffect, useState } from 'react';

import { Accordion, AccordionContext, Card } from 'react-bootstrap';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import sleep from '../../utils/sleep';

export const BookingAccordionToggle = ({
  children,
  open,
  eventKey,
  onOpen,
  onClose,
}) => {
  const currentEventKey = useContext(AccordionContext);
  const [isOpen, setIsOpen] = useState(Boolean(open));

  useEffect(() => {
    let didCancel = false;
    const set = async () => {
      await sleep(50);
      if (didCancel) return;

      const isCurrentEventKey = currentEventKey === `${eventKey}`;
      setIsOpen(isCurrentEventKey);
    };

    set();
    return () => {
      didCancel = true;
    };
  }, [currentEventKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let didCancel = false;
    const toggle = async () => {
      await sleep(50);
      if (didCancel) return;

      // console.log('🚀 ~ BookingAccordion.jsx', { isOpen });
      if (isOpen) {
        if (typeof onOpen === 'function') onOpen();
      } else if (typeof onClose === 'function') onClose();
    };

    toggle();
    return () => {
      didCancel = true;
    };
  }, [isOpen, onOpen, onClose]);

  return (
    <Accordion.Toggle
      as={Card.Header}
      eventKey={`${eventKey}`}
      className="d-flex"
    >
      <div className="cursor-pointer d-flex">
        {children}
        <div className="d-flex ml-1">
          {isOpen ? <FaCaretUp /> : <FaCaretDown />}
        </div>
      </div>
    </Accordion.Toggle>
  );
};

export const BookingAccordionCollapse = ({ children, eventKey }) => {
  return (
    <Accordion.Collapse eventKey={`${eventKey}`}>
      <Card.Body>{children}</Card.Body>
    </Accordion.Collapse>
  );
};
