import React, { useEffect, useMemo, useState } from 'react';

import Pagination from 'rc-pagination';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import EmptyItems from '../components/EmptyItems';
import ErrorServer from '../components/ErrorServer';
import FilterSidebar from '../components/FilterSidebar';
import MapSidebar from '../components/MapSidebar';
import SearchCondominios from '../components/SearchCondominios';
import SectionTop from '../components/SectionTop';
import CardCondos from '../components/condos/CardCondos';
import CondosFormSideBar from '../components/condos/CondosFormSideBar';
import MarketplaceContactUs from '../components/marketplaces/MarketplaceContactUs';
import '../css/condos/CondosList.css';
import useQuery from '../hook/useQuery';
import CargandoOpciones from '../loader/CargandoOpciones';
import { CONDOS_LIST } from '../services/Routes';
import post from '../services/post';
import FiltersContainer from '../shared/FiltersContainer';
import getCadena from '../utils/getCadena';
import getPrecio from '../utils/getPrecio';
import getStars from '../utils/getStars';

const condosPerPage = 20;
const CondosListado = () => {
  const { t, i18n } = useTranslation();
  const query = useQuery();
  const data = JSON.parse(query.get('search'));

  const [loading, setLoading] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsFilter, setItemsFilter] = useState([]);
  const [minPrecio, setMinPrecio] = useState(0);
  const [maxPrecio, setMaxPrecio] = useState(100);
  const [valuePrecio, setValuePrecio] = useState({ min: 1, max: 100 });
  const [positions, setPositions] = useState([]);
  const [errorData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [starFilter, setStarFilter] = useState(false);
  const [condosTotal, setCondosTotal] = useState(0);
  const [precioFilter, setPrecioFilter] = useState(true);
  const [center, setCenter] = useState(null);
  const [position, setPosition] = useState(-1);
  const indexOfLastPost = useMemo(
    () => currentPage * condosPerPage,
    [currentPage],
  );
  const indexOfFirstPost = useMemo(
    () => indexOfLastPost - condosPerPage,
    [indexOfLastPost],
  );

  const sendData = newData => {
    window.scrollTo(0, 0);
    setErrorServer(false);

    const globalizador = `${process.env.REACT_APP_SUNAPI_API_NAME_CONDOS}`;
    const urlService = `${CONDOS_LIST}?sources=${globalizador}`;

    if (newData) {
      setLoading(true);
      post({
        url: urlService,
        data: newData,
      })
        .then(response => {
          // eslint-disable-next-line no-shadow
          const { data } = response;
          const condos = data.hotels;
          const newCondos = [];

          // Recorre la lista de los condominios para dejar solo los no repetidos
          // eslint-disable-next-line array-callback-return
          condos.map(condo => {
            const ar = newCondos.filter(c => c.id === condo.id);
            if (ar.length === 0) newCondos.push(condo);
          });

          // Determinar si filtramos por ranking
          if (data.f_stars != null && data.f_stars.length > 0)
            setStarFilter(true);

          // Determinar si filtramos por precio
          if (data.minPrice === data.maxPrice) setPrecioFilter(false);

          setItems(newCondos);
          setItemsFilter(newCondos);
          setMinPrecio(data.minPrice);
          setMaxPrecio(data.maxPrice);
          setValuePrecio({ min: data.minPrice, max: data.maxPrice });
          setCondosTotal(newCondos.length);

          // obtiene la posicion de los condos para posicionar en el mapa
          if (
            condos.filter(
              condo =>
                condo.address !== null && condo.address.geolocation !== null,
            ).length > 0
          ) {
            setPositions(
              condos
                .filter(
                  condo =>
                    condo.address !== null &&
                    condo.address.geolocation !== null,
                )
                .map(el => ({
                  id: el.id,
                  place: el.name,
                  raiting: el.stars,
                  latitude:
                    el.address.geolocation !== null
                      ? el.address.geolocation.latitude
                      : null,
                  longitude:
                    el.address.geolocation !== null
                      ? el.address.geolocation.longitude
                      : null,
                })),
            );
            setCenter({
              latitude: condos.filter(
                ele =>
                  ele.name !== null &&
                  ele.address !== null &&
                  ele.address.geolocation !== null,
              )[0].address.geolocation.latitude,
              longitude: condos.filter(
                ele =>
                  ele.name !== null &&
                  ele.address !== null &&
                  ele.address.geolocation !== null,
              )[0].address.geolocation.longitude,
            });
          }
          setLoading(false);
        })
        .catch(() => {
          // console.error('Error en la busqueda de los condominios', ex);
          setErrorServer(true);
          setLoading(false);
        });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => sendData(data), [i18n.language]);

  // Maneja los filtrado de condos
  const searchList = (valor, typeFilter) => {
    if (typeFilter === 'nombre') {
      if (valor !== '') {
        const arr = [...items];
        setItemsFilter(
          arr.filter(elem => getCadena(elem.name).includes(valor)),
        );
      } else {
        setItemsFilter(items);
      }
    }

    if (typeFilter === 'precio') {
      const { min } = valor;
      const { max } = valor;
      const arr = [...items];
      setItemsFilter(
        arr.filter(
          elem => getPrecio(elem.total) >= min && getPrecio(elem.total) <= max,
        ),
      );
    } else if (typeFilter === 'stars') {
      if (valor.includes('todas') || valor.length === 0) {
        setItemsFilter(items);
      } else {
        const arr = [...items];
        setItemsFilter(arr.filter(el => valor.includes(getStars(el.stars))));
      }
    }
  };

  const onHandleClickCard = (id, source) => {
    data.apiName = source;
    data.chain = '';
    data.id = id;

    window.open(
      `/condos/detail?search=${encodeURIComponent(JSON.stringify(data))}`,
      '_blank',
    );
  };

  // centra en el mapa la posición del condominio
  const handleOnHoverItem = item => {
    setCenter(item.address.geolocation);
    const findIndex = items.findIndex(el => el.id === item.id);
    setPosition(findIndex);
  };

  // Quita del mapa la posición del condominio
  const handleOnHoverOutItem = () => {
    setPosition(-1);
  };

  if (loading) return <CargandoOpciones />;

  if (!data)
    return (
      <>
        <FiltersContainer title={t('Condominios')}>
          <SearchCondominios searching={data} sendData={sendData} />
        </FiltersContainer>

        <Container fluid>
          <Row className="justify-content-md-center mb-5">
            <Col xs="12" md="10" xl="8">
              <MarketplaceContactUs />
            </Col>
          </Row>
        </Container>
      </>
    );

  return (
    <>
      <SectionTop
        menu={t('Condominios', { count: items.length })}
        destino={data.destino_name}
        total={condosTotal}
        product={t('Condominios', { count: items.length })}
      />

      <Container fluid className="mt-3">
        <Row>
          <Col xs="12" lg="4">
            <Row>
              <Col xs="12" lg="12">
                <CondosFormSideBar searching={data} sendData={sendData} />
              </Col>
              <Col xs="12" lg="12">
                <Row className="justify-content-center my-4">
                  <Col xs="12" lg="10">
                    {items.length > 0 && (
                      <MapSidebar
                        positionsList={positions}
                        items={items}
                        position={position}
                        setPosition={setPosition}
                        center={center}
                        setCenter={setCenter}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xs="12" lg="12">
                {items.length > 0 && (
                  <FilterSidebar
                    searchList={searchList}
                    mPrecio={minPrecio}
                    mxPrecio={maxPrecio}
                    valuePrecio={valuePrecio}
                    showStars={starFilter}
                    showPrecio={precioFilter}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg="8">
            {itemsFilter.length === 0 && !errorServer && <EmptyItems />}
            {errorServer && <ErrorServer errorData={errorData} />}
            {itemsFilter
              .slice(indexOfFirstPost, indexOfLastPost)
              .map((item, i) => (
                <CardCondos
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  item={item}
                  huespedesGet={data.rooms}
                  fechaStar={data.checkIn}
                  fechaEnd={data.checkOut}
                  onHandleClickCard={onHandleClickCard}
                  handleOnHoverItem={handleOnHoverItem}
                  handleOnHoverOutItem={handleOnHoverOutItem}
                />
              ))}
            <div>
              {itemsFilter.length > 0 && (
                <Pagination
                  onChange={page => setCurrentPage(page)}
                  current={currentPage}
                  total={itemsFilter.length}
                  showLessItems
                  showTitle
                  pageSize={condosPerPage}
                  className="pagination"
                  prevIcon={<BsChevronLeft />}
                  nextIcon={<BsChevronRight />}
                  jumpPrevIcon={<BiDotsHorizontalRounded />}
                  jumpNextIcon={<BiDotsHorizontalRounded />}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CondosListado;
