import React from 'react';

import { Formik } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import useHotel from '../../hook/useHotel';
import { HOTELS_FILTER } from '../../services/Routes';
import get from '../../services/get';
import SelectAjax from '../SelectAjax';

const yearNow = new Date();
const year = yearNow.getFullYear();
const optionsUnitType = [
  { value: 'hotel', label: 'Hotel' },
  { value: 'studio', label: 'Studio' },
  { value: '1 bedroom', label: '1 bedroom' },
  { value: '2 bedroom', label: '2 bedroom' },
  { value: '3 bedroom', label: '3 bedroom' },
  { value: '4 bedroom', label: '4 bedroom' },
  { value: '5 bedroom', label: '5 bedroom' },
  { value: '6 bedroom', label: '6 bedroom' },
];
const week = () => {
  const e = [];
  for (let i = 1; i < 54; i++) {
    e.push({ value: i, label: i });
  }
  return e;
};
const years = date => {
  let y = date;
  const e = [];
  for (let i = 1; i < 10; i++) {
    e.push({ value: y, label: y });
    y += 1;
  }

  return e;
};
const optionYear = years(year);
const optionWeekNumber = week();

const FormGroup = ({ xs, lg, className, showLabel, label, children }) => {
  return (
    <Col xs={xs} lg={lg} className={className}>
      {showLabel ? (
        <Form.Group>
          <Form.Label className="text-light">{label}</Form.Label>

          {children}
        </Form.Group>
      ) : (
        children
      )}
    </Col>
  );
};

const FormSearch = ({ inline = false, onSubmit }) => {
  const { t } = useTranslation();
  const { query } = useHotel();

  return (
    <Formik
      initialValues={
        query || {
          resort: null,
          unitType: null,
          weekNumber: null,
          year: null,
        }
      }
      // validationSchema={Yup.object().shape({
      //   resort: Yup.string().required(),
      //   unitType: Yup.string().required(),
      //   weekNumber: Yup.string().required(),
      //   year: Yup.string().required(),
      // })}
      onSubmit={async values => {
        // console.log('🚀 ~ FormSearch.js', { values });
        onSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setTouched,
        handleSubmit,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="flex space-x-3 justify-center">
              <FormGroup
                placeholder="Destinos"
                lg={inline ? '6' : '4'}
                className={inline && 'w-80' && 'mb-2' && 'bg-indigo-500'}
              >
                <SelectAjax
                  defaultValue={values.destiny}
                  isMulti={false}
                  loadOptions={async (inputValue, cb) => {
                    if (inputValue.length >= 3) {
                      const url = `${HOTELS_FILTER}?q=${inputValue}`;
                      const res = await get(url);
                      cb(
                        (res.data || []).map(item => {
                          return {
                            hotel: item?.id,
                            value: item?.destinationDTO?.id,
                            label: `${item.name} - ${item.destinationDTO?.state?.name} - ${item.destinationDTO?.state?.country?.name}`,
                          };
                        }),
                      );
                    }
                  }}
                  handleChange={value => {
                    setFieldValue('destiny', value);
                  }}
                  handleBlur={() => {
                    setTouched({ ...touched, destiny: true });
                  }}
                  defaultOptions={values.destiny}
                  valid={touched.destiny && errors.destiny}
                  isClearable
                />
                {touched.destiny && errors.destiny && (
                  <Form.Control.Feedback type="invalid">
                    {t(errors.destiny)}
                  </Form.Control.Feedback>
                )}
              </FormGroup>
              <Select
                options={optionsUnitType}
                className="w-80"
                placeholder="Unit Type"
              />
              <Select options={optionWeekNumber} className="w-80" />
              <Select options={optionYear} className="w-80" />
              <button
                type="submit"
                alt=""
                className="w-80 px-4 py-1 border-0 border-none rounded-sm bg-blue-600 hover:bg-blue-800 text-white cursor-pointer"
              >
                Check redemption value
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormSearch;
