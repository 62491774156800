import { GET_DESTINIES_FILTER, GET_DESTINY_BY_ID } from './Routes';
import instance from './instance';

const languages = {
  en: 1,
  es: 2,
};

export const getLabel = ({ name, code, state }) =>
  [name || code, state?.country?.name?.length && state.country.name]
    .filter(Boolean)
    .join(', ');

export const getDestinies = async ({
  searchString,
  language,
  typeDestination,
}) => {
  // console.log('🚀 ~ destinations.js', { searchString, language });
  const destinies = await instance
    .get(GET_DESTINIES_FILTER, {
      params: {
        q: searchString,
        languageId: languages[language] ?? 1,
      },
    })
    .then(res => {
      if (!res?.data?.length) return [];

      const destinations = res.data
        .filter(it => {
          if (!typeDestination?.length) return true;

          return it.typeDestination === typeDestination;
        })
        .map(it => {
          // console.log('🚀 ~ destinations.js', { it });
          const label = getLabel(it);

          return { value: it.id, label };
        });

      return destinations;
    })
    .catch(() => []);

  return destinies;
};

export const getLabelByDestinyId = async ({ destinyId, language }) => {
  if (!destinyId || !language) return null;

  const url = GET_DESTINY_BY_ID({
    destinyId,
    language: languages[language] ?? 1,
  });

  const label = await instance
    .get(url)
    .then(res => res?.data)
    .then(destiny => getLabel(destiny))
    .catch(() => null);

  return label;
};

export const getLabelsByDestinyId = async ({ destinyId }) => {
  if (!destinyId) return null;

  const entries = await Promise.all(
    Object.keys(languages).map(async key => {
      const label = await getLabelByDestinyId({ destinyId, language: key });

      return [key, label];
    }),
  );

  return Object.fromEntries(entries);
};
