import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import sleep from '../utils/sleep';
import { useAuth } from './AuthProvider';

const userProvider = createContext();

const USER_BLACK = {
  name: 'María',
  lastName1: 'Bárcenas',
  lastName2: 'Martínez',
  dateOfBirth: '',
  email: 'Mar@gmail.com',
  additionalEmail: '',
  telephone: '55 5500 2020',
  country: 'México',
  state: 'Estado Ciudad de México',
};

const USER_PLATINUM = {
  name: 'Alejandra',
  lastName1: 'Mateos',
  lastName2: '',
  dateOfBirth: '',
  email: 'alexmat@gmail.com',
  additionalEmail: '',
  telephone: '55 5500 2010',
  country: 'México',
  state: 'Estado Ciudad de México',
};

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const { isPlatinum } = useAuth();

  useEffect(() => {
    let didCancel = false;
    const set = async () => {
      await sleep(300);
      if (didCancel) return;

      const newUser = isPlatinum ? USER_PLATINUM : USER_BLACK;
      setUser(newUser);
    };

    set();
    return () => {
      didCancel = true;
    };
  }, [isPlatinum]);

  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <userProvider.Provider value={value}>{children}</userProvider.Provider>
  );
};

export const useUser = () => {
  const ctx = useContext(userProvider);

  if (!ctx) throw new Error('useUser must be used within a UserProvider');

  return ctx;
};

export default UserProvider;
