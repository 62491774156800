import React, { useEffect } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SectionPromotionNY from '../components/SectionPromotionNY';
import Booking from '../components/sportingEvents/Booking';
import Checkout from '../components/sportingEvents/Checkout';
import Detail from '../components/sportingEvents/Detail';
import Home from '../components/sportingEvents/Home';
import List from '../components/sportingEvents/List';
import Warranty from '../components/sportingEvents/Warranty';
import MainContainer from '../shared/MainContainer';
import PriceBottom from '../shared/PriceBottom';

const SportingEvents = () => {
  const { url } = useRouteMatch();

  useEffect(() => {
    // console.count('🚀 ~ Home.jsx ~ useEffect(url)');

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [url]);

  return (
    <MainContainer>
      <Switch>
        <Route exact path={url} component={Home} />
        <Route path={`${url}/list/:query`} component={List} />
        <Route path={`${url}/detail/:query`} component={Detail} />
        <Route path={`${url}/warranty/:query`} component={Warranty} />
        <Route path={`${url}/booking/:id`} component={Booking} />
        <Route path={`${url}/checkout/:query/:id`} component={Checkout} />
      </Switch>

      <SectionPromotionNY />
      <PriceBottom />
    </MainContainer>
  );
};

export default SportingEvents;
