import React, { useEffect, useMemo, useState } from 'react';

import { nanoid } from 'nanoid';
import RCPagination from 'rc-pagination';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import styled from 'styled-components';
import EmptyItems from './EmptyItems';

const Pagination = ({
  items,
  component: Component,
  render,
  children,
  itemsPerPage = 5,
  page = 1,
  onChange,
}) => {
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    if (page !== currentPage) setCurrentPage(page);
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const itemsRender = useMemo(() => {
    const first = (currentPage - 1) * itemsPerPage;
    const last = first + itemsPerPage;
    const slice = items.slice(first, last);

    return slice.map(item => {
      const id = item?.id || nanoid();
      return (
        <React.Fragment key={id}>
          {Component ? (
            <Component {...item} />
          ) : render ? (
            render(item)
          ) : children ? (
            children(item)
          ) : (
            item
          )}
        </React.Fragment>
      );
    });
  }, [currentPage, itemsPerPage, items, Component, render]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = newPage => {
    setCurrentPage(newPage);

    if (onChange) onChange(newPage);

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  if (!items?.length) return <EmptyItems />;

  return (
    <>
      {itemsRender}

      <PaginationStyled
        onChange={handleChange}
        current={currentPage}
        total={items.length}
        showLessItems
        showTitle
        pageSize={itemsPerPage}
        className="pagination"
        prevIcon={<BsChevronLeft />}
        nextIcon={<BsChevronRight />}
        jumpPrevIcon={<BiDotsHorizontalRounded />}
        jumpNextIcon={<BiDotsHorizontalRounded />}
      />
    </>
  );
};

const PaginationStyled = styled(RCPagination)`
  margin-block: 1rem;
  display: flex;
  justify-content: center;
`;

export default Pagination;
