/* eslint-disable react/no-array-index-key */
import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { Image } from 'react-bootstrap';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai/index';

import '../../css/condos/CondosGaleria.css';

const CondoGaleria = ({ photos }) => {
  const [state, setState] = useState({ nav1: null, nav2: null });
  const slider1 = useRef();
  const slider2 = useRef();
  const { nav1, nav2 } = state;

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    });
  }, []);

  return (
    <div className="slider-content">
      <Slider
        key={1}
        asNavFor={nav2}
        ref={slider => {
          slider1.current = slider;
        }}
        arrows
        className="slider-content"
      >
        {photos.map((photo, p) => (
          <div key={p}>
            <div className="image-content">
              <Image
                src={photo.url != null ? photo.url.replace(' ', '') : ''}
                fluid
                className="h-100p object-cover img-responsive"
              />
            </div>
          </div>
        ))}
      </Slider>

      <Slider
        key={2}
        asNavFor={nav1}
        ref={slider => {
          slider2.current = slider;
        }}
        slidesToShow={8}
        swipeToSlide
        focusOnSelect
        infinite={false}
        nextArrow={<AiFillRightCircle />}
        prevArrow={<AiFillLeftCircle />}
        className="slider-items"
      >
        {photos.map((item, i) => (
          <div key={i}>
            <div className="image-item-content">
              <Image
                src={item.url != null ? item.url.replace(' ', '') : ''}
                fluid
                className="h-100p object-cover img-responsive"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CondoGaleria;
