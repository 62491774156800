import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ErrorServer from '../../components/ErrorServer';
import EmptyData from '../../components/Hotels/EmptyData';
import PromoCondos from '../../components/PromoCondos';
import SectionTop from '../../components/SectionTop';
import useHotel from '../../hook/useHotel';
import { HOTEL_DETAIL } from '../../services/Routes';
import post from '../../services/post';

const globalizador = `${process.env.REACT_APP_SUNAPI_API_NAME_HOTEL}`;
const urlService = `${HOTEL_DETAIL}?sources=${globalizador}`;
const urlServiceByHotels = '';
const imgWarranty = require('../../img/garantia.png');

const Warranty = () => {
  const { query, createBody } = useHotel();
  const { t, i18n } = useTranslation();

  const [, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [, setPosition] = useState({});
  const [, setCenter] = useState([]);
  const [errorServer, setErrorServer] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [errorStatus] = useState(null);
  const handleSubmit = async values => {
    const dataWarranty = {
      ...values,
      destiny: query.destiny.label,
      hotel: query.id,
      value: query.destiny.value,
      checkIn: query.checkIn,
      checkOut: query.checkOut,
      rooms: query.rooms,
    };

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(dataWarranty, null, 2));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const source = axios.CancelToken.source();
    setLoading(false);
    setErrorServer(false);
    setItem(null);

    const fetch = async () => {
      if (query) {
        try {
          // console.log('🚀 ~ Warranty.js ~ Init');
          setLoading(true);

          const language =
            i18n.language && i18n.language === 'en' ? 'EN' : 'CAS';
          const body = createBody(language);

          const res = await post({
            url: query.by === '1' ? urlService : urlServiceByHotels,
            data: body,
            token: source.token,
          });
          // console.log('🚀 ~ Warranty.js', { res });

          if (query.by === '1') {
            if (res?.data) {
              if (res.data.errors?.length) throw res.data.errors;

              const { data } = res;
              setItem(data);

              if (data.address?.geolocation) {
                setPosition([
                  {
                    id: data.id,
                    place: data.name,
                    raiting: data.stars,
                    latitude: data.address.geolocation.latitude || null,
                    longitude: data.address.geolocation.longitude || null,
                  },
                ]);

                setCenter({
                  latitude: data.address.geolocation.latitude || null,
                  longitude: data.address.geolocation.longitude || null,
                });
              }
            }
          } else if (res?.data) {
            // if (res.data.errors?.length) throw res.data.errors;
            const data = res.data[0];
            setItem(data);

            if (data.address?.geolocation) {
              setPosition([
                {
                  id: data.id,
                  place: data.name,
                  raiting: data.stars,
                  latitude: data.address.geolocation.latitude || null,
                  longitude: data.address.geolocation.longitude || null,
                },
              ]);

              setCenter({
                latitude: data.address.geolocation.latitude || null,
                longitude: data.address.geolocation.longitude || null,
              });
            }
          }
        } catch (err) {
          // console.log('🚀 ~ Warranty.js', { err });

          setItem(null);
          setErrorData(Array.isArray(err) ? err : [err]);
          setErrorServer(true);
        } finally {
          setLoading(false);
        }
      }
    };

    fetch();

    return () => {
      // console.log('🚀 ~ Warranty.js ~ Cancel');
      source.cancel();
    };
  }, [query, i18n]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SectionTop
        menu={t('Hoteles_one')}
        destino={query.destiny.label}
        nombre={item?.name}
        direccion={item?.address?.addressName || ''}
      />

      {!item ? (
        <EmptyData />
      ) : (
        <Container fluid className="my-3">
          <Row>
            <Col xs="12" lg="3">
              <Row>
                <Col xs="12" lg="10">
                  <h1 className="text-go text-right ft-6em">GO!</h1>
                </Col>
                <Col xs="12" lg="12">
                  <PromoCondos />
                </Col>
              </Row>
            </Col>
            {errorServer ? (
              <ErrorServer errorData={errorData} errorStatus={errorStatus} />
            ) : (
              <>
                <Col xs="12" lg="3">
                  <Row>
                    <img src={imgWarranty} alt="Garantia" />
                  </Row>
                </Col>

                <Row>
                  <Formik
                    initialValues={{
                      fullName: '',
                      phone: '',
                      email: '',
                      url: '',
                      reservation: '',
                    }}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <Col className="mb-3">
                        <Row md={4}>
                          <label htmlFor="fullName">{t('Nombre')}</label>
                          <Col className="mb-3">
                            <Field
                              id="fullName"
                              name="fullName"
                              placeholder=""
                            />
                          </Col>
                        </Row>
                        <Row md={4}>
                          <label htmlFor="phone">{t('Telefono')}</label>
                          <Col className="mb-3">
                            <Field id="phone" name="phone" placeholder="" />
                          </Col>
                        </Row>
                        <Row md={4}>
                          <label htmlFor="email">Email</label>
                          <Col className="mb-3">
                            <Field
                              id="email"
                              name="email"
                              placeholder=""
                              type="email"
                            />
                          </Col>
                        </Row>
                        <Row md={4}>
                          <label htmlFor="url">Url</label>
                          <Col className="mb-4">
                            <Field id="url" name="url" placeholder="" />
                          </Col>
                        </Row>
                        <Row md={4}>
                          <label htmlFor="reservation">{t('Reserva')}</label>
                          <Col className="mb-3">
                            <Field
                              id="reservation"
                              name="reservation"
                              placeholder=""
                            />
                          </Col>
                        </Row>

                        <button
                          className="btn btn-primary btn-block text-uppercase font-weight-bold "
                          type="submit"
                        >
                          {t('enviar')}
                        </button>
                      </Col>
                    </Form>
                  </Formik>
                </Row>
              </>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default Warranty;
