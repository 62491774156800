/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Col, Jumbotron, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

const AdicionalInformation = ({ t, additionalInformation }) => {
  return (
    <Row className="mt-4">
      <Col>
        <h2>{t('InfoAdicional')}</h2>
        {additionalInformation === null ? (
          <div>
            <Jumbotron>
              <p>{t('NoInformacionMostrar')}</p>
            </Jumbotron>
          </div>
        ) : (
          <div>
            {additionalInformation.length === 0 ? (
              <Jumbotron>
                <p>{t('NoInformacionMostrar')}</p>
              </Jumbotron>
            ) : (
              <ul>
                {additionalInformation.map((item, i) => (
                  <li className="text-secondary" key={i}>
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default withTranslation()(AdicionalInformation);
