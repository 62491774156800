export const LOGIN_SUNAPI = '/auth/login';
export const GET_DESTINOS = '/content/destinations';
export const GET_DESTINY_BY_ID = ({ destinyId, language }) =>
  `/content/destinations/${destinyId}/language/${language}`;
export const GET_DESTINIES_FILTER = '/content/destinationsFilter';

export const HOTELS_FILTER = '/content/hotelsFilter';
export const HOTEL_LIST = '/hotel/availabilityHotels';
export const HOTEL_IMAGES = ({ hotelId }) => `/hotel/${hotelId}/photos`;
export const HOTEL_PRICE_COMPARISON = '/hotel/price_comparison';
export const HOTEL_DETAIL = '/hotel/detailHotel';
export const HOTEL_RESERVATION = '/reservation/booking';

export const CONDOS_LIST = '/condos/availability';
export const CONDOS_DETAIL = '/condos/detail';

export const TOUR_LIST = '/activity/available';
export const TOUR_DETAIL = '/activity/detailActivity';

export const CAR_LIST = '/vehicle/availabilityVehicles';
export const CAR_OFFICES_LIST = '/content/offices';
export const CAR_DETAIL = '/vehicle/vehicleDetail';

export const FLIGHT_LIST = '/flight/search';
export const FLIGHT_CHECK_PRICE = '/flight/checkPrice';

export const AIRLINES_LIST = '/content/airlines';

export const CRUISE_SEARCH_PARAMS = '/cruises/search-parameters';
export const CRUISE_LIST = '/cruises';

export const RESERVATION_BOOKING = '/reservation/booking';

export const RESTAURANTS_LIST = '/restaurant';
export const RESTAURANT_DETAIL = ({ restaurantId }) =>
  `/restaurant/${restaurantId}`;

export const ROAD_GOAT_AUTOCOMPLETE = '/destinations/auto_complete';

export const BOOKING = '/reservation/booking';
