import React, { useMemo, useState } from 'react';

import { Markup } from 'interweave';
import moment from 'moment';
import { Button, Col, Image, Jumbotron, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../css/ReviewInformation.css';
import Rating from '../shared/Rating';
import Pagination from './Pagination';

const MAX_LENGTH_BY_COMMENT = 300;
const ITEMS_PER_PAGE = 6;

const ReviewCard = ({ review }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const description = review?.description?.trim?.() ?? '';
  const isGreaterMax = description.length > MAX_LENGTH_BY_COMMENT;
  const content = useMemo(() => {
    if (!isOpen && isGreaterMax) {
      return `${description.substring(0, MAX_LENGTH_BY_COMMENT)}...`;
    }

    return description;
  }, [isOpen, description, isGreaterMax]);

  const toggleContent = () => setIsOpen(prev => !prev);

  return (
    <Col xs="12" lg="12" className="mb-3">
      <div className="d-flex align-items-center">
        <div className="pr-4">
          <h6 className="mb-0">
            {review.ownerName && review.ownerName}
            {review.publishedDate &&
              `${review.ownerName ? ',' : ''} ${moment(
                review.publishedDate,
                'DD-MM-YYYY',
              ).format('MMM YYYY')}`}
          </h6>

          <span className="exp">
            <Rating rating={review.rating} />
          </span>
        </div>

        <div>
          <Image src="/images/tripadvisor.png" fluid className="mw-75px" />
        </div>
      </div>

      <div className="child-p">
        <Markup content={content} />
      </div>

      {review.description.length > MAX_LENGTH_BY_COMMENT && (
        <Button
          variant="link"
          className="text-success p-0 shadow-none"
          onClick={toggleContent}
        >
          {t(isOpen ? 'VerMenos' : 'VerMas')}
        </Button>
      )}
    </Col>
  );
};

const ReviewInformation = ({ reviews }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  const avg = useMemo(() => {
    const total = (reviews ?? []).reduce(
      (acc, { rating }) => acc + rating,
      0.0,
    );

    return (total / reviews.length).toFixed(1);
  }, [reviews]);

  if (!reviews?.length)
    return (
      <Row className="mt-4">
        <Col xs="12" lg="12">
          <h2>{t('Resenas')}</h2>

          <Jumbotron>
            <p>{t('NoInformacionMostrar')}</p>
          </Jumbotron>
        </Col>
      </Row>
    );

  return (
    <Row className="mt-4">
      <Col xs="12" lg="12">
        <h2>{t('Resenas')}</h2>
        <h1 className="mb-0 d-list-inline">
          {avg} <Rating rating={avg} />
        </h1>

        <span>
          {reviews.length} {t('Resenas')}
        </span>
      </Col>

      <Col xs="12" lg="12">
        <Pagination
          items={reviews}
          itemsPerPage={ITEMS_PER_PAGE}
          page={currentPage}
          onChange={setCurrentPage}
          disabledScroll
        >
          {review => <ReviewCard key={review?.id} review={review} />}
        </Pagination>
      </Col>
    </Row>
  );
};

export default ReviewInformation;
