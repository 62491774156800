import React, { useEffect, useState } from 'react';

import { Image, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import { Link, useRouteMatch } from 'react-router-dom';
import useAppTranslation from '../hook/useAppTranslation';
import { useAuth } from '../providers/AuthProvider';
import styles from './HeaderNav.module.scss';

const AppLink = ({ className, ...props }) => {
  const routeMatch = useRouteMatch({
    path: props?.to,
    exact: props?.exact,
    strict: props?.exact,
  });

  return (
    <Link
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={`${className} ${routeMatch !== null ? 'active' : ''}`}
    />
  );
};

const HeaderNav = () => {
  const { t, changeLanguage, languages, language } = useAppTranslation();
  const { setAuthData } = useAuth();
  const [sticky, setSticky] = useState('');
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 150 ? styles.sticky : '');
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogoutClick = () => {
    setAuthData(null);
    setExpanded(false);
  };

  const handleNavLinkClick = () => {
    setExpanded(false);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="light"
      fixed="top"
      className={`navbar-home ${styles.navbar} ${sticky}`}
      expanded={expanded}
      onToggle={setExpanded}
    >
      <Navbar.Brand as={Link} to="/" onClick={handleNavLinkClick}>
        <Image src="/images/logo.png" className={styles.imageLogo} fluid />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" />

        <Nav>
          <Nav.Link as={AppLink} to="/" exact onClick={handleNavLinkClick}>
            {t('Inicio')}
          </Nav.Link>

          <NavDropdown
            title={t('Reservaciones')}
            id="collasible-nav-dropdown-reservations"
          >
            <NavDropdown.Item
              as={AppLink}
              to="/hotels"
              onClick={handleNavLinkClick}
            >
              {t('Hoteles')}
            </NavDropdown.Item>
            <NavDropdown.Item
              as={AppLink}
              to="/experience"
              onClick={handleNavLinkClick}
            >
              {t('Experiencias')}
            </NavDropdown.Item>
            <NavDropdown.Item
              as={AppLink}
              to="/cruiseShips"
              onClick={handleNavLinkClick}
            >
              {t('Cruceros')}
            </NavDropdown.Item>
            <NavDropdown.Item
              as={AppLink}
              to="/flights"
              onClick={handleNavLinkClick}
            >
              {t('Vuelos')}
            </NavDropdown.Item>
            <NavDropdown.Item
              as={AppLink}
              to="/cars"
              onClick={handleNavLinkClick}
            >
              {t('Cars')}
            </NavDropdown.Item>
            <NavDropdown.Item
              role="button"
              href="https://ike.ticketero.app/"
              target="_blank"
              onClick={handleNavLinkClick}
            >
              {t('EntertainmentTickets')}
            </NavDropdown.Item>
            <NavDropdown.Item
              as={AppLink}
              to="/restaurants"
              onClick={handleNavLinkClick}
            >
              {t('Restaurants')}
            </NavDropdown.Item>
            <NavDropdown.Item
              role="button"
              href="https://sitio-concierge.dev-pruebas.com/es/"
              target="_blank"
              onClick={handleNavLinkClick}
            >
              {t('Iké on demand')}
            </NavDropdown.Item>
          </NavDropdown>

          <Nav.Link
            as={AppLink}
            to="/my-preferences"
            onClick={handleNavLinkClick}
          >
            {t('MyPreferencesTitle')}
          </Nav.Link>

          <NavDropdown title={<FaUser />} id="collasible-nav-dropdown-user">
            <NavDropdown.Item
              as={AppLink}
              to="/my-profile"
              onClick={handleNavLinkClick}
            >
              {t('MyProfile')}
            </NavDropdown.Item>
            <NavDropdown.Item onClick={handleLogoutClick}>
              {t('CerrarSesion')}
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={language} id="collasible-nav-dropdown-languages">
            {languages
              .filter(it => it !== language)
              .map(it => {
                return (
                  <NavDropdown.Item
                    key={it}
                    className="text-uppercase"
                    onClick={() => {
                      changeLanguage(it);
                      handleNavLinkClick();
                    }}
                  >
                    {it}
                  </NavDropdown.Item>
                );
              })}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default HeaderNav;
