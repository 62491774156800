import React, { useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import AdicionalInformation from '../components/AdicionalInformation';
import CarouselTopDetail from '../components/CarouselTopDetail';
import DescripcionDetail from '../components/DescripcionDetail';
import FormExperienceSidebar from '../components/FormExperienceSidebar';
import MapSidebar from '../components/MapSidebar';
import ReviewInformation from '../components/ReviewInformation';
import SectionTop from '../components/SectionTop';
import TourDetail from '../components/TourDetail';
import '../css/HotelDetalle.css';
import useAppTranslation from '../hook/useAppTranslation';
import useQuery from '../hook/useQuery';
import CargandoOpciones from '../loader/CargandoOpciones';
import { TOUR_DETAIL } from '../services/Routes';
import post from '../services/post';
import PriceBottom from '../shared/PriceBottom';

const ExperienceDetalle = () => {
  const history = useHistory();
  const query = useQuery();
  const { t, language } = useAppTranslation();
  const [data, setData] = useState(JSON.parse(query.get('search')));
  const [item, setItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [positions, setPositions] = useState([]);
  const [, setErrorServer] = useState(false);
  const [, setErrorData] = useState(null);
  const [center, setCenter] = useState(null);
  const [position, setPosition] = useState(-1);
  const [items, setItems] = useState([]);

  useEffect(() => {
    data.language = language;
    // console.log(data)
    window.scrollTo(0, 0);
    // console.log(data)
    setErrorServer(false);
    setLoading(true);
    const urlHotelList = `${TOUR_DETAIL}?sources=${process.env.REACT_APP_SUNAPI_API_NAME_TOUR}`;
    post({
      url: urlHotelList,
      data,
    })
      .then(response => {
        // console.log(response);
        if (
          response.data.errors !== null &&
          response.data.error !== undefined &&
          response.data.errors.length > 0
        ) {
          setErrorData(response.data.errors);
          setErrorServer(true);
        } else {
          setItem(response.data);
          const arr = [];
          arr.push(response.data);
          setItems(arr);
          // maps
          if (
            response.data.address != null &&
            response.data.address.geolocation != null
          ) {
            const arrP = [
              {
                id: response.data.id,
                place: response.data.name,
                raiting: response.data.raiting,
                latitude: response.data.address.geolocation.latitude,
                longitude: response.data.address.geolocation.longitude,
              },
            ];
            setPositions(arrP);
            setCenter({
              latitude: response.data.address.geolocation.latitude,
              longitude: response.data.address.geolocation.longitude,
            });
          }
        }
        setLoading(false);
      })
      .catch(() => {
        // console.log(error)
        setErrorServer(true);
        setLoading(false);
      });
  }, [language]); // eslint-disable-line react-hooks/exhaustive-deps

  const sendData = d => {
    // console.log(d)
    const newData = { ...d };
    newData.apiName = data.apiName;
    newData.chain = '';
    newData.id = data.id;
    setData(newData);
    window.scrollTo(0, 0);
    setLoading(true);
    const urlExpDe = `${TOUR_DETAIL}?sources=${process.env.REACT_APP_SUNAPI_API_NAME_HOTEL}`;
    post({
      url: urlExpDe,
      data: newData,
    })
      .then(response => {
        // console.log(response)
        if (
          response.data.errors !== null &&
          response.data.error !== undefined &&
          response.data.errors.length > 0
        ) {
          setErrorData(response.data.errors);
          setErrorServer(true);
        } else {
          setItem(response.data);
          const arr = [];
          arr.push(response.data);
          setItems(arr);
          // maps
          if (
            response.data.address != null &&
            response.data.address.geolocation != null
          ) {
            const arrP = [
              {
                id: response.data.id,
                place: response.data.name,
                raiting: response.data.rating,
                latitude: response.data.address.geolocation.latitude,
                longitude: response.data.address.geolocation.longitude,
              },
            ];
            setPositions(arrP);
            setCenter({
              latitude: response.data.address.geolocation.latitude,
              longitude: response.data.address.geolocation.longitude,
            });
          }
        }
        setLoading(false);
      })
      .catch(() => {
        // console.log(error)
        setErrorServer(true);
        setLoading(false);
      });
  };
  // console.log(data)
  const onHandleClickReservar = elem => {
    // console.log(elem);
    const priceadult = elem.rateDetail.paxAmounts.filter(
      el => el.paxType === 'ADULT',
    );
    const childadult = elem.rateDetail.paxAmounts.filter(
      el => el.paxType === 'CHILD',
    );
    const d = {
      apiName: data.apiName,
      checkIn: data.checkIn,
      adults: data.paxes.adults,
      children: data.paxes.children,
      destino_id: data.destiny,
      destino_name: data.destino_name,
      id: data.id,
      experience_name: item.name,
      adult_price: priceadult.length > 0 ? priceadult[0].amount : 0,
      child_price: childadult.length > 0 ? childadult[0].amount : 0,
      photos: [],
      address: elem.destination,
      currency: data.currency,
    };
    // console.log(d);

    const cacheKey = `${d.id}${d.destino_id}${d.checkIn}${d.adults}${d.children}`;
    const cacheValue = JSON.stringify(d);
    if (window.localStorage.getItem(cacheKey) === null) {
      window.localStorage.setItem(cacheKey, cacheValue);
    }
    history.push(
      `/experience/reservation?search=${encodeURIComponent(cacheKey)}`,
    );
  };

  return (
    <>
      {!loading && (
        <SectionTop
          menu={t('Experiencias')}
          destino={data?.destino_name?.[language] ?? data.destino_name.label}
          nombre={item.name}
          direccion={item.address.addressName}
        />
      )}

      {loading ? (
        <CargandoOpciones hideLooking />
      ) : (
        <Container fluid className="mt-3">
          <Row>
            <Col xs="12" lg="3">
              <Row>
                <Col xs="12" lg="12" className="justify-content-center">
                  <FormExperienceSidebar
                    searching={data}
                    sendData={sendData}
                    showDestiny={false}
                  />
                </Col>
                <Col xs="12" lg="12">
                  <Row className="justify-content-center my-4">
                    <Col xs="12" lg="10">
                      <MapSidebar
                        positionsList={positions}
                        items={items}
                        position={position}
                        setPosition={setPosition}
                        center={center}
                        setCenter={setCenter}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs="12" lg="9">
              <CarouselTopDetail
                photos={item.photos.map(({ urlPhoto }) => urlPhoto)}
              />
              <DescripcionDetail description={item.description} />
              <TourDetail
                rates={item.rateServices}
                data={data}
                onHandleClickReservar={onHandleClickReservar}
              />
              <AdicionalInformation
                additionalInformation={item.additionalInformation}
              />
              <ReviewInformation reviews={item.reviews} />
            </Col>
          </Row>
        </Container>
      )}

      <PriceBottom />
    </>
  );
};

export default ExperienceDetalle;
