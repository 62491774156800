import React, { useEffect, useMemo, useState } from 'react';

import { Col, Container as ContainerBootstrap, Row } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { getImageForDestination } from '../services/roadGoat';
import sleep from '../utils/sleep';

const IMAGE_DEFAULT = '/images/section-top.jpg';

const SectionTop = ({
  menu,
  destino: destination,
  nombre: name,
  total,
  product,
  direccion,
  imageDefault = IMAGE_DEFAULT,
}) => {
  const [imageUrl, setImageUrl] = useState(IMAGE_DEFAULT);

  useEffect(() => {
    let didCancel = false;
    const fetch = async () => {
      await sleep(500);
      if (didCancel) return;

      const image = await getImageForDestination({
        destination: name ?? destination,
      });
      setImageUrl(image ?? imageDefault);
    };

    fetch();
    return () => {
      didCancel = true;
    };
  }, [name, destination, imageDefault]);

  const content = useMemo(() => {
    // If they contain name is the detail
    if (name?.length) {
      return (
        <>
          <label className="text-top-1">{menu}</label>
          <h1>{name}</h1>
          <span className="text-top-1">{direccion}</span>
        </>
      );
    }

    // List
    return (
      <>
        <label className="text-top-1">{menu}</label>
        <h1>{destination}</h1>
        <span className="text-top-3">
          {total} {product}
        </span>
      </>
    );
  }, [destination, name, total, product, direccion, menu]);

  return (
    <Wrapper imageUrl={imageUrl}>
      <Container>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 40vh;
  margin-top: 90px;
  position: relative;

  ${({ imageUrl }) => {
    return css`
      background-image: url(${imageUrl}) !important;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `;
  }}
`;

const textTop1 = css`
  font-size: 1.5rem;
  line-height: 0;
`;

const textTop3 = css`
  background: #003c74;
  padding: 5px;
`;

const Container = styled(ContainerBootstrap)`
  padding-top: 120px;

  &&&& {
    & label,
    & h1,
    & span {
      color: #fff;
    }

    & label.text-top-1,
    & span.text-top-1 {
      ${textTop1}
    }

    & span.text-top-3 {
      ${textTop3}
    }
  }
`;

export default SectionTop;
