import React, { useMemo, useState } from 'react';

import axios from 'axios';
import { Formik } from 'formik';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useAuth } from '../../providers/AuthProvider';
import login from '../../services/login';

const schema = yup.object().shape({
  username: yup
    .string()
    .email('Correo electrónico inválido')
    .required('LoginFieldRequired'),
  password: yup.string().required('LoginFieldRequired'),
  bin: yup
    .number()
    .required('LoginFieldRequired')
    .oneOf([44879067, 45113805], 'LoginBinInvalid'),
});

const initialValues = {
  username: '',
  password: '',
  bin: '',
};

const REGEX_MATCHES = /^.+? must match the following: ".+?"$/;
const REGEX_MIN = /^(.+? must be at least )(\d+)( characters)$/;
const REGEX_MAX = /^(.+? must be at most )(\d+)( characters)$/;
const ErrorFeedback = ({ name, count = 1, touched, error, matches }) => {
  const { t } = useTranslation();

  const msgError = useMemo(() => {
    let msg = error;
    const data = {
      name,
      count,
    };

    if (REGEX_MATCHES.test(msg)) {
      return matches;
    }

    if (REGEX_MIN.test(msg)) {
      const min = msg.match(REGEX_MIN)?.[2];

      msg = 'LoginFieldMin';
      data.min = Number(min);
    } else if (REGEX_MAX.test(msg)) {
      const max = msg.match(REGEX_MAX)?.[2];

      msg = 'LoginFieldMax';
      data.max = Number(max);
    }

    return t(msg, data);
  }, [name, count, error, matches, t]);

  if (!touched || !error) return null;

  return (
    <Form.Control.Feedback type="invalid">{msgError}</Form.Control.Feedback>
  );
};

const LoginForm = () => {
  const { t } = useTranslation();
  const { setAuthData } = useAuth();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitFormik = async ({ username, bin }) => {
    try {
      // setError(null);
      setIsSubmitting(true);

      const resp = await login();
      const dataStorage = {
        access_token: resp?.data?.Bearer,
      };

      const email = 'demo@demo.com';
      const password = 'demodemo';
      const url = `${process.env.REACT_APP_URL_LOGIN}/loginJustGoNow?EMAIL=${email}&PSW=${password}&FMT=JSON&APPNAME=${process.env.REACT_APP_APPNAME_LOGIN}&PUBLICKEY=${process.env.REACT_APP_APPKEY_LOGIN}`;
      const response = await axios({
        method: 'get',
        url,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      if (response.data?.length > 0) {
        const {
          justgo_points: justGoPoints,
          membership,
          name,
        } = response.data[0];

        const authData = {
          ...dataStorage,
          email: username,
          justGoPoints,
          membership,
          name,
          bin,
        };

        // await sleep(10000);
        setAuthData(authData);
        setIsSubmitting(false);
        history.replace('/');
        return;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ ModalLogin.jsx', { err });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmitFormik}
      initialValues={initialValues}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        touched,
        errors,
        submitCount,
        isValid,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Card>
              <Card.Body>
                <Container>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="username">
                        <Form.Label>{t('CorreoElectronico')}</Form.Label>
                        <Form.Control
                          placeholder={t('CorreoElectronico')}
                          type="email"
                          name="username"
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.username && !errors.username}
                          isInvalid={touched.username && !!errors.username}
                        />

                        <ErrorFeedback
                          name={t('CorreoElectronico')}
                          count={1}
                          touched={touched.username}
                          error={errors.username}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="password">
                        <Form.Label>{t('LoginPassword')}</Form.Label>
                        <Form.Control
                          placeholder={t('LoginPassword')}
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.password && !errors.password}
                          isInvalid={touched.password && !!errors.password}
                        />

                        <ErrorFeedback
                          name={t('LoginPassword')}
                          count={1}
                          touched={touched.password}
                          error={errors.password}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="bin">
                        <Form.Label>{t('LoginBIN')}</Form.Label>
                        <Form.Control
                          placeholder={t('LoginBIN')}
                          type="number"
                          name="bin"
                          value={values.bin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.bin && !errors.bin}
                          isInvalid={touched.bin && !!errors.bin}
                        />

                        <ErrorFeedback
                          name={t('LoginBIN')}
                          count={1}
                          touched={touched.bin}
                          error={errors.bin}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>

              <Card.Footer>
                <Button
                  type="submit"
                  disabled={isSubmitting || (submitCount > 0 && !isValid)}
                  variant="outline-primary"
                  block
                  className="text-uppercase"
                >
                  {isSubmitting ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    t('LoginStart')
                  )}
                </Button>
              </Card.Footer>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
