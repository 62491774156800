import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import './moment';
import formatDuration from './utils/formatDuration';

const NS = {
  common: 'translation',
  priceGuarantee: 'priceGuarantee',
  groupTravelRequests: 'groupTravelRequests',
  errors: 'errors',
  welcome: 'welcome',
  dashboard: 'dashboard',
  myProfile: 'myProfile',
  myPreferences: 'myPreferences',
  vuelos: 'flights',
  condominios: 'marketplaces',
  hoteles: 'hotels',
  eventosDeportivos: 'sportingEvents',
  restaurants: 'restaurants',
  cars: 'cars',
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // use interval
  // learn more: https://github.com/i18next/i18next-intervalplural-postprocessor
  .use(intervalPlural)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .on('languageChanged', lng => {
    moment.locale(lng);
  })
  .init({
    ns: Object.keys(NS),
    defaultNs: 'common',
    fallbackNS: Object.keys(NS),
    backend: {
      loadPath: (_, [key]) => {
        if (NS[key]?.length) return `/locales/{{lng}}/${NS[key]}.json`;

        return '/locales/{{lng}}/{{ns}}.json';
      },
    },
    load: 'languageOnly',
    lng: navigator.language.substring(0, 2) === 'es' ? 'es' : 'en',
    fallbackLng: ['en', 'es'],
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default

      format: (value, format, lng) => {
        if (format === 'lowercase') {
          // console.log('🚀 ~ i18n.js', { value, format, lng });

          return value?.toLocaleLowerCase();
        }

        if (format === 'time24hours') {
          // console.log('🚀 ~ i18n.js ~ format', { value, format, lng });
          const time = value ? moment(value, 'HHmm') : null;
          return time?.isValid() ? time.format('HH:mm') : '-';
        }

        if (format === 'longDate') {
          const date = moment(value, 'DDMMYYYY');
          return date?.isValid() ? date.format('ddd, DD MMM YYYY') : '-';
        }

        return formatDuration(value, format, lng);
      },
    },

    react: {
      useSuspense: false,
    },
  });

export default i18n;
