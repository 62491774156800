import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InputForm from '../shared/InputForm';
import { PAYMENT_TYPE } from '../validations/payment';

const FormPayment = ({ hideFeedback }) => {
  const { t } = useTranslation();
  const formikBag = useFormikContext();

  const cardType = useMemo(
    () => formikBag?.values?.payment?.paymentType,
    [formikBag?.values?.payment?.paymentType],
  );

  return (
    <>
      <Row xs="1">
        <Col lg="6">
          <InputForm
            name="payment.name"
            label={t('PaymentNameOnCard')}
            hideFeedback={hideFeedback}
          />
        </Col>

        <Col lg="4">
          <InputForm
            as="select"
            name="payment.paymentType"
            label={t('PaymentCardType')}
            hideFeedback={hideFeedback}
          >
            {Object.entries(PAYMENT_TYPE).map(([value, key]) => (
              <option key={value} value={value}>
                {t(key)}
              </option>
            ))}
          </InputForm>
        </Col>
      </Row>

      <Row xs="1">
        <Col lg="6">
          <InputForm
            type="password"
            name="payment.creditCard.number"
            label={t(
              cardType === 'CREDIT_CARD'
                ? 'PaymentCreditCardNumber'
                : 'PaymentDebitCardNumber',
            )}
            onChange={(evt, { field }) => {
              // eslint-disable-next-line no-param-reassign
              evt.target.value = evt.target.value
                .replace(/[^\d]/g, '')
                .substring(0, 16);

              field.onChange(evt);
            }}
            hideFeedback={hideFeedback}
          />
        </Col>

        <Col lg="2">
          <InputForm
            as="select"
            name="payment.creditCard.expireMonth"
            label={t('Month')}
            hideFeedback={hideFeedback}
          >
            {moment.months().map((nameMonth, index) => {
              return (
                <option key={index} value={index + 1}>
                  {nameMonth}
                </option>
              );
            })}
          </InputForm>
        </Col>

        <Col lg="2">
          <InputForm
            as="select"
            name="payment.creditCard.expireYear"
            label={t('Year')}
            hideFeedback={hideFeedback}
          >
            {Array.from({ length: 10 })
              .fill(moment().year())
              .map((current, index) => {
                const value = current + index;
                return (
                  <option key={value} value={value}>
                    {value}
                  </option>
                );
              })}
          </InputForm>
        </Col>

        <Col lg="2">
          <InputForm
            type="password"
            name="payment.creditCard.cvv"
            label={t('PaymentCardSecureCode')}
            onChange={(evt, { field }) => {
              // eslint-disable-next-line no-param-reassign
              evt.target.value = evt.target.value
                .replace(/[^\d]/g, '')
                .substring(0, 3);

              field.onChange(evt);
            }}
            hideFeedback={hideFeedback}
          />
        </Col>
      </Row>
    </>
  );
};

export default FormPayment;
