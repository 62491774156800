import React from 'react';

import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useAppParams from '../../../hook/useAppParams';
import { encode } from '../../../utils/uriComponent';
import PriceFormat from '../../PriceFormat';
import styles from './CardFlightResume.module.css';
import CardItinerary from './CardItinerary';

const CardFlightResume = ({ flight, onCancelClick }) => {
  const { t } = useTranslation();
  const { query } = useAppParams();
  const history = useHistory();

  const handleCheckoutClick = () => {
    const newQuery = encode(query);
    const checkout = encode(flight);

    history.push(`/flights/checkout/${newQuery}/${checkout}`);
  };

  const handleCancelClick = () => {
    if (onCancelClick) onCancelClick();
  };

  return (
    <Card className={`${styles.card}`}>
      <Card.Body className="p-0">
        <Panes xs="1" className="m-0">
          <Col lg="9" className={`${styles.cardPanel} m-0 p-0`}>
            <CardItinerary isDeparture itinerary={flight.departing} />
            <CardItinerary itinerary={flight.returning} />
          </Col>

          <Col
            lg="3"
            className={`${styles.cardPanel} m-0 p-0 d-flex flex-column align-items-end py-3`}
            style={{ justifyContent: 'space-evenly' }}
          >
            <Row xs="1" className="m-0 p-0">
              <Col>
                <h2 className="font-weight-bold text-right">
                  {t('PrecioBase')}
                </h2>
              </Col>

              <Col>
                <PriceFormat
                  currency={flight.price.currency}
                  value={flight.price.base}
                >
                  {valueText => (
                    <h2
                      className={`${styles.priceBase} font-weight-bold text-right`}
                    >
                      {valueText}
                    </h2>
                  )}
                </PriceFormat>
              </Col>
            </Row>

            <Row xs="1" className="m-0 p-0">
              <Col>
                <h2 className="font-weight-bold text-right">{t('Total')}</h2>
              </Col>

              <Col>
                <PriceFormat
                  currency={flight.price.currency}
                  value={flight.price.total}
                >
                  {valueText => (
                    <h2
                      className={`${styles.total} font-weight-bold text-right`}
                    >
                      {valueText}
                    </h2>
                  )}
                </PriceFormat>
              </Col>

              <Col>
                <h5 className="text-right">
                  {t(!flight.returning ? 'ViajeSencillo' : 'ViajeRedondo')}
                </h5>
              </Col>
            </Row>

            <Row xs="1" className="m-0 p-0 w-100">
              <Col>
                <Button
                  block
                  variant="outline-primary font-weight-bold"
                  className={`${styles.btn} mb-2`}
                  onClick={handleCheckoutClick}
                >
                  {t('Continuar')}
                </Button>
              </Col>

              <Col>
                <Button
                  block
                  variant="danger font-weight-bold"
                  className={styles.btn}
                  onClick={handleCancelClick}
                >
                  {t('CambiarVuelo')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Panes>
      </Card.Body>
    </Card>
  );
};

const Panes = styled(Row)`
  & > div {
    &:first-child {
      border-bottom-width: 2.5px !important;

      @media (min-width: 992px) {
        border-bottom-width: 0px !important;
        border-right-width: 2.5px !important;
      }

      & > div:first-child > div:first-child {
        border-radius: 0.25rem 0 0 0;
        overflow: hidden;
      }

      & > div:last-child > div:first-child {
        border-radius: 0 0 0 0.25rem;
        overflow: hidden;
      }
    }

    &:last-child {
      border-top-width: 2.5px !important;

      @media (min-width: 992px) {
        border-top-width: 0px !important;
        border-left-width: 2.5px !important;
      }
    }
  }
`;

export default CardFlightResume;
