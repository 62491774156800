import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const DATE_FORMAT = {
  es: 'dd/MM/yyyy',
  en: 'MM/dd/yyyy',
};

const DATE_TIME_FORMAT = {
  es: `${DATE_FORMAT.es} HH:mm`,
  en: `${DATE_FORMAT.en} HH:mm`,
};

const useAppTranslation = () => {
  const translation = useTranslation();

  const ready = useMemo(() => translation?.ready, [translation?.ready]);

  const t = useMemo(() => {
    if (!ready) return () => 'Loading...';

    return translation.t;
  }, [ready, translation.t]);

  const changeLanguage = useMemo(() => {
    return translation.i18n.changeLanguage;
  }, [translation.i18n.changeLanguage]);

  const language = useMemo(
    () => translation.i18n.language,
    [translation.i18n.language],
  );

  const languages = useMemo(
    () => translation?.i18n?.languages?.sort?.() ?? [],
    [translation.i18n.languages],
  );

  const dateFormat = useMemo(() => {
    return DATE_FORMAT[language] ?? null;
  }, [language]);

  const dateTimeFormat = useMemo(() => {
    return DATE_TIME_FORMAT[language] ?? null;
  }, [language]);

  return {
    ready,
    t,
    changeLanguage,
    languages,
    language,
    dateFormat,
    dateTimeFormat,
  };
};

export default useAppTranslation;
