import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useCruise from '../../hook/useCruise';
import CargandoOpciones from '../../loader/CargandoOpciones';
import FormSidebar from '../../shared/FormSidebar';
import CabinsDetail from '../CabinsDetail';
import CarouselTopDetail from '../CarouselTopDetail';
import DescripcionDetail from '../DescripcionDetail';
import ErrorServer from '../ErrorServer';
import MapSidebar from '../MapSidebar';
import SectionTop from '../SectionTop';
import EmptyData from './EmptyData';
import FormSearch from './FormSearch';

const Detail = () => {
  const { query, makeBook, getDetails } = useCruise();
  const { t, i18n } = useTranslation();
  const [cruises, setCruises] = useState({});
  const [showCurrency, setShowCurrency] = useState(false);
  const handleCloseCurrency = () => setShowCurrency(false);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [text] = useState(null);
  const [position, setPosition] = useState({});
  const [center, setCenter] = useState([]);
  const [errorServer, setErrorServer] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [errorStatus] = useState(null);

  const assignPrice = (cat, price) => {
    const newCat = [];
    let e = 0;
    for (let i = 0; i < cat.length; i++) {
      if (price[e] !== undefined) {
        if (cat[i].text === price[e].text) {
          const newPrice = cat[i].price !== '' ? cat[i].price : price[e].price;
          const newDescription = cat[i].children.map(oldChildren => {
            // eslint-disable-next-line no-param-reassign
            oldChildren.price = newPrice;
            return oldChildren;
          });

          newCat.push({
            id: cat[i].id,
            img: cat[i].img,
            price: newPrice,
            text: cat[i].text,
            description: newDescription,
          });
          e++;
        }
      }
    }
    return newCat;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const source = axios.CancelToken.source();
    setLoading(false);
    setErrorServer(false);
    setItem(null);

    const fetch = async () => {
      if (query) {
        try {
          // console.log('🚀 ~ Detail.js ~ Init');
          setLoading(true);
          const { masterCruiseID } = query;
          const dataCruise = await getDetails(masterCruiseID);

          // console.log('🚀 ~ Detail.js', { dataCruise });

          const dataC = {
            masterCruiseId: dataCruise.masterCruiseId,
            title: dataCruise.title,
            arrival: dataCruise.arrival,
            departure: dataCruise.departure,
            duration: dataCruise.duration,
            excluded: dataCruise.excluded,
            included: dataCruise.included,
            route: dataCruise.route,
            description: dataCruise.ship.description,
            images: dataCruise.ship.images,
            features: dataCruise.ship.features,
            categories: assignPrice(
              dataCruise.ship.categories,
              dataCruise.priceCat,
            ),
          };

          // console.log('🚀 ~ Detail.js', { dataC });

          setCruises(dataC.categories);

          if (query.count >= 1) {
            const data = dataC;

            setItem(data);

            if (data.route?.points[0]) {
              setPosition([
                {
                  id: data.masterCruiseId,
                  place: data.title,
                  raiting: data.stars,
                  latitude: data.route.points[0].latitude || null,
                  longitude: data.route.points[0].longitude || null,
                },
              ]);

              setCenter({
                latitude: data.route.points[0].latitude || null,
                longitude: data.route.points[0].longitude || null,
              });
            }
          } else {
            const data = dataCruise;
            setItem(data);

            if (data.route?.points[0]) {
              setPosition([
                {
                  id: data.masterCruiseId,
                  place: data.title,
                  raiting: data.stars,
                  latitude: data.route.points[0].latitude || null,
                  longitude: data.route.points[0].longitude || null,
                },
              ]);

              setCenter({
                latitude: data.route.points[0].latitude || null,
                longitude: data.route.points[0].longitude || null,
              });
            }
          }
        } catch (err) {
          // console.log('🚀 ~ Detail.js', { err });

          setItem(null);
          setErrorData(Array.isArray(err) ? err : [err]);
          setErrorServer(true);
        } finally {
          setLoading(false);
        }
      }
    };

    fetch();

    return () => {
      // console.log('🚀 ~ Detail.js ~ Cancel');
      source.cancel();
    };
  }, [query, i18n]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBookClick = rate => makeBook(item, rate);

  if (loading) return <CargandoOpciones hideLooking />;

  return (
    <>
      <Modal show={showCurrency} onHide={handleCloseCurrency} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('itinerario')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>{t('Nombre')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{text}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <SectionTop
        menu={t('Cruceros')}
        destino={query?.cruiseArea}
        nombre={item?.title}
        direccion={item?.cruiseLine || ''}
      />

      {!item ? (
        <EmptyData />
      ) : (
        <Container fluid className="my-3">
          <Row>
            <Col xs="12" lg="3">
              <Row>
                <Col xs="12" lg="12">
                  <FormSidebar>
                    <FormSearch inline showDestiny={false} />
                  </FormSidebar>
                </Col>
                <Col xs="12" lg="12">
                  <Row className="justify-content-center my-4">
                    <Col xs="12" lg="10">
                      {item && (
                        <MapSidebar
                          items={[item]}
                          position={position}
                          setPosition={setPosition}
                          positionsList={[position]}
                          center={center}
                          setCenter={setCenter}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col xs="12" lg="9">
              {errorServer ? (
                <ErrorServer errorData={errorData} errorStatus={errorStatus} />
              ) : (
                <>
                  <CarouselTopDetail
                    photos={item.images.gallery.map(({ url }) =>
                      url.replace(/^\/\//gi, 'https://'),
                    )}
                    numberCards={5}
                    imgHeight={260}
                  />
                  <DescripcionDetail description={item.description} />
                  {/*  <Col xs="12" className="px-0 mx-0">
                    <h1>
                      <Cabins>
                        <Text
                          size="30"
                          lineHeight="50"
                          color="#FFFFFF"
                          className="cursor-pointer"
                          onClick={handleClick}
                        >
                          Escoge el tipo de cabina
                        </Text>
                      </Cabins>
                    </h1>
                  </Col> */}
                  <CabinsDetail
                    cabinas={cruises}
                    onHandleClickReservar={handleBookClick}
                  />
                  <Row>
                    <Col>
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: item.included }}
                      />
                    </Col>
                    <Col>
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: item.excluded }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Detail;
