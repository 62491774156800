import React, { useState } from 'react';

import moment from 'moment';
import { Alert, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import BookInfo from '../../components/Hotels/BookInfo';
import ReservaCardTop from '../../components/ReservaCardTop';
import ReservaCardTopWhite from '../../components/ReservaCardTopWhite';
import SectionTop from '../../components/SectionTop';
import '../../css/HotelReserva.css';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import LoaderReservar from '../../loader/LoaderReservar';
import jointStr from '../../utils/jointStr';
import sleep from '../../utils/sleep';
import { encode } from '../../utils/uriComponent';

const Booking = () => {
  const { t } = useAppTranslation();
  const { rate } = useAppParams();
  const history = useHistory();

  const [sendingBooking, setSendingBooking] = useState(false);
  const [infoReserva] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleBookingClick = async values => {
    try {
      setSendingBooking(true);

      const { payment } = values;
      const totalPaxes = {
        adults: 0,
        children: 0,
        infant: 0,
      };

      const rooms = values.rooms.map((room, roomIndex) => {
        const paxes = {
          adults: 0,
          children: 0,
          infant: 0,
        };

        const paxesInformation = room.map((guest, guestIndex) => {
          if (guest.type === 'ADULT') paxes.adults += 1;
          else paxes.children += 1;

          return {
            name: guest.name,
            lastName: guest.lastName,
            type: guest.type,
            passportNumber: '765432987',
            passportExpiration: '12-11-2026',
            nationality: 'MEX',
            dateOfBirth: '06-05-1983',
            gender: 'M',
            age: 38,
            owner: values.isMainGuest && roomIndex === 0 && guestIndex === 0,
            checked: false,
          };
        });

        totalPaxes.adults += paxes.adults;
        totalPaxes.children += paxes.children;
        totalPaxes.infant += paxes.infant;

        return {
          id: rate.roomId,
          roomType: 'RO',
          planId: '',
          price: rate.total,
          paxes,
          paxesInformation,
        };
      });

      const booking = {
        currency: 'USD',
        total: rate.total,
        reference: 'CMOM',
        company: 'JustGo',
        client: {
          name: jointStr({ arr: [values.name, values.middleName] }),
          lastname: values.lastName,
          email: values.email,
          country: 'OT',
          phone: {
            number: values.phone,
            type: 'HOME',
          },
          hotel: {
            id: 2,
            name: rate.hotelName,
          },
        },
        hotels: [
          {
            id: rate.hotelId,
            source: rate.apiName,
            name: rate.hotelName,
            checkin: moment(rate.checkIn).format('DD-MM-YYYY'),
            checkout: moment(rate.checkOut).format('DD-MM-YYYY'),
            destination: rate.destino_id.value,
            rooms,
            paxes: totalPaxes,
          },
        ],
        payment: {
          currency: 'USD',
          source: rate.apiName,
          amount: rate.total,
          paymentType: payment.paymentType,
          creditCard: {
            number: payment.creditCard.number,
            expireMonth: `${payment.creditCard.expireMonth}`.padStart(2, '0'),
            expireYear: payment.creditCard.expireYear,
            type: {
              name: 'VI',
            },
          },
        },
      };

      // const htmlBody = handleEmailConfirmation(t, booking, checkout);
      // const paramsEmail = {
      //   content: htmlBody,
      //   from: 'demo@gmail.com',
      //   subject: 'CONFIRMACION',
      //   to: booking.client.email,
      // };
      // sendMail(paramsEmail);

      // const data = await makeBooking(booking);
      await sleep(5000);
      setSendingBooking(false);

      // if (data === null) {
      //   setShow(true);
      //   return;
      // }

      // setInfoReserva(booking);
      // addPoints({
      //   membership: auth.data.membership,
      //   describe: rate.hotelName,
      //   points: rate.total,
      // });

      const encodedBooking = encode({ infoReserva: booking, checkout: rate });
      history.push(`/hotels/booking/voucher/${encodedBooking}`);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ Booking.js', { err });
    }
  };

  if (!rate) return <div />;

  return (
    <>
      {sendingBooking && <LoaderReservar />}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Row>
            <Col xs="12">
              <h6>{t('LoSentimos')}!!!</h6>
              <label>{t('NoEfectuarReservación')}</label>
            </Col>
          </Row>
          {infoReserva && infoReserva.httpStatusCode && (
            <Row>
              <Col xs="12">
                <Alert variant="outline-primary">
                  {`${infoReserva.httpStatusCode}`}
                  <br />
                  {infoReserva.message}
                </Alert>
              </Col>
            </Row>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose}>
            {t('Aceptar')}
          </Button>
        </Modal.Footer>
      </Modal>

      <SectionTop
        menu={t('Hotel')}
        destino={rate.destino_id.label}
        nombre={t('DatosReserva')}
        direccion={rate.hotelName}
      />

      <Container fluid className="mb-3">
        <Row>
          <Col xs="12" lg="3" className="mt-3">
            <Row className="justify-content-center">
              <Col xs="12" className="mb-3">
                <ReservaCardTop rate={rate} />
              </Col>

              <Col xs="12">
                <ReservaCardTopWhite item={rate} />
              </Col>
            </Row>
          </Col>

          <Col xs="12" lg="9" className="mt-3">
            <BookInfo
              onBookingClick={handleBookingClick}
              rate={rate}
              filters={{
                rooms: rate.rooms.map(it => {
                  const acc = {};
                  return Array.from({ length: it.guest.adults })
                    .fill({ type: 'ADULT' })
                    .concat(
                      it.guest.childrenAges.map(age => ({
                        type: 'CHILD',
                        age,
                      })),
                    )
                    .map(({ type, age }) => {
                      acc[type] = acc[type] ?? 1;

                      return {
                        num: acc[type]++,
                        type,
                        name: '',
                        lastName: '',
                        age,
                      };
                    })
                    .sort(({ type: a }, { type: b }) => {
                      if (a === 'ADULT' && b !== 'ADULT') return -1;
                      if (b === 'ADULT' && a !== 'ADULT') return 1;

                      return 0;
                    });
                }),
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Booking;
