import React, { useEffect, useState } from 'react';

import sleep from '../utils/sleep';
import styles from './MainContainer.module.scss';

const MainContainer = ({ children }) => {
  const [navHeight, setNavHeight] = useState(0);
  const [marginTop, setMarginTop] = useState(0);

  useEffect(() => {
    const nav = window.document.querySelector('nav.navbar-home');

    if (nav) {
      const handleResize = () => {
        setNavHeight(nav.clientHeight);
      };

      handleResize();
      new ResizeObserver(handleResize).observe(nav);
    }
  }, []);

  useEffect(() => {
    let didCancel = false;
    const set = async () => {
      await sleep(50);
      if (didCancel) return;

      setMarginTop(navHeight + 16);
    };

    set();
    return () => {
      didCancel = true;
    };
  }, [navHeight]);

  return (
    <div style={{ marginTop: `${marginTop}px` }} className={styles.container}>
      {children}
    </div>
  );
};

export default MainContainer;
