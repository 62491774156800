/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

import { Formik } from 'formik';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useCruise from '../../hook/useCruise';
import AsyncSelect from '../../shared/AsyncSelect';
import Select from '../../shared/Select';
import DatePicker from '../DatePicker';

const BY = { HOTEL: '0', DESTINATION: '1' };
const FormGroup = ({ xs, lg, className, showLabel, label, children }) => {
  return (
    <Col xs={xs} lg={lg} className={className}>
      {showLabel ? (
        <Form.Group>
          <Form.Label className="text-light">{label}</Form.Label>

          {children}
        </Form.Group>
      ) : (
        children
      )}
    </Col>
  );
};

const FormSearch = ({ inline = false, showDestiny = true }) => {
  const { t } = useTranslation();
  const { search, getAreas, getLines, getLength, getShips } = useCruise();
  const [Lineas, setLineas] = useState([]);
  const [Length, setLength] = useState([]);
  const [Ships, setShips] = useState([]);

  const valuesLine = async area => {
    const lines = await getLines(area);
    setLineas(lines);
  };
  const valuesLength = async (area, linea) => {
    const length = await getLength(area, linea);
    setLength(length);
  };
  const valuesShip = async (area, linea) => {
    const ship = await getShips(area, linea);
    setShips(ship);
  };
  return (
    <Formik
      initialValues={{
        by: BY.DESTINATION,
        cruisearea: '',
        cruiselength: '',
        cruiseline: '',
        ship: '',
        earl: '',
        latest: '',
      }}
      /* 
      validationSchema={Yup.object().shape({
        by: Yup.string().oneOf(Object.values(BY)),
        cruisearea: Yup.object()
          .when('by', {
            is: BY.DESTINATION,
            then: schema =>
              schema.shape({
                value: Yup.number().required(),
                label: Yup.string().required(),
              }),
            otherwise: schema =>
              schema.shape({
                value: Yup.number().required(),
                label: Yup.string().required(),
              }),
          })
          .nullable()
          .required('CampoRequerido'),
        cruiseline: Yup.object()
          .when('by', {
            is: BY.DESTINATION,
            then: schema =>
              schema.shape({
                value: Yup.number().required(),
                label: Yup.string().required(),
              }),
            otherwise: schema =>
              schema.shape({
                value: Yup.number().required(),
                label: Yup.string().required(),
              }),
          })
          .nullable()
          .required('CampoRequerido'),
        cruiselength: Yup.object()
          .when('by', {
            is: BY.DESTINATION,
            then: schema =>
              schema.shape({
                value: Yup.number().required(),
                label: Yup.string().required(),
              }),
            otherwise: schema =>
              schema.shape({
                value: Yup.number().required(),
                label: Yup.string().required(),
              }),
          })
          .nullable()
          .required('CampoRequerido'),
        ship: Yup.object()
          .when('by', {
            is: BY.DESTINATION,
            then: schema =>
              schema.shape({
                value: Yup.number().required(),
                label: Yup.string().required(),
              }),
            otherwise: schema =>
              schema.shape({
                value: Yup.number().required(),
                label: Yup.string().required(),
              }),
          })
          .nullable()
          .required('CampoRequerido'),
      })}   */
      onSubmit={async values => {
        setTimeout(500);
        //  alert(JSON.stringify(values, null, 2));
        search(values, !!showDestiny);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setTouched,
        handleSubmit,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Container className="form-padding">
              {/* <pre>{JSON.stringify({ values, errors, touched }, null, 2)}</pre> 
                console.log('V A L U E S', values) */}
              <Row>
                <FormGroup
                  label={t('CruiseAreas')}
                  xs="12"
                  lg={inline ? '12' : '4'}
                  showLabel={!inline}
                  className={inline && 'mb-2'}
                >
                  <AsyncSelect
                    groupHeading
                    defaultOptions
                    defaultValue={values.cruisearea}
                    isMulti={false}
                    loadOptions={async () => {
                      return getAreas();
                    }}
                    onChange={value => {
                      setFieldValue('cruisearea', value);
                      valuesLine(value.value);
                    }}
                    onBlur={() => {
                      setTouched({ ...touched, cruisearea: true });
                    }}
                    isValid={touched.cruisearea && errors.cruisearea}
                  />

                  {touched.cruisearea && errors.cruisearea && (
                    <Form.Control.Feedback type="invalid">
                      {t(errors.cruisearea.label)}
                    </Form.Control.Feedback>
                  )}
                </FormGroup>

                <FormGroup
                  id="cruseline"
                  label={t('CruiseLine')}
                  name="cruiseLine"
                  xs="12"
                  lg={inline ? '12' : '4'}
                  showLabel={!inline}
                  className={inline && 'mb-2'}
                >
                  {values.cruisearea.value !== '' ? (
                    <Select
                      options={Lineas}
                      className={inline ? '12' : '4'}
                      placeholder={t('CruiseLine')}
                      onChange={value => {
                        setFieldValue('cruiseline', value);
                        valuesLength(values.cruisearea.value, value.value);
                      }}
                    />
                  ) : (
                    <Select
                      isDisabled
                      options={Lineas}
                      className={inline ? '12' : '4'}
                      placeholder={t('CruiseLine')}
                      onChange={value => {
                        setFieldValue('cruiseline', value);
                        valuesLength(values.cruisearea.value, value.value);
                      }}
                    />
                  )}
                  {touched.cruiseline && errors.cruiseline && (
                    <Form.Control.Feedback type="invalid">
                      {t(errors.cruiseline.label)}
                    </Form.Control.Feedback>
                  )}
                </FormGroup>

                <FormGroup
                  label={t('CruiseLength')}
                  name="cruiseLength"
                  xs="12"
                  lg={inline ? '12' : '4'}
                  showLabel={!inline}
                  className={inline && 'mb-2'}
                >
                  <Select
                    options={Length}
                    className={inline ? '12' : '4'}
                    placeholder={t('CruiseLength')}
                    onChange={value => {
                      setFieldValue('cruiselength', value);
                      valuesShip(
                        values.cruisearea.value,
                        values.cruiseline.value,
                      );
                    }}
                  />
                  {touched.cruiselength && errors.cruiselength && (
                    <Form.Control.Feedback type="invalid">
                      {t(errors.cruiselength.label)}
                    </Form.Control.Feedback>
                  )}
                </FormGroup>
              </Row>
              <Row>
                <FormGroup
                  label={t('Ship')}
                  name="ships"
                  xs="12"
                  lg={inline ? '12' : '4'}
                  showLabel={!inline}
                  className={inline && 'mb-2'}
                >
                  <Select
                    options={Ships}
                    className={inline ? '12' : '4'}
                    placeholder={t('Ship')}
                    onChange={value => {
                      setFieldValue('ship', value);
                    }}
                  />
                  {touched.ship && errors.ship && (
                    <Form.Control.Feedback type="invalid">
                      {t(errors.ship.label)}
                    </Form.Control.Feedback>
                  )}
                </FormGroup>

                <FormGroup
                  label={t('Salida')}
                  name="earliest"
                  xs={inline ? '12' : '4'}
                  lg={inline ? '12' : '4'}
                  showLabel={!inline}
                  className={inline && 'mb-2'}
                >
                  <DatePicker
                    name="earliest"
                    id="earliest"
                    date={values.earliest}
                    handleChange={value => {
                      setFieldValue('earl', value);
                    }}
                  />
                  {/*      <DatePicker
                    onChange={date}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                  /> */}

                  {touched.earliest && errors.earliest && (
                    <Form.Control.Feedback type="invalid">
                      {t(errors.earliest)}
                    </Form.Control.Feedback>
                  )}
                </FormGroup>

                <FormGroup
                  label={t('Regreso')}
                  xs={inline ? '12' : '4'}
                  lg={inline ? '12' : '4'}
                  showLabel={!inline}
                  className={inline && 'mb-2'}
                >
                  <DatePicker
                    name="latest"
                    id="latest"
                    date={values.earliest}
                    minDate={values.latest}
                    handleChange={value => {
                      setFieldValue('latest', value);
                    }}
                  />

                  {touched.latest && errors.latest && (
                    <Form.Control.Feedback type="invalid">
                      {t(errors.latest)}
                    </Form.Control.Feedback>
                  )}
                </FormGroup>
              </Row>

              <Row>
                <Col xs="12" lg={inline ? 12 : { span: 2, offset: 10 }}>
                  <Button
                    type="submit"
                    block
                    variant="outline-primary"
                    className="font-weight-bold"
                  >
                    {t('Buscar')}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormSearch;
