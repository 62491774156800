import React from 'react';

import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import useHotel from '../../hook/useHotel';

const FormRedemption = ({ onSubmit }) => {
  const { query } = useHotel();
  return (
    <Formik
      initialValues={
        query || {
          resort: null,
          unitType: null,
          weekNumber: null,
          year: null,
        }
      }
      // validationSchema={Yup.object().shape({
      //   resort: Yup.string().required(),
      //   unitType: Yup.string().required(),
      //   weekNumber: Yup.string().required(),
      //   year: Yup.string().required(),
      // })}
      onSubmit={async values => {
        // console.log('🚀 ~ FormRedemption.js', { values });
        onSubmit(values);
      }}
    >
      {({ handleSubmit }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-3 ">
              <Form.Group controlId="formName">
                <Form.Label>First Name:</Form.Label>
                <Form.Control type="text" name="name" placeholder="Full Name" />
              </Form.Group>
              <Form.Group controlId="formName">
                <Form.Label>Last Name:</Form.Label>
                <Form.Control type="text" name="name" placeholder="Full Name" />
              </Form.Group>
              <Form.Group controlId="formName">
                <Form.Label>Co-Owner&apos;s First Name:</Form.Label>
                <Form.Control type="text" name="name" placeholder="Full Name" />
              </Form.Group>
              <Form.Group controlId="formName">
                <Form.Label>Co-Owner&apos;s Last Name:</Form.Label>
                <Form.Control type="text" name="name" placeholder="Full Name" />
              </Form.Group>
              <Form.Group controlId="formName">
                <Form.Label>Email:</Form.Label>
                <Form.Control type="text" name="name" placeholder="Full Name" />
              </Form.Group>
              <Form.Group controlId="formName">
                <Form.Label>Phone:</Form.Label>
                <Form.Control type="text" name="name" placeholder="Full Name" />
              </Form.Group>
              <Form.Group controlId="formName">
                <Form.Label>Secondary Phone:</Form.Label>
                <Form.Control type="text" name="name" placeholder="Full Name" />
              </Form.Group>
              <Form.Group controlId="formName">
                <Form.Label>Address:</Form.Label>
                <Form.Control type="text" name="name" placeholder="Full Name" />
              </Form.Group>
              <Form.Group controlId="formName">
                <Form.Label>City:</Form.Label>
                <Form.Control type="text" name="name" placeholder="Full Name" />
              </Form.Group>
              <Form.Group controlId="formName">
                <Form.Label>State:</Form.Label>
                <Form.Control type="text" name="name" placeholder="Full Name" />
              </Form.Group>
              <Form.Group controlId="formName">
                <Form.Label>ZIP/ Postal Code:</Form.Label>
                <Form.Control type="text" name="name" placeholder="Full Name" />
              </Form.Group>
              {/* <Select
                options={optionsDestiny}
                className="w-80"
                placeholder="Destino"
              /> */}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormRedemption;
