import React, { useMemo } from 'react';

import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

const withFormikSearch =
  ({ schema, onSubmit }, Component) =>
  props => {
    const { filters } = props;
    const history = useHistory();

    const defaultValues = useMemo(() => schema?.getDefault?.() ?? {}, []);

    const initialValues = useMemo(() => {
      return { ...defaultValues, ...(filters ?? {}) };
    }, [defaultValues, filters]);

    const handleSubmit = (values, actions) => {
      if (typeof onSubmit === 'function') {
        onSubmit({ values, actions, history }, props);
      }
    };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {formikBag => {
          return (
            <form noValidate onSubmit={formikBag.handleSubmit}>
              <Component {...props} formikBag={formikBag} />
            </form>
          );
        }}
      </Formik>
    );
  };

export default withFormikSearch;
