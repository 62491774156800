import React from 'react';
import moment from 'moment';
import { Card, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { FaCalendarAlt, FaUsers } from 'react-icons/fa';
import formatNumber from '../../utils/formatNumber';

const CondosReservaDatosMonetarios = ({ t, condoInfo }) => {
  const { rates: room } = condoInfo;
  const fecha1 = moment(room?.effectiveDate, 'DD-MM-YYYY');
  const fecha2 = moment(room?.expireDate, 'DD-MM-YYYY');
  const duration = moment.duration(fecha2.diff(fecha1)).asDays();

  return (
    <Card className="datos-monetarios-container bg-secondary-color container-info">
      <div className="section-title"> {t('YourReservationDetail')} </div>
      <Row>
        <Col>
          <label className="d-block mb-0">
            <FaCalendarAlt className="icon-14 mb-1 mr-2" />
            {t('DuracionEstancia')}
          </label>
          <label className="d-block ft-08rem mb-0">
            {duration}&nbsp;{duration > 1 ? t('Noches') : t('Noche')}
          </label>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <label className="d-block mb-0">
            <FaUsers className="icon-14 mb-1 mr-2" />
            {t('capacidadMaxima')}
          </label>
          <label className="d-block ft-08rem mb-0">
            {room?.roomDescription[0]?.maxCapacity}&nbsp;{t('Personas')}
          </label>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <label className="label-totales">{t('Total')}</label>
        </Col>
        <Col>
          <label className="label-totales">
            {formatNumber(room?.amountDetail?.total)} USD
          </label>
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(CondosReservaDatosMonetarios);
