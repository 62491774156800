import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { FaHotel, FaDatabase } from 'react-icons/fa';
import { MdCall } from 'react-icons/md';
import useAppTranslation from '../../hook/useAppTranslation';
import styles from './GroupTravelRequests.module.scss';

const { REACT_APP_COMPANY_CONTACT_TELEPHONE } = process.env;

const GroupTravelRequests = () => {
  const { t } = useAppTranslation();

  return (
    <>
      <Container className="m-0 p-0 w-100 mw-100">
        <Row xs="1" className="m-0 p-0 w-100">
          <Col className={styles.containerMain}>
            <Container className="mt-5">
              <Row xs="1">
                <Col lg="7" className="d-none d-sm-block">
                  <span className={styles.title}>{t('GTRTitle')}</span>
                  <span
                    className={styles.call}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: t('GTRCall', {
                        phone: REACT_APP_COMPANY_CONTACT_TELEPHONE,
                      }),
                    }}
                  />
                </Col>

                <Col lg="5">
                  <div className={styles.boxMessage}>
                    {t('GTRAlreadyHaveTravel')}
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <Container className="mt-3 d-none d-sm-block">
        <Row xs="1">
          <Col>
            <span className={styles.weWillBook}>{t('GTRWeWillBook')}</span>
          </Col>

          <Col>
            <span className={styles.plusReward}>{t('GTRPlusReward')}</span>
          </Col>

          <Col>
            <Row xs="4">
              {[
                {
                  title: 'GTRWedding',
                  image: 'https://cdn.signalnode.io/1602541827585.jpg',
                  alt: 'People walking down aisle at wedding',
                },
                {
                  title: 'GTRBusinessTravel',
                  image: 'https://cdn.signalnode.io/1602600735794.jpg',
                  alt: 'People walking through an airport',
                },
                {
                  title: 'GTRSportsTeam',
                  image: 'https://cdn.signalnode.io/1602600408353.jpg',
                  alt: 'Fans at a sports game',
                },
                {
                  title: 'GTRGroupTour',
                  image: 'https://cdn.signalnode.io/1602541942396.jpg',
                  alt: 'Double decker bus tour',
                },
                {
                  title: 'GTRConference',
                  image: 'https://cdn.signalnode.io/1602600600518.jpg',
                  alt: 'Audience at conference presentation',
                },
                {
                  title: 'GTRReunion',
                  image: 'https://cdn.signalnode.io/1602600470656.jpg',
                  alt: 'Family gathering',
                },
                {
                  title: 'GTRCelebration',
                  image: 'https://cdn.signalnode.io/1602600694969.jpg',
                  alt: 'People celebrating New Years Eve',
                },
                {
                  title: 'GTRSocialClub',
                  image: 'https://cdn.signalnode.io/1602600646470.jpg',
                  alt: 'Group of hikers',
                },
              ].map(({ title, image, alt }) => {
                return (
                  <Col key={title} className="p-3">
                    <img src={image} alt={alt} className="w-100" />
                    <span className={styles.titleEvent}>{t(title)}</span>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>

      <Container className={styles.containerBestPrice}>
        <Row xs="1" className={styles.contentBestPrice}>
          <Col>
            <span className={styles.titleBestPrice}>
              {t('GTRBestValueGuarantee')}
            </span>
          </Col>

          <Col>
            <Container>
              <Row xs="3">
                <Col className={styles.containerHotelsAvailable}>
                  <div className={styles.containerIcon}>
                    <FaHotel />
                  </div>

                  <span>{t('GTRHotelsAvailable')}</span>
                </Col>

                <Col className={styles.containerHotelsAvailable}>
                  <div className={styles.containerIcon}>
                    <MdCall />
                  </div>

                  <span>{t('GTR247Reservation')}</span>
                </Col>

                <Col className={styles.containerHotelsAvailable}>
                  <div className={styles.containerIcon}>
                    <FaDatabase />
                  </div>

                  <span>{t('GTRBookTogether')}</span>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <Container className={styles.containerHowItWorks}>
        <Row xs="2">
          <Col>
            <span className={styles.titleHowItWorks}>{t('GTRHowItWorks')}</span>
            <span className={styles.descriptionHowItWorks}>
              {t('GTRHowItWorksDesc')}
            </span>
          </Col>

          <Col className="m-0 p-0">
            <img
              src="https://cdn.signalnode.io/1602600530176.jpg"
              alt="Hikers on top of mountain"
              className="w-100 h-100"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default GroupTravelRequests;
