import React from 'react';

import moment from 'moment';
import { Card, Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';
import useAppTranslation from '../hook/useAppTranslation';

const ReservaCardTopExp = ({ t, item }) => {
  const { language } = useAppTranslation();
  const f1 = moment(item.checkIn, 'DD-MM-YYYY');

  return (
    <Card className="text-light mb-2">
      <Card.Body>
        <Row>
          <Col xs="12" lg="12">
            <h4>{t('YourReservationDetail')}</h4>
            <div className="d-flex justify-content-between">
              <div>
                <label className="d-block mb-0">
                  <FaCalendarAlt className="icon-14 mb-1 mr-2" />
                  {t('Fecha')}
                </label>
                <label className="d-block ft-08rem mb-0">
                  {moment(f1).format('dd, DD MMM, YYYY')}
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <label className="d-block mb-2">
              {item?.destino_name?.[language] ??
                item?.destino_name?.label ??
                ''}
            </label>
            <label className="d-block mb-0  ft-08rem">
              {item.experience_name}
            </label>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default withTranslation()(ReservaCardTopExp);
