import React from 'react';

import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useSportingEvents from '../../hook/useSportingEvents';
import Card from '../Card';
import Text from '../Text';

const CardSportingEvent = ({ item, dolar }) => {
  const { t } = useTranslation();
  const { searchTickets, warranty } = useSportingEvents();
  const handleTicketsClick = () => {
    const newItem = {
      ...item,
      dollar: dolar,
    };
    searchTickets(newItem);
  };
  const handleWarranty = () => {
    warranty(item);
  };

  return (
    <Card>
      <Row>
        <Col xs="12" lg="3">
          <img src={item.venue_img} className="img-fluid" alt="test" />
        </Col>
        <Col xs="12" lg="6">
          <Container
            fluid
            className="h-100 mh-100 m-0 d-flex align-items-start flex-column justify-content-around"
          >
            <Row>
              <Col>
                <Text>{item.sport}</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text bold size="30">
                  {item.caption}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text>{item.tournament}</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text>{item.date_of_event}</Text>
              </Col>
            </Row>
          </Container>
        </Col>

        <Col xs="12" lg="3">
          <Container className="h-100 mh-100 m-0 d-flex align-items-start flex-column justify-content-around">
            {item.startPrice >= 1 ? (
              <Row>
                <Row>
                  <Col>
                    <Text size="18" lineHeight="22" color="#000">
                      {`${t('desde')}   `}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Text
                      size="22"
                      lineHeight="22"
                      color="#51BC65"
                      style={{
                        marginLeft: '10px',
                      }}
                    >
                      {`    ${item.startPrice} USD`}
                    </Text>
                  </Col>
                </Row>
                <Row className="w-100">
                  <Button
                    variant="outline-primary"
                    block
                    className="font-weight-bold rounded-pill"
                    onClick={handleTicketsClick}
                  >
                    Tickets
                  </Button>
                </Row>

                <Row>
                  <Text size="15" lineHeight="20" color="#3A9EC7">
                    Hasta 18 meses sin intereses
                  </Text>
                </Row>
              </Row>
            ) : (
              <Row>
                <Text size="18" lineHeight="22" color="#000">
                  PROXIMAMENTE
                </Text>
                <Button
                  variant="outline-primary"
                  block
                  className="font-weight-bold rounded-pill"
                  onClick={handleWarranty}
                >
                  Tickets
                </Button>
              </Row>
            )}
          </Container>
        </Col>
      </Row>
    </Card>
  );
};

export default CardSportingEvent;
