import React, { useMemo } from 'react';

import { Badge, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './ModalAmenities.module.scss';

const COLS = 4;

const ModalAmenities = ({ show = false, onHide, amenities }) => {
  const { t } = useTranslation();

  const handleHide = (...args) => {
    if (onHide) {
      onHide(args);
    }
  };

  const groups = useMemo(() => {
    const size = Math.ceil(amenities.length / COLS);
    const amenitiesGroup = Array.from({ length: COLS }).map((_, index) => {
      const start = index * size;
      const end = start + size;

      return amenities.slice(start, end);
    });

    return amenitiesGroup;
  }, [amenities]);

  return (
    <Modal size="xl" show={show} onHide={handleHide} className={styles.modal}>
      <Modal.Header>{t('HotelsAmenities')}</Modal.Header>

      <Modal.Body>
        <Container className="m-0 p-0 w-100 mw-100">
          <Row xs="2" lg={COLS}>
            {groups.map((it, index) => {
              return (
                <Col key={index}>
                  <ul className="list-group">
                    {it.map(({ id, name, extraCharge }) => (
                      <li
                        key={id}
                        className={`d-flex flex-column list-group-item ${
                          extraCharge && 'list-group-item-danger'
                        }`}
                      >
                        <span>{name}</span>
                        {extraCharge && (
                          <div className="align-self-end">
                            <Badge variant="secondary" className="text-right">
                              {t('HotelsChargedSeparately')}
                            </Badge>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={handleHide}>
          {t('Aceptar')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAmenities;
