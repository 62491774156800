/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { auth } = useAuth();
  const { loading } = auth;

  if (loading) {
    return (
      <Route
        {...rest}
        render={() => {
          return <p>Loading...</p>;
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={routeProps =>
        auth.data ? <Component {...routeProps} /> : <Redirect to="/welcome" />
      }
    />
  );
};

export default PrivateRoute;
