import React, { useState } from 'react';

import { Button, Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsEyeFill } from 'react-icons/bs';
import { FaInfoCircle } from 'react-icons/fa';
import GetMealPlan from '../../utils/getMealPlan';
import getTaxName from '../../utils/getTaxName';
import AmenitiesRoomDetalle from '../AmenitiesRoomDetalle';
import PriceFormat from '../PriceFormat';

const RoomTable = ({ room, rates, onBookingClick }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [showTaxes, setShowTaxes] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [taxes, setTaxes] = useState([]);

  const handleClose = () => setShow(false);
  const handleCloseTaxes = () => setShowTaxes(false);
  const [countShow, setCountShow] = useState(3);
  const [texto, setTexto] = useState(t('VerMas'));

  const handleBookingClick = rate => {
    if (onBookingClick) onBookingClick({ room, rate });
  };

  const handlePolicyClick = rate => {
    setPolicies(rate.cancellationPolicies);
    setShow(true);
  };

  const handleTaxesClick = item => {
    setTaxes(item.taxes);
    setShowTaxes(true);
  };

  const showAll = () => {
    if (countShow === 1000) {
      setTexto(t('VerMas'));
      setCountShow(3);
    } else {
      setTexto(t('VerMenos'));
      setCountShow(1000);
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('PoliticaCancelacion')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>{t('Inicia')}</th>
                <th>{t('Termina')}</th>
                <th>{t('Monto')}</th>
                <th className="text-center">{t('Reembolsable')}</th>
                <th>{t('Descripcion')}</th>
              </tr>
            </thead>
            <tbody>
              {policies.map((pol, i) => (
                <tr key={i}>
                  <td>{pol.startDate.format('DD-MM-YYYY HH:mm')}</td>
                  <td>{pol.endDate.format('DD-MM-YYYY HH:mm')}</td>
                  <td>
                    <PriceFormat
                      currency={pol.currency}
                      value={pol.amount}
                      decimalScale={0}
                    />
                  </td>
                  <td className="text-center">
                    {pol.nonRefundable ? 'No' : 'Si'}
                  </td>
                  <td>{pol.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Modal show={showTaxes} onHide={handleCloseTaxes} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('Impuestos')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>{t('Nombre')}</th>
                <th className="text-center">{t('Incluido')}</th>
                <th className="text-center">{t('Precio')}</th>
                <th>{t('Descripcion')}</th>
              </tr>
            </thead>
            <tbody>
              {taxes.map(tax => (
                <tr
                  key={tax.name}
                  className={tax.included ? 'text-success' : 'text-danger'}
                >
                  <td>{getTaxName(tax.name)}</td>
                  <td className="text-center">
                    {tax.included ? t('Si') : 'No'}
                  </td>
                  <td className="text-center">
                    <PriceFormat
                      currency={tax.currency}
                      value={tax.amount}
                      decimalScale={0}
                    />
                  </td>
                  <td>{tax.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Table hover striped bordered responsive className="mt-3 table-rates">
        <thead>
          <tr>
            <th>{t('Detalle')}</th>
            <th className="text-center">{t('Precio')}</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {rates.map(
            (rate, i) =>
              i < countShow && (
                <tr key={i}>
                  <td width="50%">
                    <label className="ft-14 d-block mb-0 text-secondary font-weight-bold">
                      {room.roomDescription[0].description}
                    </label>
                    <label className="text-secondary">
                      <GetMealPlan mealPlan={rate.mealPlan} />
                    </label>
                    <AmenitiesRoomDetalle amenities={room.amenities} />
                  </td>

                  <td className="text-center" width="20%">
                    <PriceFormat
                      currency={rate.currency}
                      value={rate.total}
                      decimalScale={0}
                    >
                      {price => (
                        <div className="text-success font-weight-bold">
                          {price}
                          <FaInfoCircle
                            className="text-info icon-18 mb-1 ml-2"
                            onClick={() => handleTaxesClick(rate)}
                          />
                        </div>
                      )}
                    </PriceFormat>
                  </td>

                  <td width="30%" className="text-center">
                    <div>
                      <Button
                        variant="outline-primary"
                        className="font-weight-bold d-block m-auto px-5"
                        onClick={() => handleBookingClick(rate)}
                      >
                        {t('Reservar')}
                      </Button>
                      <small>
                        <Button
                          variant="link"
                          size="sm"
                          className="font-weight-bold  text-danger"
                          onClick={() => handlePolicyClick(rate)}
                        >
                          <BsEyeFill /> {t('VerCancelaciones')}
                        </Button>
                      </small>
                    </div>
                  </td>
                </tr>
              ),
          )}
        </tbody>
        {rates.length > 3 && (
          <tfoot>
            <tr>
              <td colSpan="4" className="text-center">
                <Button
                  variant="light"
                  size="sm"
                  className="font-weight-bold"
                  onClick={showAll}
                >
                  {texto}
                </Button>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
    </>
  );
};

export default RoomTable;
