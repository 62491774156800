import React from 'react';

import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import EditProfile from './EditProfile';
import MyProfile from './MyProfile';

const Home = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  return (
    <Container fluid style={{ 'margin-block': '7rem' }}>
      <Row className="justify-content-md-center">
        <Col xs="12" md="10" xl="9">
          <h4 className="text-primary">{t('MyProfile')}</h4>
          <CardStyled>
            <Card.Body>
              <Switch>
                <Route exact path={url} component={MyProfile} />
                <Route path={`${url}/edit`} component={EditProfile} />

                <Route path={url}>
                  <Redirect to={url} />
                </Route>
              </Switch>
            </Card.Body>
          </CardStyled>
        </Col>
      </Row>
    </Container>
  );
};

const CardStyled = styled(Card)`
  box-shadow: 0px 1px 15px #00000014 !important;
  border-radius: 22px !important;
`;

export default Home;
