import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import FilterSidebar from '../../components/FilterSidebar';
import CardHotel from '../../components/Hotels/CardHotel';
import FormSearch from '../../components/Hotels/FormSearch';
import MapHotels from '../../components/Hotels/MapHotels';
import Pagination from '../../components/Pagination';
import SectionTop from '../../components/SectionTop';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import useDev from '../../hook/useDev';
import CargandoOpciones from '../../loader/CargandoOpciones';
import { useAuth } from '../../providers/AuthProvider';
import { getLabelByDestinyId } from '../../services/destinations';
import { getHotels } from '../../services/hotels';
import Error from '../../shared/Error';
import FormSidebar from '../../shared/FormSidebar';
import normalizeStr from '../../utils/normalizeStr';
import { scrollUp } from '../../utils/scroll';
import sleep from '../../utils/sleep';
import { encode } from '../../utils/uriComponent';
import { BY } from '../../validations/hotels';
import styles from './List.module.scss';

const List = () => {
  const { t, language } = useAppTranslation();
  const { isDev } = useDev();
  const { query } = useAppParams();
  const { isDemo } = useAuth();

  const [loading, setLoading] = useState(true);
  const [hotels, setHotels] = useState([]);
  const [destiny, setDestiny] = useState(null);
  const [errors, setErrors] = useState([]);
  const [allAmenities, setAllAmenities] = useState([]);
  const [minPrecio, setMinPrecio] = useState(0);
  const [maxPrecio, setMaxPrecio] = useState(100);
  const [valuePrecio, setValuePrecio] = useState({ min: 1, max: 100 });
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    let didCancel = false;
    const fetch = async () => {
      await sleep(500);
      if (didCancel || !query) return;

      setLoading(true);
      setDestiny(null);
      setErrors(false);
      setHotels([]);
      setFilters(null);

      if (query.by === BY.DESTINATION) {
        const [labelDestiny, data] = await Promise.all([
          getLabelByDestinyId({
            destinyId: query.destiny,
            language,
          }),

          getHotels({
            destiny: query.destiny,
            checkIn: query.checkIn,
            checkOut: query.checkOut,
            rooms: query.rooms,
            language,
            // currency: null,
            isDemo,
          }),
        ]);

        setDestiny(labelDestiny);
        if (data.errors?.length) {
          // console.log('🚀 ~ List.jsx', { data });
          setErrors(data.errors);
        } else if (data.hotelsValid?.length) {
          setHotels(data.hotelsValid);
          const offset = data.min === data.max ? 1 : 0;
          setMinPrecio(data.min - offset);
          setMaxPrecio(data.max);
          setValuePrecio({ min: data.min - offset, max: data.max });
          setAllAmenities(data.amenities);
        }

        scrollUp();
      } else if (query.by === '0') {
        // console.log('🚀 ~ List.jsx', { query });
      }

      setLoading(false);
    };

    fetch();
    return () => {
      didCancel = true;
    };
  }, [isDemo, query, language]);

  const hotelsFiltered = useMemo(() => {
    if (!hotels?.length) return [];

    if (filters) {
      return hotels.filter(({ name, total, stars, amenities }) => {
        if (filters.name?.length) {
          const str1 = normalizeStr(name).toLocaleLowerCase();
          const str2 = normalizeStr(filters.name).toLocaleLowerCase();

          if (!str1.includes(str2)) return false;
        }

        if (filters.price && filters.price.min && filters.price.max) {
          const price = Number(total) || 0;
          if (price < filters.price.min || price > filters.price.max) {
            return false;
          }
        }

        if (filters.stars?.length && !filters.stars.includes('todas')) {
          const hotelStars = Number(stars);

          if (
            !filters.stars.some(star => {
              const value = Number(star.trim());

              return hotelStars === value;
            })
          ) {
            return false;
          }
        }

        if (filters?.amenities?.length) {
          if (
            amenities.every(
              amenity =>
                !filters.amenities.includes(amenity.name.toLocaleLowerCase()),
            )
          ) {
            return false;
          }
        }

        return true;
      });
    }

    return hotels;
  }, [hotels, filters]);

  const searchList = (valor, typeFilter) => {
    const value = { ...(filters || {}) };
    switch (typeFilter) {
      case 'nombre':
        value.name = valor;
        break;

      case 'precio':
        value.price = valor;
        break;

      case 'stars':
        value.stars = valor;
        break;

      case 'percentage':
        value.percentage = valor;
        break;

      case 'amenities':
        value.amenities = valor.map(name => name.toLocaleLowerCase());
        break;

      default:
        break;
    }

    setCurrentPage(1);
    setFilters(value);
  };

  const handleHotelClick = useCallback(
    hotel => {
      const encodedQuery = encode(query);

      window.open(`/hotels/detail/${encodedQuery}/${hotel.id}`, '_blank');
    },
    [query],
  );

  const handleMouseOverCard = useCallback(
    hotel => {
      setCurrent(hotel);
    },

    [],
  );

  const handleMouseOutCard = useCallback(() => {
    setCurrent(null);
  }, []);

  const renderCard = useCallback(
    hotel => (
      <CardHotel
        hotel={hotel}
        query={query}
        onClick={handleHotelClick}
        onMouseOver={handleMouseOverCard}
        onMouseOut={handleMouseOutCard}
      />
    ),
    [query, handleHotelClick, handleMouseOverCard, handleMouseOutCard],
  );

  if (loading) return <CargandoOpciones />;

  return (
    <>
      <SectionTop
        menu={t('Hoteles', { count: hotels.length })}
        destino={destiny}
        total={hotels?.length ?? 0}
        product={t('Hoteles', { count: hotels.length })}
      />

      <Container fluid className="my-3">
        <Row>
          <Col xs="12" lg="4">
            <Row xs="1" className={styles.containerFilters}>
              <Col>
                <FormSidebar>
                  <FormSearch inline filters={query} />
                </FormSidebar>
              </Col>

              {hotels.length > 0 && (
                <>
                  <Col>
                    <MapHotels
                      hotels={hotelsFiltered}
                      current={current}
                      onMarkerClick={handleHotelClick}
                    />
                  </Col>

                  <Col>
                    <FilterSidebar
                      searchList={searchList}
                      mPrecio={minPrecio}
                      mxPrecio={maxPrecio}
                      valuePrecio={valuePrecio}
                      showStars
                      showPrecio={query.by === '1'}
                      amenities={allAmenities}
                      competence={query.competence}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Col>

          <Col xs="12" lg="8" className={styles.containerHotels}>
            {errors ? (
              <Error errors={errors} />
            ) : (
              <Pagination
                items={hotelsFiltered}
                render={renderCard}
                page={currentPage}
                onChange={setCurrentPage}
              />
            )}
          </Col>
        </Row>

        {isDev && (
          <Row xs="1" className="mt-3">
            {Object.entries({
              query,
            })
              .filter(([, value]) => value)
              .map(([key, value], _, self) => {
                const lg = 12 / self.length;

                return (
                  <Col lg={lg}>
                    <pre>
                      {JSON.stringify(
                        {
                          [key]: value,
                        },
                        null,
                        2,
                      )}
                    </pre>
                  </Col>
                );
              })}
          </Row>
        )}
      </Container>
    </>
  );
};

export default List;
