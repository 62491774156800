import React from 'react';

import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaLock } from 'react-icons/fa';
import FormPayment from '../../FormPayment';

const CardBookingPay = () => {
  const { t } = useTranslation();

  return (
    <Card className="shadow-sm">
      <Card.Body>
        <Row xs="1">
          <Col>
            <h1>
              <FaLock />
              {t('FlightHowWouldLikePay')}
            </h1>
          </Col>
        </Row>

        <FormPayment />
      </Card.Body>
    </Card>
  );
};

export default CardBookingPay;
