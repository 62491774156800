import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Alert,
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Row,
  Spinner,
} from 'react-bootstrap';
import { IoIosArrowDown } from 'react-icons/io';
import useAppTranslation from '../../hook/useAppTranslation';
import { useAuth } from '../../providers/AuthProvider';
import { getImages, getPriceComparison } from '../../services/hotels';
import Lightbox from '../../shared/Lightbox';
import Rating from '../../shared/Rating';
import Tooltip from '../../shared/Tooltip';
import sleep from '../../utils/sleep';
import PriceFormat from '../PriceFormat';
import styles from './CardHotel.module.scss';
import CardOfferAnotherPrice from './CardOfferAnotherPrice';
import ModalAmenities from './ModalAmenities';

const MAX_AMENITIES = 5;
const MAX_OFFER_PRICES = 6;

const CardHotel = ({ hotel, query, onClick, onMouseOver, onMouseOut }) => {
  const { isDemo } = useAuth();
  const { t, language } = useAppTranslation();
  const { checkIn, checkOut, rooms, competence: showCompetence } = query;

  const [images, setImages] = useState([hotel.image]);
  const [loadingPriceComparison, setLoadingPriceComparison] = useState(false);
  const [showPriceComparison, setShowPriceComparison] = useState();
  const [anotherPrices, setAnotherPrices] = useState([]);
  const [maxOffer, setMaxOffer] = useState(0);
  const [showAmenities, setShowAmenities] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);
  const [indexLightbox, setIndexLightbox] = useState(-1);
  const maxHeightOffers = useRef(0);

  useEffect(() => {
    let didCancel = false;
    const fetchImages = async () => {
      await sleep(500);
      if (didCancel || !hotel?.id) return;

      setImages([hotel.image]);
      const data = await getImages({ hotelId: hotel.id });

      setImages(prev => [...new Set([...prev, ...data])]);
    };

    fetchImages();
    return () => {
      didCancel = true;
    };
  }, [hotel]);

  const offerPrices = useMemo(() => {
    if (!anotherPrices.length) return [];

    const group = Array.from({
      length: Math.ceil(anotherPrices.length / MAX_OFFER_PRICES),
    }).map((_, index) => {
      const start = index * MAX_OFFER_PRICES;
      const end = start + MAX_OFFER_PRICES;

      return anotherPrices.slice(start, end);
    });

    return group;
  }, [anotherPrices]);

  const handleSeeMoreClick = () => {
    if (typeof onClick === 'function') {
      onClick(hotel);
    }
  };

  const handleMouseOver = () => {
    if (typeof onMouseOver === 'function') {
      onMouseOver(hotel);
    }
  };

  const handleMouseOut = () => {
    if (typeof onMouseOut === 'function') {
      onMouseOut(hotel);
    }
  };

  const handleImageClick = imageIndex => {
    setIndexLightbox(imageIndex);
    setShowLightbox(true);
  };

  const handleAmenityClick = () => {
    setShowAmenities(true);
  };

  const handleToggleCompetence = async () => {
    if (showPriceComparison) {
      setLoadingPriceComparison(false);
      setShowPriceComparison();
      setMaxOffer(0);

      // The offers are cleaned at the end of the animation.
      setTimeout(() => {
        setAnotherPrices([]);
      }, 500);
      return;
    }

    setLoadingPriceComparison(true);
    const data = await getPriceComparison({
      id: hotel.id,
      checkIn,
      checkOut,
      rooms,
      language,
      currency: hotel.currency,
      isDemo,
      total: hotel.total,
    });

    setAnotherPrices(data);
    setMaxOffer(Math.max(...data.map(({ price }) => price)));
    setShowPriceComparison(true);
    setLoadingPriceComparison(false);
  };

  const handleWantThisClick = () => {
    const mainLauncher = document.querySelector(
      '.intercom-lightweight-app > .intercom-lightweight-app-launcher',
    );

    if (typeof mainLauncher?.click === 'function') {
      mainLauncher.click();
      return;
    }

    const iframe = document.querySelector('.intercom-launcher-frame');
    const documentFrame =
      iframe && (iframe.contentDocument ?? iframe.contentWindow.document);
    if (documentFrame) {
      const launcher = documentFrame.querySelector(
        '#intercom-container > div > div.intercom-launcher',
      );
      const classList = [...launcher.classList];

      if (
        typeof launcher?.click === 'function' &&
        !classList.includes('intercom-launcher-active')
      ) {
        launcher.click();
      }
    }
  };

  const setRefOffers = ref => {
    const clientHeight = ref?.clientHeight ?? 0;

    maxHeightOffers.current = clientHeight;
  };

  return (
    <>
      <ModalAmenities
        show={showAmenities}
        onHide={() => {
          setShowAmenities(false);
        }}
        amenities={hotel.amenities}
      />

      <Lightbox
        show={showLightbox}
        onClose={() => {
          setShowLightbox(false);
        }}
        index={indexLightbox}
        images={images}
        title={hotel.name}
      />

      <Container
        className="mw-100 w-100 m-0 p-0"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <Row className={styles.container}>
          <Col
            xs="12"
            lg={showCompetence ? 6 : 9}
            className={styles.containerCardHotel}
          >
            <Card className={styles.cardHotel}>
              <Card.Header className={styles.headerCardHotel}>
                &nbsp;
              </Card.Header>

              <Card.Body className={styles.bodyCardHotel}>
                <Row xs="1">
                  <Col lg="4" className={styles.containerImage}>
                    {Array.isArray(images) && (
                      <Carousel controls={false} indicators={false}>
                        {images.map((url, index) => (
                          <Carousel.Item
                            key={`${url}-${index}`}
                            onClick={() => handleImageClick(index)}
                          >
                            <img
                              src={url}
                              alt={hotel.name}
                              className="d-block img-thumbnail cursor-pointer w-100 mw-100"
                            />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    )}
                  </Col>

                  <Col lg="8">
                    <Row xs="1">
                      <Col>
                        <h3 className="text-dark">{hotel.name}</h3>
                      </Col>

                      <Col>
                        <h4>
                          <Rating rating={hotel.stars} />
                        </h4>
                      </Col>

                      <Col>
                        <Row
                          xs="12"
                          className={`${styles.containerAmenities} ${
                            showCompetence && styles.showCompetence
                          }`}
                        >
                          {hotel.amenities
                            .filter(it => it?.image?.length)
                            .slice(0, MAX_AMENITIES)
                            .map(it => {
                              return (
                                <Col key={it.id} className="m-0 p-0">
                                  <Tooltip message={it.name}>
                                    <Button
                                      variant="light"
                                      className={styles.buttonAmenity}
                                      onClick={handleAmenityClick}
                                    >
                                      <img
                                        src={it.image}
                                        alt={it.name}
                                        className={styles.imageAmenity}
                                      />
                                    </Button>
                                  </Tooltip>
                                </Col>
                              );
                            })}
                        </Row>
                      </Col>

                      <Col className={styles.seeMore}>
                        <Button variant="link" onClick={handleSeeMoreClick}>
                          {t('HotelsSeeMore')}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col
            xs={showCompetence ? 6 : 12}
            lg="3"
            className={styles.containerCarPrices}
          >
            <Card className={styles.cardPrices}>
              <Card.Header className={styles.headerCardPrices}>
                <h5>
                  {t(isDemo ? 'HotelsPublicPrice' : 'HotelsSunsetMembers')}
                </h5>
              </Card.Header>

              <Card.Body className={styles.bodyCardPrices}>
                <Row xs="1">
                  {hotel.total === 0 ? (
                    <>
                      <Col className="text-center">
                        <h5>{t('HotelsNoAvailabilityDates')}</h5>
                      </Col>

                      <Col className="text-center">
                        <Button variant="link" onClick={handleWantThisClick}>
                          {t('HotelsIWantThis')}
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                      {showCompetence && maxOffer > 0 && (
                        <Col className="text-center">
                          <span className="text-dark">
                            {Math.trunc((1 - hotel.total / maxOffer) * 100)}%
                            OFF
                          </span>
                        </Col>
                      )}

                      <Col className="text-center">
                        <PriceFormat
                          currency={hotel.currency}
                          value={hotel.total}
                          decimalScale={0}
                        >
                          {price => (
                            <h4 className={styles.totalPrice}>{price}</h4>
                          )}
                        </PriceFormat>
                      </Col>

                      {showCompetence && maxOffer > 0 && (
                        <Col className="text-center">
                          <PriceFormat
                            currency={hotel.currency}
                            value={maxOffer - hotel.total}
                            decimalScale={0}
                          >
                            {price => (
                              <span className="text-dark">
                                {t('HotelsSaving', { price })}
                              </span>
                            )}
                          </PriceFormat>
                        </Col>
                      )}

                      <Col className="text-center">
                        <PriceFormat
                          currency={hotel.currency}
                          value={hotel.totalByNight}
                          decimalScale={0}
                        >
                          {price => (
                            <span className="text-dark">
                              {t('HotelsPerNight', { price })}
                            </span>
                          )}
                        </PriceFormat>
                      </Col>
                    </>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>

          {showCompetence && (
            <Col xs="6" lg="3" className={styles.containerCarPrices}>
              <Card className={styles.cardPrices}>
                <Card.Header
                  className={`${styles.headerCardPrices} ${
                    showPriceComparison && styles.openHeaderCardPrices
                  }`}
                >
                  <h5>{t('HotelsCompetition')}</h5>
                </Card.Header>

                <Card.Body className={styles.bodyCardPrices}>
                  <div className={styles.containerImageCompetence}>
                    <img
                      src="/images/hotels/competence.svg"
                      alt={t('HotelsShowCompetence')}
                    />

                    {!showPriceComparison && <h4>{t('HotelsOtherPrices')}</h4>}
                  </div>

                  <div className={styles.containerIconCompetence}>
                    <IoIosArrowDown
                      className={`${styles.iconCompetence} ${
                        showPriceComparison && styles.iconHideCompetence
                      }`}
                      onClick={handleToggleCompetence}
                    />
                  </div>

                  {loadingPriceComparison && (
                    <div className={styles.containerLoadingPriceComparison}>
                      <Spinner animation="border" variant="outline-primary" />
                      <span>{t('HotelsLoadingPriceComparison')}</span>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          )}

          <Col
            xs="12"
            className={styles.containerOffers}
            style={
              showPriceComparison && {
                maxHeight: `${maxHeightOffers.current ?? 0}px`,
                marginTop: '1rem',
              }
            }
          >
            <Container className="m-0 p-0 mw-100 w-100" ref={setRefOffers}>
              {offerPrices.length === 0 && (
                <Row>
                  <Col>
                    <Alert variant="danger">{t('HotelsNoComparisons')}</Alert>
                  </Col>
                </Row>
              )}

              {offerPrices.map((group, indexGroup) => (
                <Row
                  key={indexGroup}
                  xs="2"
                  lg={MAX_OFFER_PRICES}
                  className={styles.rowOffers}
                >
                  {group.map((offer, indexOffer) => (
                    <Col key={`${indexGroup}-${indexOffer}`}>
                      <CardOfferAnotherPrice offer={offer} />
                    </Col>
                  ))}
                </Row>
              ))}
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CardHotel;
