import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import sleep from '../../utils/sleep';

const BookingErrorsForm = ({ touched, errors, submitCount }) => {
  const { t } = useTranslation();

  const oldSubmitCount = useRef(null);
  const [textErrors, setTextErrors] = useState([]);

  useEffect(() => {
    let didCancel = false;
    const set = async () => {
      await sleep(500);
      if (
        didCancel ||
        submitCount === 0 ||
        oldSubmitCount.current === submitCount
      )
        return;

      oldSubmitCount.current = submitCount;
      const newTextErrors = [];
      if (
        (touched.name && errors.name) ||
        (touched.lastName && errors.lastName) ||
        (touched.email && errors.email) ||
        (touched.phone && errors.phone)
      ) {
        newTextErrors.push('ValidacionHuespedPrincipal');
      }

      if (
        (touched?.rooms ?? []).some((room, roomIndex) =>
          room.some((guest, guestIndex) => {
            const error = errors?.rooms?.[roomIndex]?.[guestIndex] ?? {};

            return (
              (guest.name && error.name) || (guest.lastName && error.lastName)
            );
          }),
        )
      ) {
        newTextErrors.push('ValidacionHuespedes');
      }

      if (
        (touched?.payment?.name && errors?.payment?.name) ||
        (touched?.payment?.paymentType && errors?.payment?.paymentType) ||
        (touched?.payment?.creditCard?.number &&
          errors?.payment?.creditCard?.number) ||
        (touched?.payment?.creditCard?.expireMonth &&
          errors?.payment?.creditCard?.expireMonth) ||
        (touched?.payment?.creditCard?.expireYear &&
          errors?.payment?.creditCard?.expireYear) ||
        (touched?.payment?.creditCard?.type?.name &&
          errors?.payment?.creditCard?.type?.name)
      ) {
        newTextErrors.push('ValidacionPayment');
      }

      if (
        (touched?.policy1 && errors?.policy1) ||
        (touched?.policy2 && errors?.policy2)
      ) {
        newTextErrors.push('OkPolitica');
      }

      if (newTextErrors.length) {
        window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
      }
      setTextErrors(newTextErrors);
    };

    set();
    return () => {
      didCancel = true;
    };
  }, [touched, errors, submitCount]);

  return useMemo(() => {
    if (!textErrors.length) return <div />;

    return (
      <Alert variant="danger" className="py-3">
        <ul className="mb-0">
          {textErrors.map((it, i) => (
            <li key={i}>{t(it)}</li>
          ))}
        </ul>
      </Alert>
    );
  }, [textErrors]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default BookingErrorsForm;
