import { Formik } from 'formik';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';

const MarketplaceContactUs = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleOpenModal = () => {
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  const marketplaceDescription = useMemo(
    () => t('MarketplaceDescription'),
    [t],
  );

  const ref = useRef(null);
  // test.current.querySelector("p > a")
  const setRef = useCallback(
    node => {
      if (ref.current) {
        const link = ref.current.querySelector('p > a');
        if (link) {
          link.removeEventListener('click', handleOpenModal);
        }
      }

      if (node) {
        const link = node.querySelector('p > a');
        if (link) {
          link.addEventListener('click', handleOpenModal);
        }
      }

      ref.current = node;
    },

    [],
  );

  const refInput = useRef(null);
  const setFocus = useCallback(
    node => {
      if (show && !refInput.current && node) {
        setTimeout(() => {
          node.focus();
        }, 100);
      }

      refInput.current = node;
    },
    [show],
  );

  return (
    <>
      <Wrapper
        ref={setRef}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: marketplaceDescription }}
      />

      <Formik
        enableReinitialize={show}
        initialValues={{ name: '', email: '', subject: '', message: '' }}
        validationSchema={yup.object().shape({
          name: yup.string().required('MarketplaceContactUsNameRequired'),
          email: yup
            .string()
            .email('MarketplaceContactUsEmailInvalid')
            .required('MarketplaceContactUsEmailRequired'),
          subject: yup.string().required('MarketplaceContactUsSubjectRequired'),
          message: yup.string().required('MarketplaceContactUsMessageRequired'),
        })}
        onSubmit={async (values, formikBag) => {
          formikBag.resetForm();
          handleCloseModal();
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          resetForm,
          values,
          touched,
          errors,
        }) => {
          // console.log('🚀 ~ MarketplaceContactUs.jsx', {
          //   values,
          //   touched,
          //   errors,
          // });
          const handleHide = () => {
            resetForm();
            handleCloseModal();
          };

          return (
            <Form noValidate>
              <Modal
                show={show}
                onHide={handleHide}
                size="lg"
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>{t('Contactanos')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <Row className="mb-3">
                    <Form.Group as={Col} sm="12" lg="6">
                      <Form.Label>
                        {t('MarketplaceContactUsNamePlaceholder')}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        ref={setFocus}
                        // placeholder={t('MarketplaceContactUsNamePlaceholder')}
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={Boolean(touched.name && !errors?.name?.length)}
                        isInvalid={Boolean(
                          touched.name && errors?.name?.length,
                        )}
                      />
                      {Boolean(touched.name) && (
                        <Form.Control.Feedback type="invalid">
                          {errors?.name?.length ? t(errors.name) : ''}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} sm="12" lg="6">
                      <Form.Label>
                        {t('MarketplaceContactUsEmailPlaceholder')}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        // placeholder={t('MarketplaceContactUsEmailPlaceholder')}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={Boolean(
                          touched.email && !errors?.email?.length,
                        )}
                        isInvalid={Boolean(
                          touched.email && errors?.email?.length,
                        )}
                      />
                      {Boolean(touched.email) && (
                        <Form.Control.Feedback type="invalid">
                          {errors?.email?.length ? t(errors.email) : ''}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} sm="12">
                      <Form.Label>
                        {t('MarketplaceContactUsSubjectPlaceholder')}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        // placeholder={t('MarketplaceContactUsSubjectPlaceholder')}
                        name="subject"
                        value={values.subject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={Boolean(
                          touched.subject && !errors?.subject?.length,
                        )}
                        isInvalid={Boolean(
                          touched.subject && errors?.subject?.length,
                        )}
                      />

                      {Boolean(touched.subject) && (
                        <Form.Control.Feedback type="invalid">
                          {errors?.subject?.length ? t(errors.subject) : ''}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} sm="12">
                      <Form.Label>
                        {t('MarketplaceContactUsMessagePlaceholder')}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        // placeholder={t('MarketplaceContactUsMessagePlaceholder')}
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={Boolean(
                          touched.message && !errors?.message?.length,
                        )}
                        isInvalid={Boolean(
                          touched.message && errors?.message?.length,
                        )}
                      />

                      {Boolean(touched.message) && (
                        <Form.Control.Feedback type="invalid">
                          {errors?.message?.length ? t(errors.message) : ''}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={handleHide}>
                    {t('MarketplaceContactUsClose')}
                  </Button>

                  <Button variant="outline-primary" onClick={handleSubmit}>
                    {t('MarketplaceContactUsSendMessage')}
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const Wrapper = styled.div`
  margin-top: 1rem;

  & > p > a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #19b2e2 !important;
  }
`;

export default MarketplaceContactUs;
