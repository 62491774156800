import React from 'react';

import SectionPromotionNY from '../components/SectionPromotionNY';
import PriceBottom from '../shared/PriceBottom';

import '../css/condos/CondosList.css';

const CondosListado = () => {
  return (
    <>
      <div className="bg-gray-400 p-48">Header </div>
      <SectionPromotionNY />
      <PriceBottom />
    </>
  );
};

export default CondosListado;
