import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { BsHouseFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const PromoCondos = ({ t }) => {
  return (
    <Row className="justify-content-center">
      <Col xs="12" lg="10">
        <h2 className="display-4 ft-2-5rem line-before">
          {t('ViveMejorExperiencia')} <strong>{t('RentaCondominios')}</strong>
        </h2>
        <Link
          className="btn btn-primary btn-block text-uppercase font-weight-bold mt-4"
          to="/condos"
        >
          <BsHouseFill className="mb-1" /> {t('VerSemanasDisponibles')}
        </Link>
      </Col>
    </Row>
  );
};

export default withTranslation()(PromoCondos);
