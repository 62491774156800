import moment from 'moment';
import { payment } from './payment';
import yup from './yup';

export const filterSearchSchema = yup.object().shape({
  pickUpDate: yup
    .string()
    .isDateMomentValid()
    .nullable()
    .required('ErrorFieldRequired')
    .default(moment().add(1, 'day').startOf('day').hour(12)),
  dropOffDate: yup
    .string()
    .isDateMomentValid()
    .nullable()
    .required('ErrorFieldRequired')
    .isDateBeforeAtStartDate({
      startDateName: 'pickUpDate',
      message: 'ErrorFechaRegreso',
    })
    .default(moment().add(2, 'day').startOf('day').hour(12)),
  pickUp: yup.number().nullable().required('CampoRequerido').default(null),
  dropOff: yup.number().nullable().required('CampoRequerido').default(null),
});

export const bookingSchema = yup.object().shape({
  name: yup.string().required('ErrorFieldRequired').default(''),
  middleName: yup.string().required('ErrorFieldRequired').default(''),
  lastName: yup.string().required('ErrorFieldRequired').default(''),
  email: yup.string().required('ErrorFieldRequired').default(''),
  phone: yup.string().required('ErrorFieldRequired').default(''),
  policy: yup
    .boolean()
    .oneOf([true], 'CarsBookPolicyAccept')
    .required('ErrorFieldRequired')
    .default(false),
  payment: payment.clone(),
});
