import React, { useMemo } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import Map from '../../shared/Map';
import MapMarker from '../../shared/MapMarker';
import Rating from '../../shared/Rating';
import PriceFormat from '../PriceFormat';
import styles from './MapHotels.module.scss';

const MapHotels = ({ hotels, current, onMarkerClick }) => {
  const defaultCenter = useMemo(() => {
    if (current) {
      return {
        lat: current.latitude,
        lng: current.longitude,
      };
    }

    return null;
  }, [current]);

  return (
    <Map
      infoWindow={{
        showOnClick: false,
        showOnMouseHover: true,
        hideOnMouseOut: true,
      }}
      defaultZoom={defaultCenter ? 15 : 11}
      defaultCenter={defaultCenter}
    >
      {hotels.map(hotel => {
        const show = current && hotel.id === current.id;

        return (
          <MapMarker
            key={hotel.id}
            lat={hotel.latitude}
            lng={hotel.longitude}
            show={show}
            onClick={() => {
              if (onMarkerClick) {
                onMarkerClick(hotel);
              }
            }}
          >
            <Container className="my-1">
              <Row xs="1">
                <Col>
                  <h6 className="text-center text-dark">{hotel.name}</h6>
                </Col>

                <Col className="d-flex justify-content-center">
                  <PriceFormat currency={hotel.currency} value={hotel.total}>
                    {price => (
                      <span className={styles.totalPrice}>{price}</span>
                    )}
                  </PriceFormat>
                </Col>

                <Col className="d-flex justify-content-center">
                  <Rating rating={hotel.stars} />
                </Col>
              </Row>
            </Container>
          </MapMarker>
        );
      })}
    </Map>
  );
};

export default MapHotels;
