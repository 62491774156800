import React from 'react';

import { Markup } from 'interweave';
import {
  Card,
  Col,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import bebida from '../img/experiencias/bebida.svg';
import comida from '../img/experiencias/comida.svg';
import traslado from '../img/experiencias/traslado.svg';
import formatNumber from '../utils/formatNumber';
import setApplyDiscount from '../utils/setApplyDiscount';
import RaitingExperiencias from './RaitingExperiencias';

import '../css/CardExperienciasHorizontal.css';

const CardExperienciasHorizontal = ({
  t,
  item,
  // fechaStart,
  onHandleClickCard,
}) => {
  // console.log(item)
  return (
    <Card
      className="shadow-sm mb-3 card-hover card-tours cursor-pointer"
      onClick={() => onHandleClickCard(item.id, item.source)}
    >
      <Card.Body className="py-1">
        <Row className="align-items-center">
          <Col xs="12" lg="3">
            <div className="text-center">
              <Image
                src={
                  item.mainPhoto && item.source === 'Viator'
                    ? item.mainPhoto.urlPhoto
                    : '/images/no-image.jpg'
                }
                fluid
                className="card-img-max-height"
              />
            </div>
          </Col>
          <Col xs="12" lg="9">
            <div className="card-horizontal">
              <Row className="mb-2">
                <div className="card-content">
                  <span className="card-title-experience">{item.name}</span>
                </div>
                {item.description && (
                  <label className="lh-1-1 text-secondary mt-2">
                    {item.description.length > 170 ? (
                      <Markup
                        content={`${item.description.substr(0, 170)}...`}
                      />
                    ) : (
                      <Markup content={item.description} />
                    )}
                  </label>
                )}
              </Row>
              <RaitingExperiencias rating={item.rating} />
              <span>
                {item.reviews.length} {t('Resenas')}
              </span>
              <Row>
                <Col>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">{t('Traslado')}</Tooltip>
                        }
                      >
                        <Image
                          src={traslado}
                          fluid
                          className="icon-width-1rem mr-3"
                        />
                      </OverlayTrigger>
                    </li>
                    <li className="list-inline-item">
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">{t('Comida')}</Tooltip>
                        }
                      >
                        <Image
                          src={comida}
                          fluid
                          className="icon-width-1rem mr-3"
                        />
                      </OverlayTrigger>
                    </li>
                    <li className="list-inline-item">
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">{t('Bebida')}</Tooltip>
                        }
                      >
                        <Image
                          src={bebida}
                          fluid
                          className="icon-width-1rem mr-3"
                        />
                      </OverlayTrigger>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col xs="6" lg="6">
                  <span className="text-price price-adult">
                    {formatNumber(setApplyDiscount(item.minRates.adult, 0))} USD{' '}
                    <small className="text-dark" style={{ fontSize: `45%` }}>
                      {t('PrecioAdulto')}
                    </small>
                  </span>
                </Col>
                <Col xs="6" lg="6">
                  <div className="text-right">
                    <span
                      className="text-price price-minors"
                      style={{ float: 'right' }}
                    >
                      {formatNumber(setApplyDiscount(item.minRates.child, 0))}{' '}
                      USD{' '}
                      <small className="text-dark" style={{ fontSize: `45%` }}>
                        {t('PrecioMenor')}
                      </small>
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default withTranslation()(CardExperienciasHorizontal);
