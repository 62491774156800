import React, { useEffect, useState } from 'react';

import { nanoid } from 'nanoid';
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import { useTranslation, withTranslation } from 'react-i18next';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useQuery from '../../hook/useQuery';
import LoaderReservar from '../../loader/LoaderReservar';
import { useAuth } from '../../providers/AuthProvider';
import addPoints from '../../utils/addPoints';

const CondoReservaInformacion = ({ condoInfo, setInfoReserva }) => {
  const { t } = useTranslation();
  const query = useQuery();
  const { url } = useRouteMatch();
  const { auth } = useAuth();
  const history = useHistory();
  const search = JSON.parse(query.get('searching'));
  const { rates: room } = condoInfo;
  const [dropInfo, setDropInfo] = useState(false);
  const [dropInfoPay, setDropInfoPay] = useState(true);
  const [client, setClient] = useState({
    name: '',
    sName: '',
    lName: '',
    telClient: '',
    eClient: '',
  });
  const [huespedPrincipal] = useState(true);
  const [acompaniantes, setAcompaniantes] = useState([]);
  const [checkReserva, setCheckReserva] = useState(true);
  const [checkRJustG, setCheckRJustG] = useState(true);
  const [dataLoaded, setdataLoaded] = useState(false);
  const [payment, setPayment] = useState({
    currency: 'USD',
    source: condoInfo?.source ?? '',
    amount: condoInfo?.rates?.amountDetail?.total ?? 0,
    paymentType: 'CREDIT_CARD',
    creditCard: {
      number: '',
      expireMonth: '01',
      expireYear: '2021',
      type: {
        name: 'VI',
      },
    },
  });
  const [textoError, setTextoError] = useState([]);

  const [sendindReserva, setSendingReserva] = useState(false);

  // Generar la lista de acompaniantes
  useEffect(() => {
    const total = room?.roomDescription[0]?.maxCapacity;
    const acompanianteAr = [];
    for (let i = 1; i <= total; i++) {
      acompanianteAr.push({
        name: '',
        lName: '',
      });
    }

    setAcompaniantes(acompanianteAr);

    setTimeout(() => {
      setdataLoaded(true);
    }, 2000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Para actualizar los datos del acompañante con los del titular
  useEffect(() => {
    if (huespedPrincipal && dataLoaded) {
      const obj = {
        name: `${client.name}${
          client.sName.length > 0 ? ` ${client.sName}` : ''
        }`,
        lName: client.lName,
      };

      const arr = [...acompaniantes];
      arr[0] = obj;
      setAcompaniantes(arr);
    }
  }, [client]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (textoError.length > 0) {
      window.scrollTo(0, 150);
    }
  }, [textoError]);

  // Para guardar los datos de quién realiza la reserva
  const typeClient = (value, key) => {
    if (key === 'name') {
      setClient(prevState => ({
        ...prevState,
        name: value,
      }));
    } else if (key === 'sname') {
      setClient(prevState => ({
        ...prevState,
        sName: value,
      }));
    } else if (key === 'lname') {
      setClient(prevState => ({
        ...prevState,
        lName: value,
      }));
    } else if (key === 'telClient') {
      setClient(prevState => ({
        ...prevState,
        telClient: value,
      }));
    } else if (key === 'eClient') {
      setClient(prevState => ({
        ...prevState,
        eClient: value,
      }));
    }
  };

  // Para actualizar los datos de los acompaniantes
  // const typeAcompaniantes = (position, value, key) => {
  //   const obj =
  //     key === 'name'
  //       ? { ...acompaniantes[position], name: value }
  //       : { ...acompaniantes[position], lName: value };
  //   const arr = [...acompaniantes];

  //   arr[position] = obj;
  //   setAcompaniantes(arr);
  // };

  // Para controlar cuando el que reserva es el huesped
  // const yoSoyHuesped = (checked, isHuesped) => {
  //   setHuespedPrincipal(isHuesped);

  //   const obj = isHuesped
  //     ? {
  //         name: `${client.name}${
  //           client.sName.length > 0 ? ` ${client.sName}` : ''
  //         }`,
  //         lName: client.lName,
  //       }
  //     : {
  //         name: '',
  //         lName: '',
  //       };

  //   const arr = [...acompaniantes];
  //   arr[0] = obj;

  //   setAcompaniantes(arr);
  // };

  const typeCard = (value, key) => {
    if (key === 'titular') {
      // para donde va el titular
    } else if (key === 'type_card') {
      setPayment(previous => ({
        ...previous,
        paymentType: value,
      }));
    } else if (key === 'no_card') {
      const objCreditCard = payment.creditCard;
      objCreditCard.number = value;
      setPayment(previous => ({
        ...previous,
        creditCard: objCreditCard,
      }));
    } else if (key === 'expireMonth') {
      const objCreditCard = payment.creditCard;
      objCreditCard.expireMonth = value;
      setPayment(previous => ({
        ...previous,
        creditCard: objCreditCard,
      }));
    } else if (key === 'expireYear') {
      const objCreditCard = payment.creditCard;
      objCreditCard.expireYear = value;
      setPayment(previous => ({
        ...previous,
        creditCard: objCreditCard,
      }));
    } else if (key === 'cvv') {
      // donde va el cvv
    }
  };

  const onHandleClickReservacion = () => {
    // setTextoError([])
    const reservaBooking = {
      currency: 'USD',
      total: condoInfo?.rates?.amountDetail?.total,
      source: condoInfo?.source,
      reference: 'CMOM', // fijo
      company: 'JustGo', // fij
      city: search.desiny,
      client: {
        name: `${client.name}${client.sName === '' ? '' : ` ${client.sName}`}`,
        lastname: client.lName,
        country: 'OT', // fijo
        email: client.eClient,
        phone: {
          number: client.telClient,
          type: 'HOME', // fijo
        },
        hotel: {
          name: condoInfo.name,
          id: 2, // fijo por el momento
        },
      },
      activities: [], // fijo
      hotels: [
        {
          id: condoInfo.id,
          source: condoInfo.source,
          name: condoInfo.name,
          destination: search.desiny,
          checkin: room.effectiveDate,
          checkout: room.expireDate,
          price: room?.amountDetail?.total,
          rooms: [
            {
              id: room?.rateKey,
              roomType: '',
              planId: '',
              price: room?.amountDetail?.total,
              paxes: {
                adults: 0,
                children: 0,
                infant: 0,
              },
              paxesInformation: acompaniantes,
            },
          ],
          paxes: {
            adults: acompaniantes.length,
            children: 0,
            infant: 0,
          },
        },
      ],
      payment,
    };

    const arr = [];
    if (
      reservaBooking.client.name === '' ||
      reservaBooking.client.lastname === '' ||
      reservaBooking.client.email === '' ||
      reservaBooking.client.phone.number === ''
    ) {
      const txt = t('ValidacionHuespedPrincipal');
      arr.push(txt);
    }
    // if(isEmptyPaxes(reservaBooking.hotels)){
    //     let txt = t('ValidacionHuespedes')
    //     arr.push(txt)
    // }
    if (reservaBooking.payment.creditCard.number === '') {
      const txt = t('ValidacionPayment');
      arr.push(txt);
    }
    if (!checkReserva || !checkRJustG) {
      const txt = t('OkPolitica');
      arr.push(txt);
    }
    setTextoError(arr);

    if (arr.length === 0) {
      const dataVoucher = {
        client_name: `${reservaBooking.client.name} ${reservaBooking.client.lastname}`,
        client_phone: reservaBooking.client.phone.number,
        client_email: reservaBooking.client.email,
        place_name: reservaBooking.hotels[0].name,
        reservationCode: nanoid(),
        checkin: reservaBooking.hotels[0].checkin,
        checkout: reservaBooking.hotels[0].checkout,
        paxes: reservaBooking.hotels[0].paxes,
        currency: reservaBooking.currency,
        total: reservaBooking.total,
      };

      setInfoReserva(dataVoucher);
      /*      setSendingReserva(true);
      // const htmlBody = handleEmailConfirmation(t, reservaBooking, client);
      const paramsEmail = {
        content: htmlBody,
        from: 'demo@gmail.com',
        subject: 'CONFIRMACION',
        to: client.email,
      }; 
      sendMail(paramsEmail);
      */
      setSendingReserva(true);
      addPoints({
        membership: auth.data.membership,
        describe: search.desiny,
        points: dataVoucher.total,
      });
      setTimeout(() => {
        setSendingReserva(false);
        history.push(`${url}/voucher`);
      }, 3000);
    }
  };

  return (
    <>
      {sendindReserva && <LoaderReservar />}
      <Card className="shadow-sm condo-map-container bg-blue-sky">
        <Card.Body>
          <Row>
            <Col xs="12" lg="12">
              <h1 className="text-strong">{t('EnterYourData')}</h1>
              <small className="d-block text-secondary text-strong">
                {t('RevisaDatosCorrectos')}
              </small>
              <small className="d-block text-secondary ">
                {t('PleaseCheckAllData')}
              </small>
            </Col>
          </Row>
          <br />
          {textoError.length > 0 && (
            <Alert variant="danger" className="py-2">
              <ul className="mb-0">
                {textoError.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </Alert>
          )}
          <Row>
            <Col xs="12" lg="12">
              <Accordion defaultActiveKey="0">
                <Card className="shadow-none border-0 bg-light card-reserva">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={() => setDropInfo(!dropInfo)}
                  >
                    <h6 className="cursor-pointer">
                      {t('PaymentInformation')}{' '}
                      {dropInfo ? <FaCaretDown /> : <FaCaretUp />}
                    </h6>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Row>
                        <Col xs="12" lg="4">
                          <Form.Group>
                            <Form.Label>{t('Nombre')}*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={t('NombrePlaceholder')}
                              onChange={e => typeClient(e.target.value, 'name')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs="12" lg="4">
                          <Form.Group>
                            <Form.Label>{t('SegundoNombre')}</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={t('SegundoNombrePlaceholder')}
                              onChange={e =>
                                typeClient(e.target.value, 'sname')
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xs="12" lg="4">
                          <Form.Group>
                            <Form.Label>{t('Apellidos')}*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={t('ApellidosPlaceholder')}
                              onChange={e =>
                                typeClient(e.target.value, 'lname')
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" lg="4">
                          <Form.Group>
                            <Form.Label>{t('CorreoElectronico')}*</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder={t('CorreoElectronicoPlaceholder')}
                              onChange={e =>
                                typeClient(e.target.value, 'eClient')
                              }
                            />
                            <Form.Text className="text-muted">
                              {t('CorreoElectronicoParrafo')}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                        <Col xs="12" lg="4">
                          <Form.Group>
                            <Form.Label>{t('Telefono')}*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={t('TelefonoPlaceholder')}
                              onChange={e =>
                                typeClient(e.target.value, 'telClient')
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* <Row>
                                            <Col xs="12" lg="12">
                                                <label className="d-block">{t('ParaQuienReserva')}</label>
                                                <Form.Check 
                                                    type='radio'
                                                    id='radio-1'
                                                    label={t('HuespedPrincipal')}
                                                    name="radio"
                                                    className="text-secondary"
                                                    checked={ huespedPrincipal }
                                                    onChange={e=>yoSoyHuesped(e.target.checked, true)}
                                                />
                                                <Form.Check 
                                                    type='radio'
                                                    id='radio-2'
                                                    label={t('OtraPersona')}
                                                    name="radio"
                                                    className="text-secondary"
                                                    checked={ !huespedPrincipal }
                                                    onChange={e=>yoSoyHuesped(e.target.checked, false)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            {
                                                acompaniantes.map( (pax, i) =>  <Col key={`acompaniante-${i}`} xs="12" lg="12">
                                                    <label className="font-weight-bold">{ t('Acompaniante') }&nbsp;{ i+1 }</label>
                                                    <Row>
                                                        <Col xs="12" lg="6">
                                                            <Form.Group>
                                                                <Form.Label>{t('Nombre')}*</Form.Label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    placeholder={t('NombrePlaceholder')}
                                                                    value={ pax.name }
                                                                    readOnly={ (i===0 && huespedPrincipal) ? true : false }
                                                                    onChange={ e => typeAcompaniantes(i, e.target.value, 'name') } 
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs="12" lg="6">
                                                            <Form.Group>
                                                                <Form.Label>{t('Apellidos')}*</Form.Label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    placeholder={t('ApellidosPlaceholder')} 
                                                                    value={ pax.lName }
                                                                    readOnly={ (i===0 && huespedPrincipal) ? true : false }
                                                                    onChange={ e => typeAcompaniantes(i, e.target.value, 'lName') }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col> )
                                            }
                                        </Row> */}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
            <Col xs="12" lg="12">
              <Accordion>
                <Card className="shadow-none border-0 bg-light card-reserva">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={() => setDropInfoPay(!dropInfoPay)}
                  >
                    <h6 className="cursor-pointer">
                      {t('HotelsPrimaryGuestInformation')}{' '}
                      {dropInfoPay ? <FaCaretDown /> : <FaCaretUp />}
                    </h6>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body eventKey="0">
                      <Row>
                        <Col xs="12" lg="12">
                          <Alert variant="info">
                            {t('HotelsAccommodationRequires')}
                          </Alert>
                        </Col>
                        <Col xs="12" lg="6">
                          <Form.Group>
                            <Form.Label>{t('PaymentNameOnCard')}*</Form.Label>
                            <Form.Control
                              type="text"
                              onChange={e =>
                                typeCard(e.target.value, 'titular')
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xs="12" lg="4">
                          <Form.Group>
                            <Form.Label>{t('PaymentCardType')}*</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={e =>
                                typeCard(e.target.value, 'type_card')
                              }
                            >
                              <option value="CREDIT_CARD">Crédito</option>
                              <option value="DEBIT_CARD">Débito</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" lg="6">
                          <Form.Group>
                            <Form.Label>
                              {t('PaymentCreditCardNumber')}*
                            </Form.Label>
                            <Form.Control
                              type="text"
                              onChange={e =>
                                typeCard(e.target.value, 'no_card')
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xs="12" lg="2">
                          <Form.Group>
                            <Form.Label>{t('Month')}*</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={e =>
                                typeCard(e.target.value, 'expireMonth')
                              }
                            >
                              <option value="01">{t('Enero')}</option>
                              <option value="02">{t('Febrero')}</option>
                              <option value="03">{t('Marzo')}</option>
                              <option value="04">{t('Abril')}</option>
                              <option value="05">{t('Mayo')}</option>
                              <option value="06">{t('Junio')}</option>
                              <option value="07">{t('Julio')}</option>
                              <option value="08">{t('Agosto')}</option>
                              <option value="09">{t('Septiembre')}</option>
                              <option value="10">{t('Octubre')}</option>
                              <option value="11">{t('Noviembre')}</option>
                              <option value="12">{t('Diciembre')}</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs="12" lg="2">
                          <Form.Group>
                            <Form.Label>{t('Year')}*</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={e =>
                                typeCard(e.target.value, 'expireYear')
                              }
                            >
                              <option value="2021">2021</option>
                              <option value="2022">2022</option>
                              <option value="2023">2023</option>
                              <option value="2024">2024</option>
                              <option value="2025">2025</option>
                              <option value="2026">2026</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs="12" lg="2">
                          <Form.Group>
                            <Form.Label>
                              {t('PaymentCardSecureCode')}*
                            </Form.Label>
                            <Form.Control
                              type="number"
                              maxLength={3}
                              onChange={e => typeCard(e.target.value, 'cvv')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" lg="12">
                          <Form.Check
                            type="checkbox"
                            id="check-1"
                            label={t('HotelsPolicy1')}
                            className="text-secondary"
                            checked={checkReserva}
                            onChange={e => setCheckReserva(e.target.checked)}
                          />
                          <Form.Check
                            type="checkbox"
                            id="check-2"
                            label={t('HotelsPolicy2')}
                            className="text-secondary"
                            checked={checkRJustG}
                            onChange={e => setCheckRJustG(e.target.checked)}
                          />
                        </Col>
                        <Col xs="12" lg="3">
                          <Button
                            variant="outline-primary"
                            className="font-weight-bold mt-4"
                            onClick={onHandleClickReservacion}
                          >
                            {t('CompleteReservation')}
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default withTranslation()(CondoReservaInformacion);
