import React from 'react';

import moment from 'moment';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';

const ReservaCardTop = ({ rate }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs="12" lg="12">
            <h4>{t('YourReservationDetail')}</h4>
            <div className="d-flex justify-content-between">
              <div>
                <label className="d-block mb-0">
                  <FaCalendarAlt className="icon-14 mb-1 mr-2" />
                  {t('Entrada')}
                </label>

                <label className="d-block ft-08rem mb-0">
                  {moment(rate.checkIn).format('dd, DD MMM, YYYY')}
                </label>
              </div>

              <div>
                <label className="d-block mb-0">
                  <FaCalendarAlt className="icon-14 mb-1 mr-2" />
                  {t('SalidaCheckout')}
                </label>

                <label className="d-block ft-08rem mb-0">
                  {moment(rate.checkOut).format('dd, DD MMM, YYYY')}
                </label>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <label className="d-block mb-0">{t('DuracionEstancia')}</label>

            <label className="d-block ft-08rem mb-0">
              {rate.nights} {t('Nights', { count: rate.nights })}
            </label>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <label className="d-block mb-0">{t('TuHabitacion')}</label>
            <label className="d-block ft-08rem mb-0">
              {rate.roomName ?? rate.habitacion_name}
            </label>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ReservaCardTop;
