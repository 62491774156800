import React, { useMemo, useState } from 'react';

import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

const MAX_STARS = 5;
const AMENITIES = [
  'piscina',
  'swimming pool',
  'wi-fi gratuito',
  'free wi-fi',
  'aire acondicionado',
  'air conditioning',
  'pets allowed',
  'se admiten mascotas',
  'spa',
  'wheelchair accessible',
  'accesible para sillas de ruedas',
  'breakfast',
  'desayuno',
  'next to the beach',
  'junto a la playa',
  'parking',
  'aparcamiento',
  'restaurant',
  'restaurante',
  'shower/bathtub',
  'ducha/bañera',
  'gym',
  'gimnasio',

  'cajero automático',
  'atm',
  'tiendas en las instalaciones',
  'shopping on si-te',
  'cambio de divisas',
  'currency exchange',
  'alojamiento para no fumadores',
  'habitaciones para no fumadores',
  'calefacción',
  'guardia de seguridad',
  'security guard',
  'periódicos',
  'newspapers',
  'asistencia para reservas',
  'tour assistance',
  'tienda de regalos',
  'gift shop',
  'check-in/check-out rápido',
  'express check-in/check-out',
  'jardín',
  'garden',
  'televisión en el vestíbulo',
  'tv',
  'terraza',
  'terrace',
  'portero',
  'doorman',
  'mostrador de recepción',
  'reception desk',
  'servicio de habitaciones',
  'room service',
  'nevera',
  'fridge',
  'habitación familiar',
  'family room',
  'detector de humos',
  'smoke detector',
  'televisión por cable',
  'cable tv',
  'minibar',
  'secador',
  'hairdryer',
  'ducha',
  'shower',
  'armario',
  'wardrobe/closet',
  'despertador',
  'alarm clock',
  'caja fuerte',
  'safe (in room)',
  'artículos de aseo',
  'ascensor',
  'elevator/lift',
  'accesibilidad',
  'accessibility features',
  'sala de planchado',
  'iron and board',
  'servicio de planchado',
  'ironing',
  'consigna de equipajes',
  'lavandería',
  'laundry',
  'servicio de conserjería',
  'concierge services',
  'tintorería',
  'masajes',
  'teléfono',
  'telephone',
  'plancha',
  'iron',
  'bar',
  'buffet desayuno',
  'buffet breakfast',
  'café',
  'cafe',
  'wi-fi',
  'internet access',
  'acceso a internet en la habitación',
];

const Stars = ({ id, stars = MAX_STARS, onChange }) => {
  const starsComponent = useMemo(() => {
    const acc = [];
    acc.push(
      ...Array.from({ length: stars }).map(() => (
        <AiFillStar className="mb-1" />
      )),
    );

    if (acc.length < MAX_STARS) {
      acc.push(
        ...Array.from({ length: MAX_STARS - acc.length }).map(() => (
          <AiOutlineStar className="mb-1" />
        )),
      );
    }

    return acc;
  }, [stars]);

  return (
    <Form.Check
      type="checkbox"
      id={id}
      label={starsComponent}
      onChange={e => {
        if (onChange) {
          onChange(e.target.checked, `${stars}`);
        }
      }}
    />
  );
};

const FilterSidebar = ({
  searchList,
  mPrecio,
  mxPrecio,
  valuePrecio,
  showStars,
  showPrecio = true,
  showFilterCar = false,
  percentage = true,
  amenities: lstAmenities = [],
  competence = false,
}) => {
  const { t } = useTranslation();
  const [nombre, setNombre] = useState('');
  const [minPrecio] = useState(mPrecio);
  const [maxPrecio] = useState(mxPrecio);
  const [rangePrecio, setRangePrecio] = useState(valuePrecio);
  const [stars, setStars] = useState([]);
  const [amenitiesSelected, setAmenitiesSelected] = useState([]);

  const options2 = { style: 'currency', currency: 'USD' };
  const numberFormat2 = new Intl.NumberFormat('en-US', options2);

  const amenities = useMemo(() => {
    if (!Array.isArray(lstAmenities) || !lstAmenities.length) return [];

    return lstAmenities
      .sort((a, b) => {
        const valueA = a.toLocaleLowerCase();
        const valueB = b.toLocaleLowerCase();

        const result = AMENITIES.reduce((acc, it, _, arr) => {
          if (acc !== 0) {
            arr.splice(1);
            return acc;
          }

          const amenity = it.toLocaleLowerCase();
          if (valueA === amenity) return -1;
          if (valueB === amenity) return 1;

          return 0;
        }, 0);

        if (result !== 0) return result;
        return valueA.localeCompare(valueB);
      })
      .slice(0, 20);
  }, [lstAmenities]);

  const onChangeHandle = (checked, tipo) => {
    const arr = [...stars];
    if (checked) {
      if (arr.findIndex(elem => elem === tipo) >= 0) {
        searchList(arr, 'stars');
      } else {
        arr.push(tipo);
        setStars(arr);
        searchList(arr, 'stars');
      }
    } else if (arr.findIndex(elem => elem === tipo) >= 0) {
      arr.splice(
        arr.findIndex(elem => elem === tipo),
        1,
      );
      setStars(arr);
      searchList(arr, 'stars');
    }
  };

  const onChangeHandlePercentage = (checked, tipo) => {
    const arr = [...percentage];
    if (checked) {
      if (arr.findIndex(elem => elem === tipo) >= 0) {
        searchList(arr, 'percentage');
      } else {
        arr.push(tipo);
        setStars(arr);
        searchList(arr, 'percentage');
      }
    } else if (arr.findIndex(elem => elem === tipo) >= 0) {
      arr.splice(
        arr.findIndex(elem => elem === tipo),
        1,
      );
      setStars(arr);
      searchList(arr, 'percentage');
    }
  };

  const onChangeHandleTransmision = (checked, tipo) => {
    searchList(checked, tipo);
  };

  const onChangeHandleCapacidad = (checked, tipo) => {
    // console.log(checked)
    // console.log(tipo)
    searchList(checked, tipo);
  };

  const onChangeHandleCaracteristicas = (checked, tipo) => {
    // console.log(checked)
    // console.log(tipo)
    searchList(checked, tipo);
  };

  const handleAmenityChange = e => {
    if (!e?.target?.name?.length) return;
    const { name, checked } = e.target;

    let newAmenitiesSelected = [];
    if (name === 'all') {
      newAmenitiesSelected = checked ? [...amenities] : [];
    } else {
      newAmenitiesSelected = amenitiesSelected
        .concat([name])
        .filter(amenity => {
          if (amenity !== name) return true;

          return checked;
        });
    }

    setAmenitiesSelected(newAmenitiesSelected);
    searchList(
      amenities.length === newAmenitiesSelected.length
        ? []
        : newAmenitiesSelected,
      'amenities',
    );
  };

  return (
    <>
      <Row className="mb-3 justify-content-md-center">
        <Col xs="12" lg="10">
          <label className="font-weight-bold">{t('Nombre')}</label>
          <Form.Control
            type="text"
            value={nombre}
            onChange={e => {
              setNombre(e.target.value);
              searchList(e.target.value, 'nombre');
            }}
            placeholder={t('IngreseNombre')}
          />
        </Col>
      </Row>

      {showPrecio && (
        <Row className="mb-5 justify-content-md-center">
          <Col xs="12" lg="10">
            <h4 className="text-uppercase mb-3">{t('FiltersPopular')}</h4>
            <label className="font-weight-bold  mb-3">{t('Precio')}</label>
            <InputRange
              maxValue={maxPrecio}
              minValue={minPrecio}
              value={rangePrecio}
              onChange={value => {
                setRangePrecio(value);
                searchList(value, 'precio');
              }}
              formatLabel={value => `${numberFormat2.format(value)} `}
            />
          </Col>
        </Row>
      )}

      {competence && percentage && (
        <Row className="mb-5 justify-content-md-center">
          <Col xs="12" lg="10">
            <label className="font-weight-bold">{t('Descuentos')}</label>
            <Form.Check
              type="checkbox"
              id="check-4"
              label={<div>0-10%</div>}
              onChange={e => {
                onChangeHandlePercentage(e.target.checked, '1');
              }}
            />
            <Form.Check
              type="checkbox"
              id="check-4"
              label={<div>11-25%</div>}
              onChange={e => {
                onChangeHandlePercentage(e.target.checked, '2');
              }}
            />
            <Form.Check
              type="checkbox"
              id="check-4"
              label={<div>26-40%</div>}
              onChange={e => {
                onChangeHandlePercentage(e.target.checked, '3');
              }}
            />
            <Form.Check
              type="checkbox"
              id="check-4"
              label={<div> + 50% </div>}
              onChange={e => {
                onChangeHandlePercentage(e.target.checked, '4');
              }}
            />
          </Col>
        </Row>
      )}

      {showStars && (
        <Row className="mb-5 justify-content-md-center">
          <Col xs="12" lg="10">
            <label className="font-weight-bold">{t('Estrellas')}</label>
            <Form.Check
              type="checkbox"
              id="check-todas"
              label={t('Todas')}
              onChange={e => {
                onChangeHandle(e.target.checked, 'todas');
              }}
            />

            <Stars id="check-1" stars={5} onChange={onChangeHandle} />
            <Stars id="check-2" stars={4} onChange={onChangeHandle} />
            <Stars id="check-3" stars={3} onChange={onChangeHandle} />
            <Stars id="check-4" stars={2} onChange={onChangeHandle} />
            <Stars id="check-5" stars={1} onChange={onChangeHandle} />
          </Col>
        </Row>
      )}

      {Boolean(amenities.length) && (
        <Row className="mb-5 justify-content-md-center">
          <Col xs="12" lg="10">
            <label className="font-weight-bold">{t('Comodidades')}</label>
            <Form.Check
              type="checkbox"
              id="all"
              name="all"
              label={t('Todas')}
              checked={amenities.length === amenitiesSelected.length}
              onChange={handleAmenityChange}
            />

            {amenities.map(amenity => {
              return (
                <Form.Check
                  type="checkbox"
                  id={amenity}
                  name={amenity}
                  label={amenity}
                  checked={amenitiesSelected.includes(amenity)}
                  onChange={handleAmenityChange}
                />
              );
            })}
          </Col>
        </Row>
      )}

      {showFilterCar && (
        <Row className="mb-5 justify-content-md-center">
          <Col xs="12" lg="10">
            <Row className="my-3">
              <Col xs="12" md="12">
                <label className="font-weight-bold">Transmisión</label>
                <Form.Check
                  type="checkbox"
                  id="trans-automatica"
                  label="Automática"
                  onChange={e => {
                    onChangeHandleTransmision(e.target.checked, 'automatica');
                  }}
                />

                <Form.Check
                  type="checkbox"
                  id="trans-manual"
                  label="Manual"
                  onChange={e => {
                    onChangeHandleTransmision(e.target.checked, 'manual');
                  }}
                />
              </Col>
            </Row>

            <Row className="my-3">
              <Col xs="12" md="12">
                <label className="font-weight-bold">Capacidad</label>
                <Form.Check
                  type="checkbox"
                  id="capacidad12"
                  label="1 - 2 personas"
                  onChange={e => {
                    onChangeHandleCapacidad(e.target.checked, 'capacidad12');
                  }}
                />

                <Form.Check
                  type="checkbox"
                  id="capacidad24"
                  label="2 - 4 personas"
                  onChange={e => {
                    onChangeHandleCapacidad(e.target.checked, 'capacidad24');
                  }}
                />

                <Form.Check
                  type="checkbox"
                  id="capacidad46"
                  label="4 - 6 personas"
                  onChange={e => {
                    onChangeHandleCapacidad(e.target.checked, 'capacidad46');
                  }}
                />

                <Form.Check
                  type="checkbox"
                  id="capacidad610"
                  label="6 - 10 personas"
                  onChange={e => {
                    onChangeHandleCapacidad(e.target.checked, 'capacidad610');
                  }}
                />
              </Col>
            </Row>

            <Row className="my-3">
              <Col xs="12" md="12">
                <label className="font-weight-bold">Características</label>
                <Form.Check
                  type="checkbox"
                  id="aireacondicionado"
                  label="Aire acondicionado"
                  onChange={e => {
                    onChangeHandleCaracteristicas(
                      e.target.checked,
                      'aireacondicionado',
                    );
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default FilterSidebar;
