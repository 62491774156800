import React, { useState } from 'react';

import moment from 'moment';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FormSidebar from '../shared/FormSidebar';
import DatePicker from './DatePicker';
import SelectDestinations from './SelectDestinations';
import SelectPerson from './SelectPerson';

const FormExperienceSidebar = ({ searching, sendData, showDestiny }) => {
  const { t, i18n } = useTranslation();
  const [destino, setDestino] = useState({
    value: searching.destiny,
    ...searching.destino_name,
  });
  const [startDate, setStartDate] = useState(
    moment(searching.checkIn, 'DD-MM-YYYY'),
  );
  const [adult, setAdult] = useState(searching.paxes.adults);
  const [child, setChild] = useState(searching.paxes.children);
  const history = useHistory();
  const [errorDestino, setErrorDestino] = useState(false);

  const onHandleClickBuscar = () => {
    if (destino === null) {
      setErrorDestino(true);
    } else {
      const d = {
        destiny: destino === null ? null : destino.value,
        destino_name: {
          label: destino?.label ?? '',
          es: destino?.es ?? null,
          en: destino?.en ?? null,
        },
        checkIn: moment(startDate).format('DD-MM-YYYY'),
        apiName: process.env.REACT_APP_SUNAPI_API_NAME_TOUR,
        currency: 'USD',
        language: i18n.language,
        participants: adult + child,
        paxes: {
          adults: adult,
          children: child,
        },
      };
      sendData(d);
      if (showDestiny) {
        history.push(
          `/experience?search=${encodeURIComponent(JSON.stringify(d))}`,
        );
      } else {
        history.push(
          `/experience/detail?search=${encodeURIComponent(JSON.stringify(d))}`,
        );
      }
    }
  };

  return (
    <FormSidebar>
      {showDestiny && (
        <Row className="mb-2">
          <Col xs="12" lg="12">
            <SelectDestinations
              value={destino}
              onChange={value => setDestino(value)}
            />

            {errorDestino && (
              <Form.Control.Feedback type="invalid">
                {t('CampoRequerido')}
              </Form.Control.Feedback>
            )}
          </Col>
        </Row>
      )}

      <Row className="mb-2">
        <Col xs="12" lg="12">
          <DatePicker
            name="start_date_exp_id"
            id="startDateExpId"
            date={startDate}
            onDateChange={startDateExpId => {
              // console.log('🚀 ~ FormExperienceSidebar.js', { startDateExpId });
              if (startDateExpId === null) {
                setStartDate(moment().add(1, 'days'));
              } else {
                setStartDate(startDateExpId);
              }
            }}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs="4" lg="6">
          <Form.Group>
            <Form.Label className="text-light">{t('Adults')}</Form.Label>
            <SelectPerson
              adult
              child={false}
              setAdult={setAdult}
              text={adult}
            />
          </Form.Group>
        </Col>
        <Col xs="4" lg="6">
          <Form.Group>
            <Form.Label className="text-light">{t('Ninos')}</Form.Label>
            <SelectPerson
              adult={false}
              child
              setChild={setChild}
              text={child}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs="12" lg="12">
          <Button
            block
            variant="light"
            onClick={onHandleClickBuscar}
            className="font-weight-bold"
          >
            {t('Buscar')}
          </Button>
        </Col>
      </Row>
    </FormSidebar>
  );
};

export default FormExperienceSidebar;
