/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';

import { Button, Modal, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { BsEyeFill } from 'react-icons/bs';
import formatNumber from '../utils/formatNumber';
import getTaxName from '../utils/getTaxName';

const markUP = 0.31;

const TableCabins = ({ t, rates, onHandleClickReservar }) => {
  const markup = price => {
    const newprice = price - price * markUP;
    return Math.round(newprice);
  };

  const [show, setShow] = useState(false);
  const [showTaxes, setShowTaxes] = useState(false);
  const [politicas, setPoliticas] = useState([]);
  const [taxes] = useState([]);

  const handleClose = () => setShow(false);
  const handleCloseTaxes = () => setShowTaxes(false);
  const [countShow, setCountShow] = useState(3);
  const [texto, setTexto] = useState(t('VerMas'));

  const onHandleClickPolitica = item => {
    // console.log(item)
    // setPoliticas(item.cancellationPolicies);
    setPoliticas(item.description);

    setShow(true);
  };

  const showAll = () => {
    if (countShow === 1000) {
      setTexto(t('VerMas'));
      setCountShow(3);
    } else {
      setTexto(t('VerMenos'));
      setCountShow(1000);
    }
  };
  const getBeds = array => {
    let bed = '';
    for (let i = 0; i < array.length; i++) {
      if (array[i].indexOf('bed') > 0 || array[i].indexOf('beds') > 0) {
        bed = bed !== '' ? bed : array[i];
      }
    }
    return bed;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('detalles')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>{t('descripcion')}</th>
              </tr>
            </thead>
            <tbody>
              {politicas.map((pol, i) => (
                <tr key={i}>
                  <td>{pol}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      <Modal show={showTaxes} onHide={handleCloseTaxes} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('Impuestos')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>{t('Nombre')}</th>
                <th className="text-center">{t('Incluido')}</th>
                <th className="text-center">{t('Precio')}</th>
                <th>{t('Descripcion')}</th>
              </tr>
            </thead>
            <tbody>
              {taxes.map((tax, i) => (
                <tr
                  key={i}
                  className={tax.included ? 'text-success' : 'text-danger'}
                >
                  <td>{getTaxName(tax.name)}</td>
                  <td className="text-center">
                    {tax.included ? t('Si') : 'No'}
                  </td>
                  <td className="text-center">
                    {formatNumber(Math.round(parseInt(tax.amount, 10)))} USD
                  </td>
                  <td>{tax.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Table hover striped bordered responsive className="mt-3 table-rates">
        <thead>
          <tr>
            <th>{t('Detalle')}</th>
            <th className="text-center">{t('Precio')}</th>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th />
          </tr>
        </thead>
        <tbody>
          {rates.map(
            (item, i) =>
              i < countShow && (
                <tr key={i}>
                  <td width="50%">
                    <label className="ft-14 d-block mb-0 text-secondary font-weight-bold">
                      {item.text}
                    </label>
                    <label className="text-secondary">
                      {getBeds(item.description)}
                    </label>
                    {/* 
                    <AmenitiesRoomDetalle
                      amenities={item.roomDescription[0].amenities}
                    /> */}
                  </td>
                  <td className="text-center" width="20%">
                    <div className="text-success font-weight-bold">
                      {markup(item.price)} USD
                      {/* <FaInfoCircle
                        className="text-info icon-18 mb-1 ml-2"
                        onClick={() => onHandleClickTaxes(item)}
                      /> */}
                    </div>
                  </td>
                  <td width="30%" className="text-center">
                    <div>
                      <Button
                        variant="outline-primary"
                        className="font-weight-bold d-block m-auto px-5"
                        onClick={() => onHandleClickReservar(item)}
                      >
                        {t('Reservar')}
                      </Button>
                      <small>
                        <Button
                          variant="link"
                          size="sm"
                          className="font-weight-bold  text-danger"
                          onClick={() => onHandleClickPolitica(item)}
                        >
                          <BsEyeFill /> {t('VerDetalles')}
                        </Button>
                      </small>
                    </div>
                  </td>
                </tr>
              ),
          )}
        </tbody>
        {rates.length > 3 && (
          <tfoot>
            <tr>
              <td colSpan="4" className="text-center">
                <Button
                  variant="light"
                  size="sm"
                  className="font-weight-bold"
                  onClick={showAll}
                >
                  {texto}
                </Button>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
    </>
  );
};

export default withTranslation()(TableCabins);
