import React from 'react';

import PropTypes from 'prop-types';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import styles from './Counter.module.scss';

const Counter = ({ name, value, inc, min, max, onChange }) => {
  return (
    <InputGroup size="sm" className={styles.counter}>
      <InputGroup.Prepend>
        <Button
          variant="outline-primary"
          onClick={() => onChange(value - inc)}
          disabled={value <= min}
        >
          -
        </Button>
      </InputGroup.Prepend>

      <FormControl name={name} value={value} disabled />

      <InputGroup.Append>
        <Button
          variant="outline-primary"
          onClick={() => onChange(value + inc)}
          disabled={value >= max}
        >
          +
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
};

Counter.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  inc: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
};

Counter.defaultProps = {
  inc: 1,
  min: 1,
  max: 100,
  onChange: null,
};

export default Counter;
