import React, { useState } from 'react';

import { Field, Formik } from 'formik';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import useAppTranslation from '../../hook/useAppTranslation';
import useDev from '../../hook/useDev';
import SelectDestination from '../../shared/SelectDestination';
import { encode } from '../../utils/uriComponent';
import { filterSearchSchema } from '../../validations/restaurants';

const FiltersForm = ({ inline, filters }) => {
  const { isDev } = useDev();
  const { t } = useAppTranslation();
  const history = useHistory();

  const [initialValues] = useState(() => {
    if (filters) return filters;

    return { destiny: null };
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={filterSearchSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        const newQuery = encode(values);

        history.push(`/restaurants/list/${newQuery}`);
      }}
    >
      {({ handleSubmit, ...formikBag }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Container className="form-padding">
            <Row>
              <Col xs="12" lg={inline ? 12 : 4}>
                <Field name="destiny">
                  {({ field, meta }) => {
                    // console.log('🚀 ~ FiltersForm.jsx', { field, meta, form });
                    const { touched, error } = meta;

                    return (
                      <Form.Group controlId={field.name}>
                        <Form.Label className="text-light">
                          {t('RestaurantsDestiny')}
                        </Form.Label>

                        <SelectDestination
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          isValid={touched && !error}
                          isInvalid={touched && !!error}
                        />

                        {touched && error && (
                          <Form.Control.Feedback type="invalid">
                            {t(error)}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    );
                  }}
                </Field>
              </Col>
            </Row>

            <Row>
              <Col xs="12" lg={inline ? 12 : { span: 2, offset: 10 }}>
                <Button
                  type="submit"
                  block
                  variant="outline-primary"
                  className="font-weight-bold"
                >
                  {t('Buscar')}
                </Button>
              </Col>
            </Row>

            {isDev && (
              <Row className="mt-3">
                <Col xs="12">
                  <pre style={{ color: '#fff' }}>
                    {JSON.stringify(
                      {
                        values: formikBag.values,
                        touched: formikBag.touched,
                        errors: formikBag.errors,
                      },
                      null,
                      2,
                    )}
                  </pre>
                </Col>
              </Row>
            )}
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default FiltersForm;
