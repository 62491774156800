import React from 'react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

export default ({ rating }) => {
  const arr = [1, 2, 3, 4, 5];
  return (
    <ul className="list-inline mb-0">
      {arr.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className="list-inline-item" key={i}>
          {item <= parseInt(rating, 10) ? (
            <AiFillStar className="stars-on" />
          ) : (
            <AiOutlineStar />
          )}
        </li>
      ))}
    </ul>
  );
};
