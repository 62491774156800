import React from 'react';

import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdSupervisorAccount, MdTimer } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

const values = {};
const touched = {};
const errors = {};
const handleChange = () => {};
const handleBlur = () => {};

const RestaurantBooking = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleMakeBooking = () => {
    history.push('/');
  };

  return (
    <Row>
      <Col xs="12">
        <h2>{t('RestaurantBookingInformation')}</h2>
      </Col>

      <Col xs="12">
        <Card className="shadow-sm">
          <Card.Body>
            <Row>
              <Col xs="12">
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>{t('RestaurantFullNameHolder')}</Form.Label>

                  <Form.Control
                    placeholder={t('RestaurantFullNameHolder')}
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.name && !errors.name}
                    isInvalid={touched.name && !!errors.name}
                  />

                  {touched.name && errors.name && (
                    <Form.Control.Feedback type="invalid">
                      Error
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col xs="12" lg="4">
                <Form.Group className="mb-3" controlId="bookingDay">
                  <Form.Label>{t('RestaurantBookingDay')}</Form.Label>

                  <Form.Control
                    placeholder={t('RestaurantBookingDay')}
                    type="date"
                    name="bookingDay"
                    value={values.bookingDay}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.bookingDay && !errors.bookingDay}
                    isInvalid={touched.bookingDay && !!errors.bookingDay}
                  />

                  {touched.bookingDay && errors.bookingDay && (
                    <Form.Control.Feedback type="invalid">
                      Error
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col xs="12" lg="4">
                <Form.Group className="mb-3" controlId="reservationTime">
                  <Form.Label className="d-flex align-items-center">
                    <MdTimer className="mr-1" />
                    {t('RestaurantBookingTime')}
                  </Form.Label>

                  <Form.Control
                    placeholder={t('RestaurantBookingTime')}
                    type="time"
                    name="reservationTime"
                    value={values.reservationTime}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.reservationTime && !errors.reservationTime}
                    isInvalid={
                      touched.reservationTime && !!errors.reservationTime
                    }
                  />

                  {touched.reservationTime && errors.reservationTime && (
                    <Form.Control.Feedback type="invalid">
                      Error
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col xs="12" lg="4">
                <Form.Group className="mb-3" controlId="reservedFor">
                  <Form.Label className="d-flex align-items-center">
                    <MdSupervisorAccount className="mr-1" />
                    {t('RestaurantReservedFor')}
                  </Form.Label>

                  <Row>
                    <Col xs="6">
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="reservedForAdults"
                      >
                        <Form.Label column xs="6">
                          {t('RestaurantReservedForAdults')}
                        </Form.Label>

                        <Col xs="6">
                          <Form.Control
                            placeholder={t('RestaurantReservedForAdults')}
                            type="number"
                            name="reservedForAdults"
                            value={values.reservedForAdults}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={
                              touched.reservedForAdults &&
                              !errors.reservedForAdults
                            }
                            isInvalid={
                              touched.reservedForAdults &&
                              !!errors.reservedForAdults
                            }
                          />
                        </Col>

                        <Col xs="12">
                          {touched.reservedForAdults &&
                            errors.reservedForAdults && (
                              <Form.Control.Feedback type="invalid">
                                Error
                              </Form.Control.Feedback>
                            )}
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col xs="6">
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="reservedForChildren"
                      >
                        <Form.Label column xs="6">
                          {t('RestaurantReservedForChildren')}
                        </Form.Label>

                        <Col xs="6">
                          <Form.Control
                            placeholder={t('RestaurantReservedForChildren')}
                            type="number"
                            name="reservedForChildren"
                            value={values.reservedForChildren}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={
                              touched.reservedForChildren &&
                              !errors.reservedForChildren
                            }
                            isInvalid={
                              touched.reservedForChildren &&
                              !!errors.reservedForChildren
                            }
                          />
                        </Col>

                        <Col xs="12">
                          {touched.reservedForChildren &&
                            errors.reservedForChildren && (
                              <Form.Control.Feedback type="invalid">
                                Error
                              </Form.Control.Feedback>
                            )}
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>

              <Col xs="12" className="d-flex justify-content-end">
                <Button variant="outline-primary" onClick={handleMakeBooking}>
                  {t('RestaurantMakeBooking')}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default RestaurantBooking;
