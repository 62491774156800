import React, { useCallback, useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import AmenitiesDetail from '../../components/AmenitiesDetail';
import CarouselTopDetail from '../../components/CarouselTopDetail';
import DescripcionDetail from '../../components/DescripcionDetail';
import ErrorServer from '../../components/ErrorServer';
import ExtraInformation from '../../components/ExtraInformation';
import EmptyData from '../../components/Hotels/EmptyData';
import FormSearch from '../../components/Hotels/FormSearch';
import RoomsDetail from '../../components/Hotels/RoomsDetail';
import MapSidebar from '../../components/MapSidebar';
import SectionTop from '../../components/SectionTop';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import CargandoOpciones from '../../loader/CargandoOpciones';
import { useAuth } from '../../providers/AuthProvider';
import { getHotel } from '../../services/hotels';
import FormSidebar from '../../shared/FormSidebar';
import { scrollUp } from '../../utils/scroll';
import sleep from '../../utils/sleep';
import { encode } from '../../utils/uriComponent';

const Detail = () => {
  const { query, hotelId } = useAppParams();
  const { t, language } = useAppTranslation();
  const { isDemo } = useAuth();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [hotel, setHotel] = useState(null);
  const [errorServer, setErrorServer] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [errorStatus, setErrorStatus] = useState(null);

  useEffect(() => {
    let didCancel = false;
    const fetch = async () => {
      await sleep(500);
      if (didCancel || !query) return;

      setLoading(true);
      setHotel(null);
      setErrorServer(false);
      setErrorData([]);
      setErrorStatus(null);

      const response = await getHotel({
        id: hotelId,
        destiny: query?.destiny,
        checkIn: query?.checkIn,
        checkOut: query?.checkOut,
        // currency: null,
        language,
        rooms: query?.rooms,
        isDemo,
      });

      if (didCancel) return;

      if (response.error) {
        setErrorServer(true);
        setErrorData([{}]);
        setErrorStatus({ status: response.status });

        setLoading(false);
        scrollUp();
        return;
      }

      setHotel(response);
      setLoading(false);
      scrollUp();
    };

    fetch();
    return () => {
      didCancel = true;
    };
  }, [isDemo, language, query, hotelId]);

  const handleBookingClick = useCallback(
    ({ room, rate }) => {
      const nights = hotel.checkOut.diff(hotel.checkIn, 'days');
      const { guests, guestsAdults, guestsChildren } = query.rooms.reduce(
        (acc, it) => {
          if (!it) return acc;

          const adults = it.adults ?? 0;
          const children = (it.children?.ages ?? []).length;

          acc.guests += adults + children;
          acc.guestsAdults += adults;
          acc.guestsChildren += children;

          return acc;
        },
        {
          guests: 0,
          guestsAdults: 0,
          guestsChildren: 0,
        },
      );

      // console.log('🚀 ~ Detail.jsx', { query });
      const rateData = {
        checkIn: hotel.checkIn,
        checkOut: hotel.checkOut,
        destino_id: query.destiny,
        hotelId: hotel.id,
        hotelName: hotel.name,
        hotelPhotos: hotel.photos,
        stars: hotel.stars,
        address: hotel.address,
        roomId: rate.rateKey,
        roomName: room.name,
        total: rate.total,
        nights,
        totalByNight: rate.total / nights,
        //   mealPlan: rate.mealPlan,
        rooms: room.roomDescription,
        guests,
        guestsAdults,
        guestsChildren,
        breakfast: hotel.amenities.some(
          it => it.name.includes('desayuno') || it.name.includes('breakfast'),
        ),
        parking: hotel.amenities.some(
          it =>
            it.name.includes('estacionamiento') || it.name.includes('parking'),
        ),
      };

      const encodedRate = encode(rateData);
      history.push(`/hotels/booking/${encodedRate}`);
    },

    [query, hotel, history],
  );
  if (loading) return <CargandoOpciones hideLooking />;

  if (errorServer)
    return (
      <Container>
        <ErrorServer errorData={errorData} errorStatus={errorStatus} />
      </Container>
    );

  if (!hotel) return <EmptyData />;

  return (
    <>
      <SectionTop
        menu={t('Hoteles_one')}
        destino={query.destiny.label}
        nombre={hotel.name}
        direccion={hotel.address}
      />

      <Container fluid className="my-3">
        <Row>
          <Col xs="12" lg="3">
            <Row>
              <Col xs="12" lg="12">
                <FormSidebar>
                  <FormSearch inline disabled filters={query} />
                </FormSidebar>
              </Col>

              {hotel.position && (
                <Col xs="12" lg="12">
                  <Row className="justify-content-center my-4">
                    <Col xs="12" lg="10">
                      <MapSidebar
                        items={[hotel]}
                        position={0}
                        positionsList={[hotel.position]}
                        center={hotel.position}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>

          <Col xs="12" lg="9">
            {errorServer ? (
              <ErrorServer errorData={errorData} errorStatus={errorStatus} />
            ) : (
              <>
                <CarouselTopDetail
                  photos={hotel.photos}
                  numberCards={5}
                  imgHeight={260}
                />

                <DescripcionDetail description={hotel.description} />
                <RoomsDetail
                  rooms={hotel.rooms}
                  onBookingClick={handleBookingClick}
                />
                <ExtraInformation extraInformation={hotel.extraInformation} />
                <AmenitiesDetail amenities={hotel.amenities} />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Detail;
