import React, { useState } from 'react';

import { Image, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../css/SliderHome.css';
import black from '../img/slides/black.jpg';
import platinum from '../img/slides/platinum.jpg';
import { useAuth } from '../providers/AuthProvider';
import SearchAutos from './Cars/FormSearch';
import SearchVuelos from './Flights/FormSearch';
import SearchRestaurants from './Restaurants/FiltersForm';
import SearchExperiencias from './SearchExperiencias';
import SearchCruises from './cruiseShips/FormSearch';
import SearchHoteles from './Hotels/FormSearch';
import SearchSportingEvents from './sportingEvents/FormSearch';

const SliderHome = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isPlatinum } = useAuth();
  const [key, setKey] = useState('hoteles');

  if (key === 'RitzCarlton') {
    history.push(`/ritzCarlton/listRiz`);
  }

  return (
    <div className="slider_area">
      <div className="slider-wrapper">
        <Image src={isPlatinum ? platinum : black} fluid />
      </div>

      <div className="box-search">
        <Tabs
          defaultActiveKey="vuelos"
          className="justify-content-center"
          transition={false}
          activeKey={key}
          onSelect={k => setKey(k)}
        >
          <Tab eventKey="hoteles" title={t('Hoteles')}>
            <SearchHoteles />
          </Tab>
          <Tab eventKey="experiencias" title={t('Experiencias')}>
            <SearchExperiencias />
          </Tab>
          <Tab eventKey="Cruceros" title={t('Cruceros')}>
            <SearchCruises />
          </Tab>
          <Tab eventKey="vuelos" title={t('Vuelos')}>
            <SearchVuelos />
          </Tab>
          <Tab eventKey="autos" title={t('Cars')}>
            <SearchAutos />
          </Tab>
          <Tab eventKey="sportingEvents" title={t('Eventos Deportivos')}>
            <SearchSportingEvents />
          </Tab>
          <Tab eventKey="restaurants" title={t('Restaurants')}>
            <SearchRestaurants />
          </Tab>
          {/*  <Tab eventKey="RitzCarlton" title="Ritz Carlton" /> */}
        </Tabs>
      </div>
    </div>
  );
};

export default SliderHome;
