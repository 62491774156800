import { CRUISE_LIST, CRUISE_SEARCH_PARAMS } from './Routes';
import instance from './instance';

const sources = process.env.REACT_APP_SUNAPI_API_NAME_CRUISES;

export const getCruises = async ({ area, cruiseLine, duration, ship }) => {
  // console.log('🚀 ~ Cruises.js', { area, cruiseLine, duration, ship });
  const request = instance.get(CRUISE_LIST, {
    params: {
      sources,
      area,
      cruiseline: cruiseLine,
      duration,
      ship,
    },
  });

  const response = request
    .then(res => {
      // console.log('🚀 ~ Cruises.js', { res });
      if (res?.data?.errors?.length) throw res.data.errors;

      return res.data;
    })
    .catch(() => []);

  return response;
};

export const getParams = async ({ area, cruiseLine } = {}) => {
  // console.log('🚀 ~ Cruises.js', { area, cruiseline: cruiseLine });
  const request = instance.get(CRUISE_SEARCH_PARAMS, {
    params: { sources, area, cruiseline: cruiseLine },
  });

  const response = request.then(res => res?.data).catch(() => ({}));

  return response;
};
