import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import LightboxComponent from 'react-image-lightbox';
import sleep from '../utils/sleep';

const isIndexValid = ({ arr, index }) => {
  if (!Array.isArray(arr)) return false;
  if (index < 0 || index >= arr.length) return false;

  return true;
};

const Lightbox = ({ show, onClose, index, images, title }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, nextIndex] = useMemo(() => {
    if (!Array.isArray(images)) return [0, 0];
    if (!isIndexValid({ arr: images, index: currentIndex }))
      return [images.length - 1, 0];

    return [
      (currentIndex + images.length - 1) % images.length,
      (currentIndex + 1) % images.length,
    ];
  }, [currentIndex, images]);

  useEffect(() => {
    let didCancel = false;
    const set = async () => {
      await sleep(500);
      if (didCancel) return;

      setCurrentIndex(index);
    };

    set();
    return () => {
      didCancel = true;
    };
  }, [index]);

  const handleCloseRequest = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleMovePrevRequest = () => {
    if (isIndexValid({ arr: images, index: prevIndex })) {
      setCurrentIndex(prevIndex);
    }
  };

  const handleMoveNextRequest = () => {
    if (isIndexValid({ arr: images, index: nextIndex })) {
      setCurrentIndex(nextIndex);
    }
  };

  if (!show || !isIndexValid({ arr: images, index: currentIndex }))
    return <div />;

  return (
    <LightboxComponent
      mainSrc={images[currentIndex]}
      prevSrc={images[prevIndex]}
      nextSrc={images[nextIndex]}
      imageTitle={title}
      onCloseRequest={handleCloseRequest}
      onMovePrevRequest={handleMovePrevRequest}
      onMoveNextRequest={handleMoveNextRequest}
    />
  );
};

Lightbox.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  index: PropTypes.number,
  images: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

Lightbox.defaultProps = {
  show: false,
  onClose: null,
  index: -1,
  images: [],
  title: null,
};

export default Lightbox;
