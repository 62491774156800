import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IoAirplane } from 'react-icons/io5';
import styles from './CardItineraryHeader.module.css';

const CardItineraryHeader = ({ isDeparture = false, itinerary }) => {
  const { t } = useTranslation();
  const [{ departure }] = itinerary.segments;
  const { arrival } = itinerary.segments[itinerary.segments.length - 1];

  return (
    <Row xs="1" lg="3" className={styles.container}>
      <Col className={styles.identifier}>
        <Row xs="1" className="m-0">
          <Col>
            <h1 className="text-dark d-flex justify-content-start align-items-center">
              <IoAirplane className={isDeparture ? '' : styles.rotate} />
              <span className="ml-2">
                {t(isDeparture ? 'FlightLeavingFrom' : 'FlightGoingTo')}
              </span>
            </h1>
          </Col>

          <Col className="pl-5">
            <span className="text-dark pl-3">
              {t('FlightLongDate', {
                date: itinerary.departingAt,
              })}
            </span>
          </Col>
        </Row>
      </Col>

      <Col>
        <Row xs="1" className="m-0">
          <Col>
            <h1 className="text-dark d-flex justify-content-start align-items-center">
              {departure.terminal.codeCity}
            </h1>
          </Col>

          <Col>
            <span className="text-dark">{departure.terminal.name}</span>
          </Col>
        </Row>
      </Col>

      <Col>
        <Row xs="1" className="m-0">
          <Col>
            <h1 className="text-dark d-flex justify-content-start align-items-center">
              {arrival.terminal.codeCity}
            </h1>
          </Col>

          <Col>
            <span className="text-dark">{arrival.terminal.name}</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CardItineraryHeader;
