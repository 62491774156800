import React, { useEffect, useState } from 'react';

import Pagination from 'rc-pagination';
import { Col, Container, Row } from 'react-bootstrap';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import CardExperienciasHorizontal from '../components/CardExperienciasHorizontal';
import EmptyItems from '../components/EmptyItems';
import ErrorServer from '../components/ErrorServer';
import FilterSidebar from '../components/FilterSidebar';
import FormExperienceSidebar from '../components/FormExperienceSidebar';
import FormSearchExperiencias from '../components/FormSearchExperiencias';
import SectionTop from '../components/SectionTop';
import useAppTranslation from '../hook/useAppTranslation';
import useQuery from '../hook/useQuery';
import CargandoOpciones from '../loader/CargandoOpciones';
import { TOUR_LIST } from '../services/Routes';
import post from '../services/post';
import getCadena from '../utils/getCadena';
import getPrecio from '../utils/getPrecio';

const ExperienciasListado = () => {
  const { t, language } = useAppTranslation();
  const history = useHistory();
  const query = useQuery();
  const [data, setData] = useState(JSON.parse(query.get('search')));
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsFilter, setItemsFilter] = useState([]);
  const [minPrecio, setMinPrecio] = useState(0);
  const [maxPrecio, setMaxPrecio] = useState(100);
  const [errorServer, setErrorServer] = useState(false);
  const [errorData, setErrorData] = useState(null);
  const [total, setTotal] = useState(0);
  const [valuePrecio, setValuePrecio] = useState({ min: 1, max: 100 });
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 20;

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  useEffect(() => {
    setTotal(0);
    window.scrollTo(0, 0);
    if (data) {
      data.language = language;
      setData(data);
      // console.log(data)
      setErrorServer(false);
      setLoading(true);
      const urlHotelList = `${TOUR_LIST}?sources=${process.env.REACT_APP_SUNAPI_API_NAME_TOUR}`;
      post({
        url: urlHotelList,
        data,
      })
        .then(response => {
          // console.log(response);
          if (
            response.data.errors.length > 0 &&
            response.data.activities.length === 0
          ) {
            setItems([]);
            setItemsFilter([]);
            setErrorData(response.data.errors);
            setErrorServer(true);
          } else {
            setErrorServer(false);
            setTotal(
              response.data.activities.filter(elem => elem.name !== null)
                .length,
            );
            setItems(
              response.data.activities.filter(elem => elem.name !== null),
            );
            setItemsFilter(
              response.data.activities.filter(elem => elem.name !== null),
            );
            const arr = response.data.activities.map(el => {
              return el.minRates.adult;
            });
            if (
              response.data.activities.filter(elem => elem.name !== null)
                .length > 0
            ) {
              setMinPrecio(Math.floor(Math.min(...arr)));
              setMaxPrecio(Math.floor(Math.max(...arr)));
              setValuePrecio({
                min: Math.floor(Math.min(...arr)),
                max: Math.floor(Math.max(...arr)),
              });
            }
          }
          setLoading(false);
        })
        .catch(() => {
          // console.log(error)
          setItems([]);
          setItemsFilter([]);
          setErrorServer(true);
          setLoading(false);
        });
    }
  }, [language]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchList = (valor, typeFilter) => {
    if (typeFilter === 'nombre') {
      if (valor !== '') {
        const arr = [...items];
        setItemsFilter(
          arr.filter(elem =>
            getCadena(elem.name).includes(valor.toLowerCase()),
          ),
        );
      } else {
        setItemsFilter(items);
      }
    }
    if (typeFilter === 'precio') {
      const { min } = valor;
      const { max } = valor;
      const arr = [...items];
      setItemsFilter(
        arr.filter(
          elem =>
            getPrecio(elem.minRates.adult) >= min &&
            getPrecio(elem.minRates.adult) <= max,
        ),
      );
    }
  };

  const sendData = newData => {
    setTotal(0);
    window.scrollTo(0, 0);
    setLoading(true);
    setData(newData);
    const urlHotelList = `${TOUR_LIST}?sources=${process.env.REACT_APP_SUNAPI_API_NAME_TOUR}`;
    post({
      url: urlHotelList,
      data: newData,
    })
      .then(response => {
        // console.log(response)
        if (
          response.data.errors.length > 0 &&
          response.data.activities.length === 0
        ) {
          setItems([]);
          setItemsFilter([]);
          setErrorData(response.data.errors);
          setErrorServer(true);
        } else {
          setErrorServer(false);
          setTotal(
            response.data.activities.filter(elem => elem.name !== null).length,
          );
          setItems(response.data.activities.filter(elem => elem.name !== null));
          setItemsFilter(
            response.data.activities.filter(elem => elem.name !== null),
          );
          const arr = response.data.activities.map(el => {
            return el.minRates.adult;
          });
          if (
            response.data.activities.filter(elem => elem.name !== null).length >
            0
          ) {
            setMinPrecio(Math.min(...arr));
            setMaxPrecio(Math.max(...arr));
            setValuePrecio({
              min: Math.min(...arr),
              max: Math.max(...arr),
            });
          }
        }
        setLoading(false);
      })
      .catch(() => {
        // console.log(error)
        setItems([]);
        setItemsFilter([]);
        setErrorServer(true);
        setLoading(false);
      });
  };

  const onHandleClickCard = (id, source) => {
    data.apiName = source;
    data.chain = '';
    data.id = id;
    // console.log(data)
    window.open(
      `/experience/detail?search=${encodeURIComponent(JSON.stringify(data))}`,
      '_blank',
    );
    // history.push(`/experience/detail?search=${encodeURIComponent(JSON.stringify(data))}`)
  };

  if (loading) return <CargandoOpciones />;

  if (!data)
    return <FormSearchExperiencias history={history} sendData={sendData} />;

  return (
    <>
      <SectionTop
        menu={t('Experiencias')}
        destino={data?.destino_name?.[language] ?? data.destino_name.label}
        total={total}
        product={t('Experiencias')}
      />

      <Container fluid className="mt-3">
        <Row>
          <Col xs="12" lg="4">
            <Row>
              <Col xs="12" lg="12">
                <FormExperienceSidebar
                  searching={data}
                  sendData={sendData}
                  showDestiny
                />
              </Col>
              <Col xs="12" lg="12">
                {items.length > 0 && (
                  <FilterSidebar
                    searchList={searchList}
                    mPrecio={minPrecio}
                    mxPrecio={maxPrecio}
                    valuePrecio={valuePrecio}
                    showStars={false}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg="8">
            {itemsFilter.length === 0 && !errorServer && <EmptyItems />}
            {errorServer && <ErrorServer errorData={errorData} />}
            {itemsFilter
              .slice(indexOfFirstPost, indexOfLastPost)
              .map((item, i) => (
                <CardExperienciasHorizontal
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  item={item}
                  fechaStart={data.checkIn}
                  onHandleClickCard={onHandleClickCard}
                />
              ))}
            <div>
              {itemsFilter.length > 0 && (
                <Pagination
                  onChange={page => setCurrentPage(page)}
                  current={currentPage}
                  total={itemsFilter.length}
                  showLessItems
                  showTitle
                  pageSize={postsPerPage}
                  className="pagination"
                  prevIcon={<BsChevronLeft />}
                  nextIcon={<BsChevronRight />}
                  jumpPrevIcon={<BiDotsHorizontalRounded />}
                  jumpNextIcon={<BiDotsHorizontalRounded />}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ExperienciasListado;
