import React from 'react';

import { useTranslation } from 'react-i18next';
import FormSearch from '../../components/Hotels/FormSearch';
import FiltersContainer from '../../shared/FiltersContainer';

const Hotels = () => {
  const { t } = useTranslation();

  return (
    <FiltersContainer title={t('Hoteles')}>
      <FormSearch />
    </FiltersContainer>
  );
};

export default Hotels;
