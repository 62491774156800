import React from 'react';

import { Card as CardBootstrap, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InputRange from 'react-input-range';
import styled from 'styled-components';
import PriceFormat from '../PriceFormat';
import Text from '../Text';

const FilterSidebar = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  // console.log('🚀 ~ FilterSidebar.js', { filters });

  const formatLabel = value => (
    <PriceFormat currency={filters?.price?.currency || 'MXN'} value={value}>
      {valueText => (
        <Text bold size="17" lineHeight="17" color="#406d9f">
          {valueText}
        </Text>
      )}
    </PriceFormat>
  );

  return (
    <Row className="mt-3 mb-3 justify-content-md-center">
      <Col xs="12" lg="10">
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Text
                  as="div"
                  className="float-left"
                  bold
                  size="24"
                  lineHeight="29"
                  color="#003C74"
                >
                  {t('FiltersPopular')}
                </Text>
              </Col>
            </Row>

            <Row>
              <Col className="py-5">
                <FilterText className="mb-5">{t('Precio')}</FilterText>

                <InputRange
                  minValue={filters?.price?.min || 0}
                  maxValue={filters?.price?.max || 1}
                  formatLabel={formatLabel}
                  value={filters?.price?.value || { min: 0, max: 0 }}
                  onChange={value =>
                    setFilters({
                      ...(filters || {}),
                      price: { ...(filters?.price || {}), value },
                    })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <FilterText>{t('Aerolineas')}</FilterText>
                <div key="default-checkbox" className="mb-3">
                  {filters?.airlines?.map(({ id, label, checked }, index) => (
                    <Form.Check
                      key={id}
                      type="checkbox"
                      id={id}
                      label={label}
                      checked={checked}
                      onChange={evt => {
                        const airlines = [...(filters?.airlines || [])];
                        airlines[index].checked = evt.target.checked;

                        setFilters({ ...filters, airlines });
                      }}
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

const Card = styled(CardBootstrap)`
  & {
    border: 0px !important;
  }
`;

const FilterText = styled(Text).attrs(() => ({
  bold: true,
  size: 24,
  lineHeight: 29,
  color: '#565f73',
}))`
  display: inline-block;
  margin-bottom: 0.5rem;
`;

export default FilterSidebar;
