import React, { useState } from 'react';

import moment from 'moment';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import '../css/DatePicker.css';
import DatePicker from './DatePicker';
import SelectDestinations from './SelectDestinations';
import SelectPerson from './SelectPerson';

const SearchExperiencias = () => {
  const { t, i18n } = useTranslation();
  const [destino, setDestino] = useState(null);
  const [startDate, setStartDate] = useState(moment().add(1, 'days'));
  const history = useHistory();
  const [adult, setAdult] = useState(1);
  const [child, setChild] = useState(0);
  const [errorDestino, setErrorDestino] = useState(false);

  const onHandleClickBuscar = () => {
    if (destino === null) {
      setErrorDestino(true);
    } else {
      const d = {
        destiny: destino === null ? null : destino.value,
        destino_name: {
          label: destino?.label ?? '',
          es: destino?.es ?? null,
          en: destino?.en ?? null,
        },
        checkIn: moment(startDate).format('DD-MM-YYYY'),
        currency: 'USD',
        language: i18n.language,
        apiName: process.env.REACT_APP_SUNAPI_API_NAME_TOUR,
        participants: adult + child,
        paxes: {
          adults: adult,
          children: child,
        },
      };
      // console.log(d)
      history.push(
        `/experience?search=${encodeURIComponent(JSON.stringify(d))}`,
      );
    }
  };

  return (
    <Container className="form-padding">
      <Row className="align-items-center">
        <Col xs="12" lg="4">
          <Form.Group>
            <Form.Label className="text-light">{t('FlightGoingTo')}</Form.Label>

            <SelectDestinations
              value={destino}
              onChange={value => setDestino(value)}
            />

            {errorDestino && (
              <Form.Control.Feedback type="invalid">
                {t('CampoRequerido')}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col xs="4" lg="2">
          <Form.Group>
            <Form.Label className="text-light">{t('Fecha')}</Form.Label>

            <DatePicker
              name="startDateExp"
              id="start_date_exp_id"
              date={startDate}
              onDateChange={startDateExp => {
                // console.log("🚀 ~ FormSearchExperiencias.js", { startDateExp })
                if (startDateExp === null) {
                  setStartDate(moment().add(1, 'days'));
                } else {
                  setStartDate(startDateExp);
                }
              }}
            />
          </Form.Group>
        </Col>
        <Col xs="4" lg="2">
          <Form.Group>
            <Form.Label className="text-light">{t('Adults')}</Form.Label>
            <SelectPerson adult child={false} setAdult={setAdult} text="1" />
          </Form.Group>
        </Col>
        <Col xs="4" lg="2">
          <Form.Group>
            <Form.Label className="text-light">{t('Ninos')}</Form.Label>
            <SelectPerson adult={false} child setChild={setChild} text="0" />
          </Form.Group>
        </Col>
        <Col xs="12" lg="2">
          <Button
            block
            variant="outline-primary"
            onClick={onHandleClickBuscar}
            className="font-weight-bold"
          >
            {t('Buscar')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SearchExperiencias;
