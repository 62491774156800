import React from 'react';

import debounce from 'just-debounce-it';
import { useTranslation } from 'react-i18next';
import get from '../services/get';
import AsyncSelect from '../shared/AsyncSelect';

const SelectAjax = ({
  loadOptions: cb,
  url,
  className,
  valid,
  defaultValue,
  isMulti,
  defaultOptions,
  handleChange,
  value,
  isClearable,
  params,
  name,
  handleBlur,
}) => {
  const { t } = useTranslation();

  const loadOptions = debounce((inputValue, callback) => {
    if (cb) {
      cb(inputValue, callback);
      return;
    }

    if ((inputValue?.length ?? 0) < 3 || !url?.length) {
      callback([]);
      return;
    }

    get(
      url.replace(/\{(.+?)\}/gi, (match, p1) => {
        // console.log({ match, p1 });
        if (p1 === 'inputValue') {
          return inputValue;
        }

        return params?.[p1] ?? match;
      }),
    )
      .then(res => {
        // eslint-disable-next-line no-shadow
        const data = res.data.map(({ id, name, code, state }) => ({
          value: id,
          label: `${name || code}${
            state?.country?.name?.length ? `, ${state.country.name}` : ''
          }`,
        }));

        callback(data);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('🚀 ~ SelectAjax.js', { err });

        callback([]);
      });
  }, 300);

  const customStyles = {
    control: styles => ({ ...styles, borderColor: 'red !important' }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        backgroundColor: 'white!important',
        color: 'black!important',
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  return (
    <AsyncSelect
      className={className}
      styles={!valid && customStyles}
      defaultValue={defaultValue}
      isMulti={isMulti}
      defaultOptions={(Array.isArray(defaultOptions)
        ? defaultOptions
        : []
      ).filter(it => it)}
      value={value}
      loadOptions={loadOptions}
      placeholder={`${t('Buscar')} ...`}
      onChange={handleChange}
      isClearable={isClearable}
      openMenuOnClick={false}
      name={name}
      onBlur={handleBlur}
    />
  );
};

export default SelectAjax;
