import React, { useMemo } from 'react';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import AuthProvider, { useAuth } from './providers/AuthProvider';
import UserProvider from './providers/UserProvider';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from './theme/GlobalStyles';
import black from './theme/black.json';
import platinum from './theme/platinum.json';

const withThemeProvider = Component => () => {
  const { isPlatinum } = useAuth();

  const theme = useMemo(() => {
    if (isPlatinum) return platinum;

    return black;
  }, [isPlatinum]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <Component />
    </ThemeProvider>
  );
};

const AppWithTheme = withThemeProvider(App);

ReactDOM.render(
  <AuthProvider>
    <UserProvider>
      <AppWithTheme />
    </UserProvider>
  </AuthProvider>,

  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
