import React, { useEffect, useMemo, useState } from 'react';

import { Formik } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import CardBookingInfo from '../../components/Flights/Checkout/CardBookingInfo';
import CardBookingPay from '../../components/Flights/Checkout/CardBookingPay';
import CardBookingReview from '../../components/Flights/Checkout/CardBookingReview';
import CardWhoTraveling from '../../components/Flights/Checkout/CardWhoTraveling';
import SectionTop from '../../components/SectionTop';
import useAppParams from '../../hook/useAppParams';
import useAppTranslation from '../../hook/useAppTranslation';
import useDev from '../../hook/useDev';
import CargandoOpciones from '../../loader/CargandoOpciones';
import { getLabelByDestinyId } from '../../services/destinations';
import sleep from '../../utils/sleep';
import { checkoutSchema } from '../../validations/flights';
import styles from './Checkout.module.css';

const Checkout = () => {
  const { isDev } = useDev();
  const { t, language } = useAppTranslation();
  const { query, checkout } = useAppParams();

  const [loading, setLoading] = useState(true);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);

  useEffect(() => {
    let didCancel = false;
    const set = async () => {
      await sleep(500);
      if (didCancel || !query) return;

      setLoading(true);
      setDestination(null);

      const [labelOrigin, labelDestination] = await Promise.all([
        getLabelByDestinyId({
          destinyId: query.origin,
          language,
        }),

        getLabelByDestinyId({
          destinyId: query.destination,
          language,
        }),
      ]);

      setOrigin(labelOrigin);
      setDestination(labelDestination);
      setLoading(false);
    };

    set();
    return () => {
      didCancel = true;
    };
  }, [query, language]);

  const initialValues = useMemo(() => {
    if (!query?.persons?.adults) return {};

    const { adults = 0, children = [] } = query?.persons ?? {};

    const paxesInformation = [
      ...Array.from({ length: adults }),
      ...children,
    ].map((age, index) => {
      const person = {
        type: age < 18 ? 'CHILD' : 'ADULT',
        age,
        name: '',
        lastName: '',
        email: null,
        phone: null,
      };

      if (index === 0) {
        person.email = '';
        person.phone = {
          type: 'HOME',
          number: '',
        };
      }

      return person;
    });

    return {
      paxesInformation,
      payment: checkoutSchema?.fields?.payment?.getDefault?.() ?? {},
    };
  }, [query]);

  if (loading) return <CargandoOpciones />;

  return (
    <>
      <SectionTop menu={t('Vuelos', { count: 1 })} nombre={destination} />

      <Container fluid className="my-3">
        <Row xs="1">
          <Col lg="4">
            <CardBookingInfo
              origin={origin}
              destination={destination}
              query={query}
              checkout={checkout}
            />
          </Col>

          <Col lg="8">
            <Formik
              validationSchema={checkoutSchema}
              initialValues={initialValues}
              onSubmit={(...args) => {
                // eslint-disable-next-line no-console
                console.log('🚀 ~ Checkout.jsx', { args });
              }}
            >
              {({ handleSubmit, values }) => {
                return (
                  <form
                    noValidate
                    onSubmit={handleSubmit}
                    className={styles.container}
                  >
                    <CardWhoTraveling name="paxesInformation" />
                    <CardBookingPay />
                    <CardBookingReview />
                    {isDev && (
                      <pre>
                        {JSON.stringify({ values, query, checkout }, null, 2)}
                      </pre>
                    )}
                  </form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Checkout;
