import React, { useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useCruise from '../../hook/useCruise';
import CargandoOpciones from '../../loader/CargandoOpciones';
import FormSidebar from '../../shared/FormSidebar';
import Pagination from '../PaginationCruises';
import SectionTop from '../SectionTop';
import Text from '../Text';
import CardRizCarlton from './CardCruisebyRizCarlton';
import FormSearch from './FormSearch';
import ModalAmenities from './ModalAmenities';

const LiztRiz = () => {
  const { t } = useTranslation();
  const { query, getRizCarlton } = useCruise();

  const [loading, setLoading] = useState(false);
  const [cruises, setCruises] = useState([]);
  const [showAmenities, setShowAmenities] = useState(false);
  const [amenities] = useState([]);
  const [, setErrorServer] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [, setFilters] = useState(null);

  useEffect(() => {
    // console.log('🚀 ~ ListRiz.js', { query });
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // const source = axios.CancelToken.source();

    setLoading(true);
    setErrorServer(false);
    setCruises([]);
    setFilters(null);

    const fetch = async () => {
      const dataCruise = await getRizCarlton();
      if (dataCruise.length > 1) {
        setCruises(dataCruise);
      }
      setLoading(false);
    };

    fetch();

    return () => {
      // console.log('🚀 ~ ListRiz.js ~ Cancel');
      // source.cancel();
    };
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAmenitiesClose = () => setShowAmenities(false);

  if (loading) return <CargandoOpciones marginTop />;

  return (
    <>
      <ModalAmenities
        show={showAmenities}
        onClose={handleAmenitiesClose}
        amenities={amenities}
      />

      <SectionTop
        menu={t('Cruceros', { count: cruises.length })}
        destino="RITZ CARLTON"
        total={cruises.length || undefined}
        product={t('Cruceros', { count: cruises.length })}
      />

      <Container fluid className="mt-3">
        <Row>
          <Col xs="12" lg="4">
            <Row className="justify-content-md-center">
              <FormSidebar>
                <FormSearch inline />
              </FormSidebar>
            </Row>
            {cruises.length && (
              <Col>
                <br />
                <Row className="mb-5 justify-content-md-center">
                  <Col xs="12" lg="10">
                    <Text
                      size="20"
                      lineHeight="50"
                      color="#03B2E2"
                      className="cursor-pointer"
                    >
                      {t('Xpersona')}
                    </Text>
                    <Col xs="12" lg="10">
                      <br />
                    </Col>
                  </Col>
                </Row>
              </Col>
            )}
          </Col>
          <Col xs="12" lg="8">
            <Pagination
              items={cruises}
              page={currentPage}
              onChange={setCurrentPage}
            >
              {item => <CardRizCarlton item={item} />}
            </Pagination>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LiztRiz;
