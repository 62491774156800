import React from 'react';

import { Card as CardBootstrap, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import styled from 'styled-components';
import Go from '../Go';
import Text from '../Text';

const maxStars = 5;
const Filters = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <Go />
          </Col>
        </Row>
        <Row>
          <Col>
            <Text
              as="div"
              className="float-left"
              bold
              size="24"
              lineHeight="29"
            >
              {t('FiltersPopular')}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col className="py-5">
            <Text as="label" className="mb-5" bold size="24" lineHeight="29">
              {t('Precio')}
            </Text>

            {/* <InputRange
              minValue={83917}
              maxValue={298495}
              formatLabel={formatLabel}
              value={{ min: 15789, max: 150789 }}
              onChange={value =>
                setFilters({
                  ...(filters || {}),
                  price: { ...(filters?.price || {}), value },
                })
              }
              onChangeComplete={value =>
                console.log('🚀 ~ Filters.js', { value })
              }
            /> */}
          </Col>
        </Row>
        <Row>
          <Col>
            <Text as="label" bold size="24" lineHeight="29">
              {t('Estrellas')}
            </Text>
            <div key="default-checkbox" className="mb-3">
              {new Array(maxStars).fill(null).map((_, index) => {
                const stars = maxStars - index;
                const label = [
                  ...new Array(stars).fill(<AiFillStar className="mb-1" />),
                  ...new Array(5 - stars).fill(
                    <AiOutlineStar className="mb-1" />,
                  ),
                ];

                return (
                  <Form.Check
                    key={index}
                    type="checkbox"
                    id={index}
                    label={label}
                    // checked={checked}
                    // onChange={evt => {
                    //   const airlines = [...(filters?.airlines || [])];
                    //   airlines[index].checked = evt.target.checked;

                    //   setFilters({ ...filters, airlines });
                    // }}
                  />
                );
              })}
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <FilterText>{t('Servicios')}</FilterText>
            <div key="default-checkbox" className="mb-3">
              {[
                {},
                {},
              ].fill(null).map((_, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  id={index}
                  label={label}
                  // checked={checked}
                  // onChange={evt => {
                  //   const airlines = [...(filters?.airlines || [])];
                  //   airlines[index].checked = evt.target.checked;

                  //   setFilters({ ...filters, airlines });
                  // }}
                />
              ))}
            </div>
          </Col>
        </Row> */}
      </Card.Body>
    </Card>
  );
};

const Card = styled(CardBootstrap)`
  & {
    border: 0px !important;
  }
`;

export default Filters;
