import React from 'react';

import { useTranslation } from 'react-i18next';
import FiltersContainer from '../../shared/FiltersContainer';
import FormSearch from './FormSearch';

const Home = () => {
  const { t } = useTranslation();

  return (
    <FiltersContainer title={t('Eventos Deportivos')}>
      <FormSearch />
    </FiltersContainer>
  );
};

export default Home;
