import { useCallback, useMemo } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HOTEL_LIST } from '../../services/Routes';
import post from '../../services/post';
import getURL from '../../utils/getURL';
import useAppParams from '../useAppParams';
import useExtraItems from '../useExtraItems';

const sources = `${process.env.REACT_APP_SUNAPI_API_NAME_HOTEL}`;
const urlService = `${HOTEL_LIST}?sources=${sources}`;

const currency = 'USD';

const useHotel = () => {
  const history = useHistory();
  const params = useAppParams();
  const extraItems = useExtraItems('booking-hotel');
  const { i18n } = useTranslation();

  const checkout = useMemo(() => {
    try {
      const item = extraItems.get(params?.id);
      item.checkIn = moment(item.checkIn).local();
      item.checkOut = moment(item.checkOut).local();

      return item;
    } catch {
      return null;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const search = (newQuery, showDestiny) => {
    const param = getURL(newQuery);

    history.push(`/hotels/${showDestiny ? 'list' : 'detail'}/${param}`);
  };

  const searchByHotels = newQuery => {
    const param = getURL(newQuery);
    history.push(`/hotels/list/${param}`);
  };

  const warranty = (id, apiName, chain) => {
    const data = { ...params?.query, apiName, chain, id };
    const param = getURL(data);
    window.open(`/hotels/warranty/${param}`, '_blank');
  };

  const createBody = useCallback(
    language => {
      if (!params?.query) return undefined;

      const apiName = params?.query?.apiName || undefined;
      const chain = params?.query?.chain || undefined;
      const id = params?.query?.id || undefined;

      return {
        id,
        apiName,
        destiny: params?.query.destiny.value,
        checkIn: moment(params?.query.checkIn).format('DD-MM-YYYY'),
        checkOut: moment(params?.query.checkOut).format('DD-MM-YYYY'),
        currency,
        language,
        rooms: params?.query.rooms,
        chain,
      };
    },
    [params?.query],
  );

  const getHotels = async urlHotelByDestiny => {
    const language = i18n.language && i18n.language === 'en' ? 'EN' : 'CAS';
    const body = createBody(language);

    const response = await post({
      url: urlHotelByDestiny ? urlService : '',
      data: body,
    });

    if (response?.data?.errors?.length) throw response.data.errors;
    return response.data;
  };

  const openDetail = ({ id, source: apiName, chain }) => {
    const data = { ...params?.query, apiName, chain, id };

    const param = getURL(data);
    window.open(`/hotels/detail/${param}`, '_blank');
  };

  const openDetailByHotel = query => {
    const param = getURL(query);
    window.open(`/hotels/detail/${param}`, '_blank');
  };

  const makeBook = ({ hotel, room, rate }) => {
    // console.log('🚀 ~ useHotel.js', { hotel, room, rate });
    // return;

    const nights = hotel.checkOut.diff(hotel.checkIn, 'days');
    const { guests, guestsAdults, guestsChildren } = params.query.rooms.reduce(
      (acc, it) => {
        if (!it) return acc;

        const adults = it.adults ?? 0;
        const children = (it.children?.ages ?? []).length;

        acc.guests += adults + children;
        acc.guestsAdults += adults;
        acc.guestsChildren += children;

        return acc;
      },
      {
        guests: 0,
        guestsAdults: 0,
        guestsChildren: 0,
      },
    );
    // console.log('🚀 ~ useHotel.js', { guests, guestsAdults, guestsChildren });
    // return;

    if (hotel && rate) {
      const booking = {
        apiName: params.query.apiName,
        checkIn: hotel.checkIn,
        checkOut: hotel.checkOut,
        destino_id: params.query.destiny,
        hotelId: hotel.id,
        hotelName: hotel.name,
        hotelPhotos: hotel.photos,
        stars: hotel.stars,
        address: hotel.address,
        roomId: rate.rateKey,
        roomName: room.name,
        total: rate.total,
        nights,
        totalByNight: rate.total / nights,
        //   mealPlan: rate.mealPlan,
        rooms: room.roomDescription,
        guests,
        guestsAdults,
        guestsChildren,
        breakfast: hotel.amenities.some(
          it => it.name.includes('desayuno') || it.name.includes('breakfast'),
        ),
        parking: hotel.amenities.some(
          it =>
            it.name.includes('estacionamiento') || it.name.includes('parking'),
        ),
      };

      // console.log('🚀 ~ useHotel.js', { hotel, room, rate, booking });
      // return;

      const id = extraItems.save(booking);

      history.push(`/hotels/booking/${id}`);
    }
  };

  const calculateSavings = (priceJustGo, priceAnother) => {
    const justGo = Number.parseFloat(priceJustGo);
    const another = Number.parseFloat(priceAnother);
    const checkIn = moment(params.query.checkIn);
    const checkOut = moment(params.query.checkOut);
    const nights = checkOut.diff(checkIn, 'days');

    const diff = Math.abs(another - justGo);
    const percent = (diff / another) * 100;

    return {
      percent: Number.parseFloat(percent).toFixed(0),
      saving: Number.parseFloat(diff * nights).toFixed(0),
    };
  };

  return {
    query: params?.query,
    search,
    searchByHotels,
    getHotels,
    createBody,
    openDetail,
    warranty,
    openDetailByHotel,
    checkout,
    makeBook,
    clear: extraItems.clear,
    calculateSavings,
  };
};

export default useHotel;
