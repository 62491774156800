import React, { useMemo, useRef, useState } from 'react';

import { useField } from 'formik';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';

const DISPLAY_FORMAT = {
  es: 'DD/MM/YYYY',
  en: 'MM/DD/YYYY',
};

const useFieldCustom = name => {
  try {
    return useField(name);
  } catch {
    return [];
  }
};

const DatePicker = ({ date, minDate, maxDate, id, name, onDateChange }) => {
  const { i18n } = useTranslation();
  const [, , helpers] = useFieldCustom(name);

  const [focusedInput, setFocusedInput] = useState(null);

  const isValid = dateValid => !!(dateValid && moment(dateValid).isValid());

  const toMidday = dateOld => moment(dateOld).clone().startOf('day').hour(12);

  const isOutsideRange = useMemo(() => {
    if (!minDate && !maxDate) return undefined;
    const minMidday = isValid(minDate) ? toMidday(minDate) : undefined;
    const maxMidday = isValid(maxDate) ? toMidday(maxDate) : undefined;

    return day => {
      const midday = toMidday(day);
      const isBefore = minMidday ? midday.isBefore(minMidday) : false;
      const isAfter = maxMidday ? midday.isAfter(maxMidday) : false;

      return isBefore || isAfter;
    };
  }, [minDate, maxDate]);

  const dispatchedChangeValue = useRef(true);
  const handleDateChange = newDate => {
    // console.log('🚀 ~ DatePicker.js', { helpers, newDate });
    helpers?.setValue(newDate);
    dispatchedChangeValue.current = false;

    if (onDateChange) onDateChange(newDate);
  };

  const handleFocusChange = ({ focused }) => {
    if (dispatchedChangeValue.current) {
      helpers?.setTouched(true);
    }

    dispatchedChangeValue.current = false;
    setFocusedInput(focused);
  };

  const displayFormat = useMemo(
    () => DISPLAY_FORMAT[i18n.language] ?? 'DD-MM-YYYY',
    [i18n.language],
  );

  return (
    <SingleDatePicker
      displayFormat={displayFormat}
      date={toMidday(date)}
      id={id}
      numberOfMonths={1}
      isOutsideRange={isOutsideRange}
      onDateChange={handleDateChange}
      focused={focusedInput}
      onFocusChange={handleFocusChange}
      hideKeyboardShortcutsPanel
    />
  );
};

export default DatePicker;
