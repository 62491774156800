import moment from 'moment';
import { getRandomIntInclusive } from '../utils/getRandomIntInclusive';
import sortAmenitiesHotel from '../utils/sortAmenitiesHotel';
import {
  HOTELS_FILTER,
  HOTEL_DETAIL,
  HOTEL_IMAGES,
  HOTEL_LIST,
  HOTEL_PRICE_COMPARISON,
} from './Routes';
import instance from './instance';

const sources = process.env.REACT_APP_SUNAPI_API_NAME_HOTEL;
const LANGUAGE_DEFAULT = 'es';
const CURRENCY_DEFAULT = 'USD';
const COMPANIES = [
  'Agoda.com',
  'Algotels',
  'Barcelo.com',
  'Booking.com',
  'eDreams',
  'Expedia.com',
  'Hotels.com',
  'Lol.travel',
  'Marriott',
  'Official Site',
  'Orbitz.com',
  'Prestigia.com',
  'Priceline',
  'SuperTravel',
  'Travelocity',
  'travelup.com',
  'Trip.com',
  'Vio.com',
  'ZenHotels.com',
];

export const getHotelDestination = async ({ searchString }) => {
  // console.log('🚀 ~ hotels.js', { searchString });
  const request = instance.get(HOTELS_FILTER, {
    params: {
      q: searchString,
    },
  });

  const response = await request
    .then(res => {
      return (res?.data ?? [])
        .map(it => {
          return {
            value: it?.modelParseHotel?.codeRateHawk?.split?.('|')?.[0],
            destiny: it?.destinationDTO?.id,
            label: [
              it?.name,
              it?.destinationDTO?.state?.name,
              it?.destinationDTO?.state?.country?.name,
            ]
              .filter(Boolean)
              .join(' - '),
          };
        })
        .filter(it => it.value && it.destiny && it.label);
    })
    .catch(() => {
      return [];
    });

  return response;
};

export const getHotels = async ({
  destiny,
  checkIn,
  checkOut,
  rooms,
  language = LANGUAGE_DEFAULT,
  currency = CURRENCY_DEFAULT,
  isDemo = false,
}) => {
  console.log('🚀 ~ hotels.js ~ getHotels', { isDemo });

  const body = {
    destiny,
    checkIn: moment(checkIn).format('DD-MM-YYYY'),
    checkOut: moment(checkOut).format('DD-MM-YYYY'),
    rooms,
    language: language === 'en' ? 'EN' : 'CAS',
    currency,
  };

  const request = instance.post(HOTEL_LIST, body, { params: { sources } });
  // const request = Promise.resolve({
  //   data: sample,
  // });
  const response = await request
    .then(res => {
      if (Array.isArray(res.data.errors) && res.data.errors.length) {
        return { errors: res.data.errors };
      }

      const min = parseFloat(res.data.minPrice);
      const max = parseFloat(res.data.maxPrice);
      const allAmenities = [];

      const hotelsValid = (res.data?.hotels || [])
        .filter(hotel => hotel?.name?.length)
        .map(it => {
          // console.log('🚀 ~ useHotel.js', { item });

          const hotel = {
            id: it.id,
            name: it.name,
            image: it.urlPhotoExtend ?? it.urlPhoto ?? null,
            stars: parseFloat(it.stars),
            amenities: sortAmenitiesHotel(it.amenities ?? []),
            total: Math.ceil(parseFloat(it.total ?? 0)),
            totalByNight: Math.ceil(parseFloat(it.totalByNight ?? 0)),
            currency: res.data?.currency,
            latitude:
              it.address?.geolocation?.latitude &&
              Number(it.address?.geolocation?.latitude),
            longitude:
              it.address?.geolocation?.longitude &&
              Number(it.address?.geolocation?.longitude),
          };

          if (it.amenities?.length) {
            allAmenities.push(
              ...it.amenities.map(
                ({ name }) =>
                  `${name.charAt(0).toLocaleUpperCase()}${name.substring(1)}`,
              ),
            );
          }

          if (isDemo) {
            const nights = moment(checkOut).diff(moment(checkIn), 'days');

            hotel.total = Math.ceil(hotel.total * 0.7);
            hotel.totalByNight = Math.ceil(hotel.total / nights);
          }

          return hotel;
        });

      return {
        hotelsValid,
        min,
        max,
        amenities: [...new Set(allAmenities)].sort(),
      };
    })
    .catch(err => {
      console.log('🚀 ~ hotels.js', { err });
    });

  return response;
};

export const getPriceComparison = async ({
  id,
  checkIn,
  checkOut,
  rooms,
  language = LANGUAGE_DEFAULT,
  currency = CURRENCY_DEFAULT,
  isDemo = false,
  total,
}) => {
  console.log('🚀 ~ hotels.js ~ getPriceComparison', { isDemo });
  const nights = moment(checkOut).diff(moment(checkIn), 'days');
  const body = [
    {
      id,
      apiName: sources,
      checkIn: moment(checkIn).format('DD-MM-YYYY'),
      checkOut: moment(checkOut).format('DD-MM-YYYY'),
      rooms,
      language,
      currency,
    },
  ];

  const request = instance.post(HOTEL_PRICE_COMPARISON, body);
  const response = await request
    .then(res => {
      const data = (res?.data?.[0]?.price?.comparison ?? []).flat();
      let offers = data.map(it => {
        const entries = Object.entries({ ...it, currency });
        const entriesMap = entries.map(([key, value]) => {
          const keyFixed = key.replace(/\d+$/, '');
          let valueFixed = value?.replace(/^\$/, '');
          if (['price', 'tax'].includes(keyFixed) && valueFixed) {
            valueFixed = parseFloat(valueFixed);
            if (keyFixed === 'price') {
              valueFixed *= nights;
            }

            valueFixed = Math.ceil(valueFixed);
          }

          return [keyFixed, valueFixed];
        });

        const offer = Object.fromEntries(entriesMap);

        return offer;
      });

      if (isDemo && !offers.length) {
        const auxCompanies = [...COMPANIES];
        offers = Array.from({ length: getRandomIntInclusive(5, 7) }).map(() => {
          const index = getRandomIntInclusive(0, auxCompanies.length - 1);

          return {
            vendor: auxCompanies.splice(index, 1)?.[0],
            price: Math.ceil(
              total + total * (getRandomIntInclusive(30, 40) / 100),
            ),
            tax: 1,
          };
        });
      }

      // console.log('🚀 ~ hotels.js', { offers });
      return offers
        .filter(it => it.vendor && it.price && it.tax)
        .sort(({ vendor: a }, { vendor: b }) => a.localeCompare(b));
    })
    .catch(err => {
      console.log('🚀 ~ hotels.js', { err });

      return null;
    });

  return response;
};

export const getImages = async ({ hotelId }) => {
  const request = instance.get(HOTEL_IMAGES({ hotelId }), {
    params: { apiName: sources },
  });
  const response = await request
    .then(res => {
      return (res?.data ?? [])
        .filter(({ url, type }) => url?.length && type === 'IMAGE')
        .map(({ url }) => url);
    })
    .catch(err => {
      console.log('🚀 ~ hotels.js', { err });

      return [];
    });

  return response;
};

const getKeyToGroupTheRooms = ({ room }) => {
  const key = room.roomDescription
    .sort(({ noRoom: a }, { noRoom: b }) => parseInt(a, 10) - parseInt(b, 10))
    .map(({ type }) => type)
    .filter(type => type?.length)
    .reduce((acc, type, index, self) => {
      acc[type] = acc[type] ?? 0;
      acc[type]++;

      if (index < self.length - 1) return acc;

      const entries = Object.entries(acc).map(([it, count]) => {
        if (count === 1) return it;

        return `${count} x ${it}`;
      });

      return entries;
    }, {})
    .join('-');

  // console.log('🚀 ~ hotels.js ~ getKeyToGroupTheRooms', { key });
  return key;
};

export const getHotel = async ({
  id,
  destiny,
  checkIn,
  checkOut,
  currency = CURRENCY_DEFAULT,
  language = LANGUAGE_DEFAULT,
  rooms,
  isDemo,
}) => {
  const data = {
    id,
    apiName: sources,
    destiny,
    checkIn: moment(checkIn).format('DD-MM-YYYY'),
    checkOut: moment(checkOut).format('DD-MM-YYYY'),
    currency,
    language: language === 'es' ? 'CAS' : 'EN',
    rooms,
  };

  const response = await instance
    .post(
      HOTEL_DETAIL,

      data,
    )
    .then(res => {
      const hotelDirty = res?.data ?? {};

      const hotel = {
        id: hotelDirty.id,
        name: hotelDirty.name,
        checkIn: moment(hotelDirty.checkIn, 'DD-MM-YYYY'),
        checkOut: moment(hotelDirty.checkOut, 'DD-MM-YYYY'),
        description: hotelDirty.description,
        stars: hotelDirty.stars ?? 0,
        address: hotelDirty.address?.addressName ?? '',
        position: hotelDirty.address?.geolocation && {
          id: hotelDirty.id,
          place: hotelDirty.name,
          raiting: hotelDirty.stars,
          latitude: hotelDirty.address.geolocation.latitude,
          longitude: hotelDirty.address.geolocation.longitude,
        },
        photos: hotelDirty.photos.map(({ url }) => url),
        rooms: null,
        extraInformation: hotelDirty.extraInformation,
        amenities: hotelDirty.amenities,
      };

      const accRoomDescription = {};
      hotel.rooms = (hotelDirty.rates ?? []).reduce(
        (acc, room, index, self) => {
          const key = getKeyToGroupTheRooms({ room });
          // console.log('🚀 ~ hotels.js', { key });

          acc[key] = acc[key] ?? [];
          const roomMap = {
            rateKey: room.rateKey,
            mealPlan: room.mealPlan,
            total: Math.ceil(parseInt(room.amountDetail.total, 10)),
            justGoPoints: 0,
            currency: room.amountDetail.currency,

            taxes: room.taxes.map(it => ({
              name: it.name,
              included: it.included,
              amount: Math.round(parseInt(it.amount, 10)),
              currency: it.currency,
              description: it.description,
            })),

            cancellationPolicies: room.cancellationPolicies.map(it => ({
              startDate: moment(it.startDate, 'DD-MM-YYYYTHH:mm').local(),
              endDate: moment(it.endDate, 'DD-MM-YYYYTHH:mm').local(),
              amount: Math.round(parseInt(it.amountDetail.amount, 10)),
              currency: it.amountDetail.currency ?? room.amountDetail.currency,
              nonRefundable: it.nonRefundable,
              description: it.description,
            })),
          };

          if (isDemo) {
            roomMap.total = Math.ceil(roomMap.total * 0.7);
          }

          roomMap.justGoPoints = Math.ceil(roomMap.total * 0.1);
          acc[key].push(roomMap);

          accRoomDescription[key] =
            accRoomDescription[key] ??
            room.roomDescription.map(it => ({
              id: `${it.type}-${it.noRoom}`,
              guest: it.guest,
              amenities: it.amenities,
            }));

          if (index < self.length - 1) return acc;

          return Object.entries(acc).map(([name, detail]) => {
            const roomDescription = accRoomDescription[name];
            const objAmenities = roomDescription.reduce(
              (accAmenities, itRoom) => {
                itRoom.amenities.forEach(itAmenity => {
                  // eslint-disable-next-line no-param-reassign
                  accAmenities[itAmenity.name] =
                    accAmenities[itAmenity.name] ?? itAmenity;
                });

                return accAmenities;
              },
              {},
            );

            return {
              name,
              roomDescription,
              amenities: Object.values(objAmenities),

              detail,
            };
          });
        },
        {},
      );

      // console.log('🚀 ~ hotels.js', { hotel });
      return hotel;
    })
    .catch(err => {
      const { message, developerMessage } = err.response.data;

      return {
        status: err.response.status,
        error: message ?? developerMessage ?? 'Error',
      };
    });

  return response;
};
