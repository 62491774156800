import axios from 'axios';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';
import get from '../services/get';
import { encodeURI } from '../utils/getURL';
import useAppParams from './useAppParams';
import useExtraItems from './useExtraItems';

const urlEvents = `sport-events?sources=SportEvents365`;
const urlSerch = `sport-events/search?sources=SportEvents365`;

const paramsValue = object => {
  const params = {
    sportTypeId: object.sport?.value || null,
    competitorId: object.event?.value || null,
    tournamentId: object.tournament?.value || null,
    countryId: object.country?.value || null,
    cityIdm: object.city?.value || null,
    textToSearch: object.text?.value || null,
    from: object.from?.value || null,
    ntil: object.ntil?.value || null,
  };
  const sportTypeId =
    params.sportTypeId !== null ? `&sportTypeId=${params.sportTypeId}` : '';
  const competitorId =
    params.competitorId !== null ? `&competitorId=${params.competitorId}` : '';
  const countryId =
    params.countryId !== null ? `&countryId=${params.countryId}` : '';
  const cityIdm = params.cityIdm !== null ? `&cityIdm=${params.cityIdm}` : '';
  const textToSearch =
    params.textToSearch !== null ? `&textToSearch=${params.textToSearch}` : '';
  const from = params.from !== null ? `&from=${params.from}` : '';
  const ntil = params.ntil !== null ? `&ntil=${params.ntil}` : '';
  const url = `${sportTypeId}${competitorId}${countryId}${cityIdm}${textToSearch}${from}${ntil}`;
  return url;
};

const valueSEvents = array => {
  const values = [];
  for (let index = 0; index < array.length; index++) {
    values.push({
      label:
        array[index].caption !== undefined
          ? array[index].caption
          : array[index].caption,
      value: array[index].id,
    });
  }
  return values;
};

const getURL = (query, id) => {
  const param = query ? encodeURI(query) : null;

  // console.log('🚀 ~ useSportingEvents.js ~ getURL', { query, id, param });

  return `${[param, id].filter(str => !!str && `${str}`.length).join('/')}`;
};

const useSportingEvents = () => {
  const history = useHistory();
  const { query, id } = useAppParams();
  const extraItems = useExtraItems('booking-hotel');

  const search = newQuery => {
    const param = getURL(newQuery);

    history.push(`/sportingEvents/list/${param}`);
  };

  const searchTickets = itm => {
    const param = getURL(itm, nanoid());

    history.push(`/sportingEvents/detail/${param}`);
  };

  const warranty = itm => {
    const param = getURL(itm, nanoid());

    history.push(`/sportingEvents/warranty/${param}`);
  };

  const makeBook = (item, rate) => {
    if (item && rate) {
      const booking = {
        apiName: item.data.caption,
        checkIn: item.data.date,
        checkOut: item.data.date,
        destino_name: item.data.country,
        stars: '',
        direccion: item.data.venue_address,
        nTickets: item.nTickets,
        unitPrice: Math.round(item.Price * item.dollar),
        total: Math.round(item.Price * item.dollar * item.nTickets),
        ServiceFee: Math.round(item.ServiceFee),
        mealPlan: item.ItemID,
        sport: item.data.sport,
        venue: item.data.venue,
        section: item.Section,
      };
      const iD = extraItems.save(booking);

      history.push(`/sportingEvents/booking/${iD}`);
    }
  };

  const getIpClient = async () => {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data;
  };

  const getSports = async () => {
    const response = await get(`${urlEvents}&typeQuery=GET_SPORTS`);
    const resul = valueSEvents(response?.data?.all?.data ?? []);
    if (response?.data?.errors?.length) throw response.data.errors;
    return resul;
  };

  const getEvents = async body => {
    const urlGet = paramsValue(body);
    const urlFinal = `${urlSerch}${urlGet}`;
    const response = await get(urlFinal);
    const newRsult = response.data.all;
    return newRsult;
  };

  const getCountries = async () => {
    const response = await get(`${urlEvents}&typeQuery=GET_COUNTRIES`);

    const resul = valueSEvents(response?.data?.all?.data ?? []);
    if (response?.data?.errors?.length) throw response.data.errors;
    return resul;
  };
  const getCities = async Id => {
    const url = `${urlEvents}&typeQuery=GET_CITIES&id=${Id}`;
    const response = await get(url);

    const resul = valueSEvents(response.data.all.data);
    if (response?.data?.errors?.length) throw response.data.errors;
    return resul;
  };

  return {
    id,
    query,
    search,
    searchTickets,
    getCountries,
    getEvents,
    getCities,
    getSports,
    checkout: {},
    makeBook,
    warranty,
    getIpClient,
  };
};

export default useSportingEvents;
