import React from 'react';

import { Route, Switch } from 'react-router-dom';
import Footer from '../components/Footer';
import HeaderNav from '../components/HeaderNav';
import Cars from './Cars';
import Condos from './Condos';
import CruiseShips from './CruiseShips';
import Dashboard from './Dashboard';
import DepositYourWeek from './DepositYourWeek';
import Exchange from './Exchange';
import Experience from './Experience';
import Flights from './Flights';
import GroupTravelRequests from './GroupTravelRequests';
import Hotels from './Hotels';
import MyPreferences from './MyPreferences';
import MyProfile from './MyProfile';
import PriceGuarantee from './PriceGuarantee';
import Restaurants from './Restaurants';
import SportingEvents from './SportingEvents';

const Home = () => {
  return (
    <>
      <HeaderNav />

      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/price-guarantee" component={PriceGuarantee} />
        <Route path="/group-travel-requests" component={GroupTravelRequests} />
        <Route path="/my-profile" component={MyProfile} />
        <Route path="/my-preferences" component={MyPreferences} />
        <Route path="/restaurants" component={Restaurants} />
        <Route path="/hotels" component={Hotels} />
        <Route path="/experience" component={Experience} />
        <Route path="/cars" component={Cars} />
        <Route path="/condos" component={Condos} />
        <Route path="/flights" component={Flights} />
        <Route path="/sportingEvents" component={SportingEvents} />
        <Route path="/cruiseShips" component={CruiseShips} />

        <Route exact path="/deposityourweek">
          <DepositYourWeek />
        </Route>
        <Route exact path="/exchange">
          <Exchange />
        </Route>
        {/*  <Route path="/ritzCarlton" component={ritzCarlton} /> */}
      </Switch>

      <Footer />
    </>
  );
};

export default Home;
