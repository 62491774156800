import React from 'react';

import moment from 'moment';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormSidebar from '../../shared/FormSidebar';
import styles from './BookSidebar.module.scss';
import ListFeatures from './ListFeatures';
import PriceFormat from '../PriceFormat';

const BookSidebar = ({ rate }) => {
  const { t } = useTranslation();
  // console.log('🚀 ~ BookSidebar.jsx', { rate });

  if (!rate) return <div />;

  return (
    <div className={styles.container}>
      <FormSidebar>
        <Container>
          <Row xs="1" className={styles.rowYourReservationDetail}>
            <Col>
              <h4>{t('YourReservationDetail')}</h4>
            </Col>

            <Col>
              <Row xs="1">
                <Col lg="6" className="d-flex flex-column">
                  <span>{t('CarsPickUp')}</span>
                  <span>{rate.pickUp}</span>
                </Col>

                <Col lg="6" className="d-flex flex-column">
                  <span>{t('CarsDropOff')}</span>
                  <span>{rate.dropOff}</span>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row xs="1">
                <Col lg="6" className="d-flex flex-column">
                  <span>{t('CarsPickUpDate')}</span>
                  <span>
                    {moment(rate.pickUpDate).format('dd, DD MMM, YYYY HH:mm')}
                  </span>
                </Col>

                <Col lg="6" className="d-flex flex-column">
                  <span>{t('CarsDropOffDate')}</span>
                  <span>
                    {moment(rate.dropOffDate).format('dd, DD MMM, YYYY HH:mm')}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </FormSidebar>

      <Container>
        <Card className={styles.cardBelow}>
          <Card.Header>
            <Row xs="1">
              <Col>
                <h4>{t('YourReservationDetail')}</h4>
              </Col>

              <Col>
                {t('CarsBookCarBy', {
                  car: rate.car.name,
                  company: rate.car.company,
                })}
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <ListFeatures car={rate} />
          </Card.Body>

          <Card.Footer>
            <Row xs="2">
              <Col>
                <h6 className={styles.textTotal}>Total</h6>
              </Col>

              <Col>
                <PriceFormat
                  value={rate.price.total}
                  currency={rate.price.currency}
                >
                  {valueText => (
                    <h6 className={`${styles.textTotal} text-right`}>
                      {valueText}
                    </h6>
                  )}
                </PriceFormat>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Container>
    </div>
  );
};

export default BookSidebar;
