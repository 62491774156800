import React from 'react';

import {
  Badge,
  Card,
  Col,
  Jumbotron,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaUser } from 'react-icons/fa';
import getHuespedesNumber from '../../utils/getAdultsDetail';
import GetTextHuespedes from '../../utils/getTextHuespedes';
import RoomTable from './RoomTable';

const RoomsDetail = ({ rooms, onBookingClick }) => {
  const { t } = useTranslation();

  if (!rooms.length)
    return (
      <Jumbotron>
        <h4>{t('LoSentimos')}</h4>
        <p>{t('NoHabitacionesDisponibles')}</p>
      </Jumbotron>
    );

  return (
    <Row className="mt-4">
      <Col xs="12" lg="12">
        <h2 className="text-capitalize">{t('HotelsRooms')}</h2>
      </Col>

      <Col>
        {rooms.map(room => (
          <Card key={room.name} className="mb-4">
            <Card.Body>
              <Row>
                <Col xs="12" lg="12" className="mb-2">
                  <span className="text-detail-room bg-dark text-light rounded">
                    {room.name}
                  </span>
                </Col>

                <Col xs="12" lg="12">
                  {room.roomDescription.map(roomDescription => (
                    <Badge variant="light" key={roomDescription.id}>
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            <GetTextHuespedes guest={roomDescription.guest} />
                          </Tooltip>
                        }
                      >
                        <div>
                          {getHuespedesNumber(
                            roomDescription?.guest?.adults || 0,
                            'icon-16',
                          ).map((cla, clI) => (
                            <FaUser className={cla} key={clI} />
                          ))}
                          {roomDescription?.guest?.children > 0 ? '+' : ''}
                          {getHuespedesNumber(
                            roomDescription?.guest?.children || 0,
                            'icon-12',
                          ).map((cla, clI) => (
                            <FaUser className={cla} key={clI} />
                          ))}
                        </div>
                      </OverlayTrigger>
                    </Badge>
                  ))}
                </Col>

                <Col xs="12" lg="12">
                  <RoomTable
                    room={room}
                    rates={room.detail}
                    onBookingClick={onBookingClick}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </Col>
    </Row>
  );
};

export default RoomsDetail;
