import React, { useMemo, useState } from 'react';

import { Field } from 'formik';
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
} from 'react-bootstrap';
import { MdPeople } from 'react-icons/md';
import useAppTranslation from '../../hook/useAppTranslation';
import Counter from '../../shared/Counter';
import { filterSearchSchema } from '../../validations/hotels';
import styles from './Travelers.module.scss';

const Travelers = ({ form, remove, push, disabled }) => {
  const { t } = useAppTranslation();
  const [show, setShow] = useState(false);
  const [room] = filterSearchSchema?.getDefault?.().rooms ?? [
    { adults: 0, children: { ages: [] } },
  ];

  const rooms = useMemo(() => form?.values?.rooms ?? [], [form?.values?.rooms]);
  const guests = useMemo(() => {
    // console.log('🚀 ~ Travelers.jsx', { rooms });
    const count = rooms.reduce((acc, { adults, children }) => {
      return acc + adults + (children?.ages ?? []).length;
    }, 0);

    return count;
  }, [rooms]);
  const allowAddRoom = useMemo(() => rooms.length < 5, [rooms]);

  const handleDecRoomClick = index => {
    remove(index);
  };

  const handleIncRoomClick = () => {
    push(room);
  };

  const handleDoneClick = () => {
    setShow(false);
  };

  return (
    <Dropdown
      as={ButtonGroup}
      className="filter w-100 m-0 p-0"
      size="lg"
      show={show}
      onToggle={() => setShow(!show)}
    >
      <Dropdown.Toggle className={styles.toggle} disabled={disabled}>
        <span>
          <MdPeople /> {t('HotelsRoomsDescription', { count: rooms.length })},{' '}
          {t('HotelsGuestsDescription', { count: guests })}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Container className={styles.containerMenu}>
          {rooms.map(({ adults, children = { ages: [] } }, roomIndex) => {
            const roomKey = `room${roomIndex}`;
            const fieldAdults = `rooms.${roomIndex}.adults`;
            const fieldChildren = `rooms.${roomIndex}.children.ages`;
            const isLast = rooms.length - 1 === roomIndex;

            const handleAdultClick = newAdults => {
              form.setFieldValue(fieldAdults, newAdults);
            };

            const handleChildClick = newChildren => {
              form.setFieldValue(fieldChildren, newChildren);
            };

            return (
              <Row xs="1" key={roomKey} className={styles.container}>
                <Col className={styles.containerRoomTitle}>
                  <h6 className={styles.room}>
                    {roomIndex + 1} {t('HotelsRooms_one').toLocaleLowerCase()}
                  </h6>

                  {rooms.length > 1 && (
                    <Button
                      variant="link"
                      size="sm"
                      className="float-right m-0 p-0"
                      onClick={() => handleDecRoomClick(roomIndex)}
                    >
                      {t('HotelsDeleteRoom')}
                    </Button>
                  )}
                </Col>

                <Col className={styles.containerGuest}>
                  <span>{t('HotelsAdults', { count: adults })}</span>

                  <div className={styles.containerCounter}>
                    <Field name={fieldAdults}>
                      {({ field }) => (
                        <Counter
                          name={field.name}
                          value={field.value}
                          min={1}
                          max={6}
                          onChange={handleAdultClick}
                        />
                      )}
                    </Field>
                  </div>
                </Col>

                <Col className={styles.containerGuest}>
                  <span>
                    {t('HotelsChildren', {
                      count: children.ages.length,
                    })}
                  </span>

                  <div className={styles.containerCounter}>
                    <Field name={fieldChildren}>
                      {({ field }) => (
                        <Counter
                          name={field.name}
                          value={field.value.length}
                          min={0}
                          max={4}
                          onChange={newValue => {
                            if (newValue < field.value.length) {
                              handleChildClick(field.value.slice(0, -1));
                            } else if (newValue > field.value.length) {
                              handleChildClick([...field.value, 0]);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </Col>

                {(children?.ages ?? []).map((age, childIndex) => {
                  const childrenKey = `${roomKey}-children${childIndex}`;

                  return (
                    <Field
                      key={childrenKey}
                      name={`${fieldChildren}.${childIndex}`}
                    >
                      {({ field }) => (
                        <Col className={styles.containerChild}>
                          <span>
                            {t('HotelsChildAge')} {childIndex + 1}
                          </span>

                          <Form.Control
                            as="select"
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                          >
                            {Array.from({ length: 18 }).map((_, year) => (
                              <option
                                key={`${childrenKey}-${year}`}
                                value={year}
                              >
                                {t('HotelsChildAgeYears', {
                                  count: year,
                                })}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      )}
                    </Field>
                  );
                })}

                {!isLast && <Dropdown.Divider />}
              </Row>
            );
          })}
        </Container>

        <Container>
          <Row xs="1" className="sticky-top">
            <Dropdown.Divider />

            <Col
              className={`${styles.containerButtons} ${
                !allowAddRoom && styles.containerButtonsLast
              }`}
            >
              {allowAddRoom && (
                <Button
                  variant="link"
                  size="sm"
                  className="float-right m-0 p-0"
                  onClick={handleIncRoomClick}
                >
                  + {t('HotelsAddRoom')}
                </Button>
              )}

              <Button variant="primary" onClick={handleDoneClick}>
                {t('HotelsDone')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Travelers;
