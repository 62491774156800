/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';

import moment from 'moment';
import { withTranslation } from 'react-i18next';
import VoucherComponent from '../components/Voucher';
import prod3 from '../img/voucher/prod-3.jpg'; // with import
import prod4 from '../img/voucher/prod-4.jpg'; // with import
import formatNumber from '../utils/formatNumber';

const Voucher = ({ t, infoReserva }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // eslint-disable-next-line no-console
  console.log('🚀 ~ Voucher.js', { infoReserva });

  return (
    <VoucherComponent
      name={infoReserva.client_name}
      destination={infoReserva.place_name}
      noBooking={infoReserva.reservationCode}
    >
      <table
        className="bg_white"
        role="presentation"
        border={0}
        cellPadding={0}
        cellSpacing={0}
        width="100%"
      >
        <tbody>
          <tr style={{ borderBottom: '1px solid rgba(0,0,0,.05)' }}>
            <th
              width="80%"
              style={{
                textAlign: 'left',

                padding: '0 2.5em',

                color: '#000',

                paddingBottom: '20px',

                paddingTop: '30px',
              }}
            >
              Reserva
            </th>

            <th
              width="20%"
              style={{
                textAlign: 'right',

                padding: '0 2.5em',

                color: '#000',

                paddingBottom: '20px',

                paddingTop: '30px',
              }}
            >
              Precio
            </th>
          </tr>

          <tr style={{ borderBottom: '1px solid rgba(0,0,0,.05)' }}>
            <td
              valign="middle"
              width="80%"
              style={{ textAlign: 'left', padding: '0 2.5em' }}
            >
              <div className="product-entry">
                <img
                  src={prod3}
                  alt=""
                  style={{
                    width: '100px',

                    maxWidth: '600px',

                    height: 'auto',

                    marginBottom: '20px',

                    display: 'block',
                  }}
                />

                <div className="text">
                  <h3>
                    <b>{infoReserva.place_name}</b>
                  </h3>

                  <p>
                    Make changes to your booking or ask the property a question{' '}
                    <a href>in just a few clicks</a>
                  </p>
                </div>

                <div>
                  <div className="listing">
                    <h3>Reservations details</h3>

                    <h3>
                      <b>Guest name: </b> {infoReserva.client_name}
                    </h3>

                    <h4>
                      <b>Check-in: </b>{' '}
                      {moment(infoReserva.checkin, 'DD-MM-YYYY').format(
                        'dddd DD MMMM YYYY',
                      )}{' '}
                      (from 15:00)
                    </h4>

                    {infoReserva.checkout && (
                      <h4>
                        <b>Check-out: </b>{' '}
                        {moment(infoReserva.checkout, 'DD-MM-YYYY').format(
                          'dddd DD MMMM YYYY',
                        )}{' '}
                        (until 12:00)
                      </h4>
                    )}

                    <h4>
                      <strong>{t('SuReservacionPara')}:</strong>{' '}
                      {infoReserva.paxes.adults} Adultos,{' '}
                      {infoReserva.paxes.children} Menores
                    </h4>

                    <h4>
                      <b>Phone: </b> {infoReserva.client_phone}
                    </h4>

                    <h4>
                      <b>Contact: </b> {infoReserva.client_email}
                    </h4>

                    <h4>
                      <b>Prepayment: </b> You don’t need to prepay. However, the
                      property may take a deposit from the card you booked with.
                      This is a routine procedure to verify that it is valid and
                      hasnt been lost or stolen. The hold is temporary and the
                      amount will be returned to you in full.
                    </h4>

                    <h4>
                      <b>Cancellation policy: </b> You will be charged the cost
                      of the first night if you cancel your booking.
                    </h4>

                    <h4>
                      <b>Cancellation cost: </b>

                      <ul>
                        <li>
                          Hasta 3 de septiembre de 2020 23:59 [EST] : MXN
                          1,340.24
                        </li>

                        <li>
                          This booking is non-refundable. Changing the dates of
                          your stay is not possible.
                        </li>
                      </ul>
                    </h4>
                  </div>
                </div>
              </div>
            </td>

            <td
              valign="middle"
              width="20%"
              style={{ textAlign: 'left', padding: '0 2.5em' }}
            >
              <span
                className="price"
                style={{ color: '#000', fontSize: '20px' }}
              >
                {formatNumber(infoReserva.total)} {infoReserva.currency}
              </span>
            </td>
          </tr>

          <tr style={{ borderBottom: '1px solid rgba(0,0,0,.05)' }}>
            <td
              valign="middle"
              width="80%"
              style={{ textAlign: 'left', padding: '0 2.5em' }}
            >
              <div className="product-entry">
                <img
                  src={prod4}
                  alt=""
                  style={{
                    width: '100px',

                    maxWidth: '600px',

                    height: 'auto',

                    marginBottom: '20px',

                    display: 'block',
                  }}
                />

                <div className="text">
                  <h3>
                    <b>Diving and Snorkeling</b>
                  </h3>

                  <span>
                    <a href>Wet Set Diving Adventures</a>
                  </span>

                  <p>
                    Dive and Snorkel on a protected reef in Mayan Riviera -
                    Scuba Diving Snorkeling. French, English and Spanish spoken.
                    Call for more information. 20+ Years Of Experience.
                  </p>
                </div>
              </div>
            </td>

            <td
              valign="middle"
              width="20%"
              style={{ textAlign: 'left', padding: '0 2.5em' }}
            >
              <span
                className="price"
                style={{ color: '#000', fontSize: '20px' }}
              >
                $3,789
              </span>
            </td>
          </tr>

          <tr>
            <td
              valign="middle"
              style={{ textAlign: 'left', padding: '1em 2.5em' }}
            >
              <p>
                <a href="#" className="btn btn-primary">
                  Make changes to your booking
                </a>

                <a href="#" className="btn btn-primary2">
                  Get the print version &gt;&gt;
                </a>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </VoucherComponent>
  );
};

export default withTranslation()(Voucher);
