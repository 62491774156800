import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import formatNumber from '../utils/formatNumber';

const ReservaCardTopWhiteExp = ({ t, item }) => {
  return (
    <Card className="shadow-none">
      <Card.Body>
        <Row>
          <Col xs="12" lg="12">
            <h5 className="text-info">{t('YourReservationDetail')}</h5>
            <hr className="bg-info" />
            <h6>{item.experience_name}</h6>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="6" lg="7">
            <label className="text-lowercase">{item.adults} Adulto</label>
          </Col>
          <Col xs="6" lg="5">
            <label>{formatNumber(item.adult_price)} USD</label>
          </Col>
        </Row>
        <Row>
          <Col xs="6" lg="7">
            <label className="lh-1">{item.children} Menor</label>
          </Col>
          <Col xs="6" lg="5">
            <label>{formatNumber(item.child_price)}0 USD</label>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="6" lg="7">
            <h6 className="text-success">{t('Total')}</h6>
          </Col>
          <Col xs="6" lg="5">
            <h6 className="text-success">
              {formatNumber(item.adult_price + item.child_price)} USD
            </h6>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default withTranslation()(ReservaCardTopWhiteExp);
