import moment from 'moment';
import * as yup from 'yup';

yup.addMethod(
  yup.string,
  'isDateMomentValid',
  function isDateMomentValid({ message = 'CampoRequerido' } = {}) {
    return this.transform((value, originalValue) => {
      // console.log('🚀 ~ cars.js', { value, originalValue });
      const date = moment(originalValue);

      return date.isValid() ? date.format() : null;
    }).test(
      'isDateMomentValid',
      message,
      value => !!value && moment(value).isValid(),
    );
  },
);

yup.addMethod(
  yup.string,
  'isDateBeforeAtStartDate',
  function isDateBeforeAtStartDate({
    message = 'Error End Date',
    startDateName,
  } = {}) {
    if (!startDateName?.length) return this;

    return this.isDateMomentValid().when(
      [startDateName],
      (startDate, schema) => {
        const mStartDate = startDate && moment(startDate);
        if (!mStartDate?.isValid()) return schema;

        return schema.test('isDateBeforeAtStartDate', message, endDate => {
          const mEndDate = endDate && moment(endDate);
          if (!mEndDate?.isValid()) return true;

          return mStartDate.isSameOrBefore(mEndDate);
        });
      },
    );
  },
);

export default yup;
