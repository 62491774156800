import React from 'react';

import { Field } from 'formik';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import black from '../../img/profile/black.jpg';
import platinum from '../../img/profile/platinum.jpg';
import ButtonEdit from './ButtonEdit';
import { useAuth } from '../../providers/AuthProvider';

const FormProfile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isPlatinum } = useAuth();

  const handleEditClick = () => {
    history.push(`${history.location.pathname}`.replace('/edit', ''));
  };

  return (
    <>
      <Row className="mb-3 text-uppercase">
        <Col>{t('ProfilePhoto')}</Col>
      </Row>

      <RowPhoto className="mb-3">
        <Col xs="4" sm="3" md="2">
          <ImageStyled src={isPlatinum ? platinum : black} />
        </Col>

        <Col xs="8" sm="4" lg="3">
          <ButtonEdit>{t('ProfileChangePhoto')}</ButtonEdit>
        </Col>
      </RowPhoto>

      <Row className="mb-3 text-uppercase">
        <Col>{t('ProfilePersonalInformation')}</Col>
      </Row>

      <Row>
        <Col lg="6" xl="4">
          <Field name="name">
            {({ field, meta: { touched, error } }) => {
              return (
                <Form.Group>
                  <Label className="required">{t('ProfileNames')}</Label>
                  <Form.Control
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    type="text"
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {error.replace('name', t('ProfileNames'))}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </Col>

        <Col lg="6" xl="4">
          <Field name="lastName1">
            {({ field, meta: { touched, error } }) => {
              return (
                <Form.Group>
                  <Label className="required">{t('ProfileLastName')}</Label>
                  <Form.Control
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    type="text"
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {error.replace('lastName1', t('ProfileLastName'))}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </Col>

        <Col lg="6" xl="4">
          <Field name="lastName2">
            {({ field, meta: { touched, error } }) => {
              return (
                <Form.Group>
                  <Label>{t('ProfileLastName2')}</Label>
                  <Form.Control
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    type="text"
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {error.replace('lastName2', t('ProfileLastName2'))}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </Col>

        <Col lg="6" xl="4">
          <Field name="dateOfBirth">
            {({ field, meta: { touched, error } }) => {
              return (
                <Form.Group>
                  <Label className="required">{t('ProfileDateOfBirth')}</Label>
                  <Form.Control
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    type="text"
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {error.replace('dateOfBirth', t('ProfileDateOfBirth'))}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </Col>

        <Col lg="6" xl="4">
          <Field name="email">
            {({ field, meta: { touched, error } }) => {
              return (
                <Form.Group>
                  <Label className="required">{t('ProfileEmail')}</Label>
                  <Form.Control
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    type="text"
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {error.replace('email', t('ProfileEmail'))}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </Col>

        <Col lg="6" xl="4">
          <Field name="additionalEmail">
            {({ field, meta: { touched, error } }) => {
              return (
                <Form.Group>
                  <Label>{t('ProfileAdditionalEmail')}</Label>
                  <Form.Control
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    type="text"
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {error.replace(
                        'additionalEmail',
                        t('ProfileAdditionalEmail'),
                      )}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </Col>

        <Col lg="6" xl="4">
          <Field name="telephone">
            {({ field, meta: { touched, error } }) => {
              return (
                <Form.Group>
                  <Label className="required">{t('ProfileTelephone')}</Label>
                  <Form.Control
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    type="text"
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {error.replace('telephone', t('ProfileTelephone'))}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </Col>

        <Col lg="6" xl="4">
          <Field name="country">
            {({ field, meta: { touched, error } }) => {
              return (
                <Form.Group>
                  <Label className="required">{t('ProfileCountry')}</Label>
                  <Form.Control
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    type="text"
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {error.replace('country', t('ProfileCountry'))}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </Col>

        <Col lg="6" xl="4">
          <Field name="state">
            {({ field, meta: { touched, error } }) => {
              return (
                <Form.Group>
                  <Label className="required">{t('ProfileState')}</Label>
                  <Form.Control
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    type="text"
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {error.replace('state', t('ProfileState'))}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </Col>
      </Row>

      <RowButtons className="mt-3">
        <Col xs="6">
          <Button
            className="text-uppercase"
            variant="secondary"
            onClick={handleEditClick}
          >
            {t('ProfileCancel')}
          </Button>
        </Col>

        <Col xs="6">
          <Button
            type="submit"
            className="text-uppercase"
            variant="outline-primary"
          >
            {t('ProfileSave')}
          </Button>
        </Col>
      </RowButtons>
    </>
  );
};

const RowPhoto = styled(Row)`
  & > div {
    display: flex;
    justify-content: center;

    &:last-child {
      flex-direction: column;
    }
  }
`;

const ImageStyled = styled(Image)`
  width: 94px;
  aspect-ratio: 1;
  border-radius: 50rem;
`;

const Label = styled(Form.Label)`
  &.required::before {
    content: '*';
    color: red;
    margin-right: 0.5rem;
  }
`;

const RowButtons = styled(Row)`
  & > div:first-child {
    display: flex;
    justify-content: end;
  }
`;

export default FormProfile;
