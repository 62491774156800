import React, { useMemo } from 'react';

import { Field, FieldArray } from 'formik';
import moment from 'moment';
import { Button, Container, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import withFormikSearch from '../../hoc/withFormikSearch';
import useAppTranslation from '../../hook/useAppTranslation';
import useDev from '../../hook/useDev';
import ColInline from '../../shared/ColInline';
import SelectDestination from '../../shared/SelectDestination';
import { encode } from '../../utils/uriComponent';
import { BY, filterSearchSchema } from '../../validations/hotels';
import styles from './FormSearch.module.scss';
import SelectHotel from './SelectHotel';
import Travelers from './Travelers';

const FormSearch = ({ formikBag, inline = false, disabled = false }) => {
  const { t, dateFormat } = useAppTranslation();
  const { isDev } = useDev();
  const by = useMemo(() => formikBag?.values?.by, [formikBag?.values?.by]);
  // console.log('🚀 ~ FormSearch.jsx', { by });

  return (
    <Container className={styles.container}>
      <Row xs="1">
        {by === BY.DESTINATION && (
          <ColInline inline={inline} lg="3">
            <Field name="destiny">
              {({ field, meta }) => {
                // console.log('🚀 ~ FormSearch.jsx', { field, meta });

                return (
                  <Form.Group controlId={field.name}>
                    <Form.Label
                      className={`text-light ${disabled || 'required'}`}
                    >
                      {t('HotelsDestination')}
                    </Form.Label>

                    <SelectDestination
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      disabled={disabled}
                      isValid={meta.touched && !meta.error}
                      isInvalid={meta.touched && !!meta.error}
                    />

                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {t(meta.error)}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            </Field>
          </ColInline>
        )}

        {by === BY.HOTEL && (
          <ColInline inline={inline} lg="6">
            <Field name="hotelId">
              {({ field, meta }) => {
                // console.log('🚀 ~ FormSearch.jsx', { field, meta });

                return (
                  <Form.Group controlId={field.name}>
                    <Form.Label
                      className={`text-light ${disabled || 'required'}`}
                    >
                      {t('Hoteles_one')}
                    </Form.Label>

                    <SelectHotel
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      disabled={disabled}
                      isValid={meta.touched && !meta.error}
                      isInvalid={meta.touched && !!meta.error}
                    />

                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {t(meta.error)}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            </Field>
          </ColInline>
        )}

        <ColInline inline={inline} lg="3">
          <Field name="checkIn">
            {({ field, meta }) => {
              const { touched, error } = meta;
              // console.log('🚀 ~ FormSearch.jsx ~ checkIn', { field, meta });
              const mCheckIn = moment(field.value);
              const mCheckOut = moment(formikBag?.values?.checkOut);

              const checkIn = mCheckIn.isValid() ? mCheckIn.toDate() : null;
              const checkOut = mCheckOut.isValid() ? mCheckOut.toDate() : null;

              return (
                <Form.Group controlId={field.name}>
                  <Form.Label
                    className={`text-light ${disabled || 'required'}`}
                  >
                    {t('HotelsCheckIn')}
                  </Form.Label>

                  <Form.Control
                    as={DatePicker}
                    name={field.name}
                    selected={checkIn}
                    onBlur={field.onBlur}
                    onChange={date => {
                      const mDate = moment(date);
                      formikBag.setFieldValue(
                        field.name,
                        mDate.isValid() ? mDate : null,
                      );
                    }}
                    selectsStart
                    startDate={checkIn}
                    minDate={new Date()}
                    endDate={checkOut}
                    dateFormat={dateFormat}
                    disabled={disabled}
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {t(error)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </ColInline>

        <ColInline inline={inline} lg="3">
          <Field name="checkOut">
            {({ field, meta }) => {
              const { touched, error } = meta;
              // console.log('🚀 ~ FormSearch.jsx ~ checkOut', { field, meta });
              const mCheckIn = moment(formikBag?.values?.checkIn);
              const mCheckOut = moment(field.value);

              const checkIn = mCheckIn.isValid() ? mCheckIn.toDate() : null;
              const checkOut = mCheckOut.isValid() ? mCheckOut.toDate() : null;

              return (
                <Form.Group controlId={field.name}>
                  <Form.Label
                    className={`text-light ${disabled || 'required'}`}
                  >
                    {t('HotelsCheckOut')}
                  </Form.Label>

                  <Form.Control
                    as={DatePicker}
                    name={field.name}
                    selected={checkOut}
                    onBlur={field.onBlur}
                    onChange={date => {
                      const mDate = moment(date);
                      formikBag.setFieldValue(
                        field.name,
                        mDate.isValid() ? mDate : null,
                      );
                    }}
                    selectsEnd
                    startDate={checkIn}
                    endDate={checkOut}
                    minDate={checkIn}
                    dateFormat={dateFormat}
                    disabled={disabled}
                    isValid={touched && !error}
                    isInvalid={touched && !!error}
                  />

                  {touched && error && (
                    <Form.Control.Feedback type="invalid">
                      {t(error)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              );
            }}
          </Field>
        </ColInline>

        <ColInline inline={inline} lg="3">
          <FieldArray name="rooms">
            {arrayHelpers => {
              // const { touched, error } = meta;
              // console.log('🚀 ~ FormSearch.jsx', { field, form, meta });

              return (
                <Form.Group controlId={arrayHelpers.name}>
                  <Form.Label
                    className={`text-light ${disabled || 'required'}`}
                  >
                    {t('HotelsTravelers')}
                  </Form.Label>

                  <Form.Control
                    as={Travelers}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...arrayHelpers}
                    disabled={disabled}
                  />
                </Form.Group>
              );
            }}
          </FieldArray>
        </ColInline>
      </Row>

      {!disabled && (
        <Row xs="1">
          <ColInline inline={inline} lg={{ span: 2, offset: 10 }}>
            <Button
              type="submit"
              block
              variant="outline-primary"
              className="font-weight-bold"
            >
              {t('Buscar')}
            </Button>
          </ColInline>
        </Row>
      )}

      {isDev && (
        <Row>
          <ColInline xs="12">
            <pre style={{ color: '#fff' }}>
              {JSON.stringify(
                {
                  values: formikBag.values,
                  touched: formikBag.touched,
                  errors: formikBag.errors,
                },
                null,
                2,
              )}
            </pre>
          </ColInline>
        </Row>
      )}
    </Container>
  );
};

export default withFormikSearch(
  {
    schema: filterSearchSchema,
    onSubmit: ({ values, actions, history }) => {
      actions.setSubmitting(false);

      const query = filterSearchSchema.cast(values, { stripUnknown: true });
      const encodedQuery = encode(query);

      if (query.by === BY.DESTINATION)
        history.push(`/hotels/list/${encodedQuery}`);
      else history.push(`/hotels/detail/${encodedQuery}/${query.hotelId}`);
    },
  },

  FormSearch,
);
