import { css } from 'styled-components';

const dropdown = css`
  .dropdown.filter {
    height: 41px;
    border-bottom: 2px solid #ced4da;

    & > .dropdown-toggle {
      width: 100% !important;
      background: transparent !important;
      box-shadow: none !important;
      border: 0 !important;
      text-align: left !important;
      padding-left: 0 !important;

      &::after {
        display: none;
      }
    }

    &:has(> .dropdown-toggle:disabled),
    *:has(+ & > .dropdown-toggle:disabled) {
      opacity: 0.5;
    }
  }
`;

export default dropdown;
