import React from 'react';

import { useField } from 'formik';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CardTraveler from './CardTraveler';
import styles from './CardWhoTraveling.module.css';

const CardWhoTraveling = ({ name }) => {
  const { t } = useTranslation();
  const [{ value }] = useField(name);

  return (
    <Card className="shadow-sm">
      <Card.Body>
        <Row xs="1">
          <Col>
            <h1>{t('FlightWhoTraveling')}</h1>
            <p>{t('FlightWhoTravelingInfo')}</p>
          </Col>

          {(value ?? []).map((_, index) => {
            return (
              <Col key={index} className={styles.accordion}>
                <CardTraveler name={name} index={index} />
              </Col>
            );
          })}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CardWhoTraveling;
