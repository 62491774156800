import React from 'react';

import { useTranslation } from 'react-i18next';

const getMessageTranslation = ({ message }) => {
  if (message === 'Invalided Date: checkIn must be greater than today') {
    return 'ErrorApiCheckInMust';
  }

  if (
    [
      `AgentCars We don't have rates available for the selected location. Please select another location`,
      "AgentCars We don't have rates available for the selected location or Time. Please select another location or Time",
    ].includes(message)
  ) {
    return 'ErrorApiNotRatesAvailable';
  }

  return message;
};

const Error = ({ errors }) => {
  const { t } = useTranslation();

  return (
    <div className="jumbotron">
      <h1 className="display-4">Uups!!!</h1>
      <p className="lead">{t('ErrorRequest')}</p>
      <hr className="my-4" />

      {Array.isArray(errors) && errors.length > 0 && (
        <ul className="list-unstyled">
          {errors.map(it => {
            const message = it?.message ?? it;

            return (
              <li key={message}>{t(getMessageTranslation({ message }))}</li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Error;
