import React, { useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import propsMaps from '../../data/sportingEvents/map.json';
import useSportingEvents from '../../hook/useSportingEvents';
import sectionImg from '../../img/sporting-events/nfl_min@2x.png';
import CargandoOpciones from '../../loader/CargandoOpciones';
import FormSidebar from '../../shared/FormSidebar';
import ErrorServer from '../ErrorServer';
import MapSidebar from '../MapSidebar';
import Pagination from '../Pagination';
import SectionTop from '../SectionTop';
import CardSportingEvent from './CardSportingEvent';
import Filters from './Filters';
import FormSearch from './FormSearch';

const List = () => {
  const { t } = useTranslation();
  const { query, getEvents } = useSportingEvents();
  const { path } = useRouteMatch();
  const showTickets = !useRouteMatch({ path, exact: true });

  const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState(propsMaps.center);
  const [position, setPosition] = useState(propsMaps.position);
  const [events, setEvents] = useState([]);
  const [dollar, setDollar] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorServer] = useState(false);
  const [controlhed, setControlhed] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setLoading(false);

    const fetch = async () => {
      if (query) {
        setLoading(true);
        const response = await getEvents(query);
        const { data, control } = response;
        if (control.totalrecords >= 1) {
          setEvents(data);
          setDollar(data.dollarToday);
        }
        setControlhed(control);
        setLoading(false);
      }
    };

    fetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <CargandoOpciones marginTop />;

  return (
    <>
      <SectionTop
        menu={t('Eventos Deportivos', { count: controlhed.totalrecords || 0 })}
        destino={controlhed.title || t('Eventos Deportivos')}
        total={controlhed.totalrecords || 0}
        product={t('Eventos Deportivos', {
          count: controlhed.totalrecords || 0,
        })}
      />

      <Container fluid className="mt-3">
        <Row>
          <Col xs="12" lg="4">
            <Row className="justify-content-md-center">
              <FormSidebar>
                <FormSearch query={query} inline />
              </FormSidebar>
            </Row>

            {showTickets ? (
              <Row className="justify-content-md-center">
                <img src={sectionImg} className="img-fluid" alt="test" />
              </Row>
            ) : (
              <Row className="justify-content-center my-4">
                <Col className="px-4">
                  <MapSidebar
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...propsMaps}
                    containerHeight="911px"
                    position={position}
                    setPosition={setPosition}
                    center={center}
                    setCenter={setCenter}
                  />
                </Col>
              </Row>
            )}

            <Row className="justify-content-center my-4">
              <Col className="px-5">
                <Filters />
              </Col>
            </Row>
          </Col>

          <Col xs="12" lg="8">
            {errorServer ? (
              <ErrorServer />
            ) : (
              <Switch>
                <Route exact path={path}>
                  <Pagination
                    items={events}
                    page={currentPage}
                    onChange={setCurrentPage}
                  >
                    {item => <CardSportingEvent item={item} dolar={dollar} />}
                  </Pagination>
                </Route>
              </Switch>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default List;
