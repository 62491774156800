import React from 'react';

import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CardBookingReview = () => {
  const { t } = useTranslation();

  return (
    <Card className="shadow-sm">
      <Card.Body>
        <Row xs="1">
          <Col>
            <h1>{t('VerificaReservaViaje')}</h1>
          </Col>

          <Col
            lg={{ offset: 9, span: 3 }}
            className="d-flex justify-content-end"
          >
            <Button type="submit" variant="outline-primary" size="lg">
              {t('CompleteReservation')}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CardBookingReview;
