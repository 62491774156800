import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import storage from '../services/storage';

const { REACT_APP_COMPANY_NAME } = process.env;

const authContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    loading: true,
    isPlatinum: false,
    data: null,
  });

  // we will use loading later
  const setAuthData = useCallback(data => {
    const isPlatinum = data?.bin === 44879067;
    const isDemo =
      REACT_APP_COMPANY_NAME === 'Sunset World' &&
      data?.email === 'demo@demo.com';

    setAuth({ loading: false, isPlatinum, isDemo, data });
  }, []);

  useEffect(() => {
    const authFromStorage = storage.get();

    setAuthData(authFromStorage);
  }, [setAuthData]);

  useEffect(() => {
    storage.set(auth.data);
  }, [auth.data]);

  const value = useMemo(
    () => ({
      isPlatinum: auth?.isPlatinum ?? false,
      isDemo: auth?.isDemo ?? false,
      auth,
      setAuthData,
    }),
    [auth, setAuthData],
  );

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  const ctx = useContext(authContext);

  if (!ctx) throw new Error('useAuth must be used within a AuthProvider');

  return ctx;
};

export default AuthProvider;
