import React, { useEffect, useState } from 'react';

import useAppTranslation from '../hook/useAppTranslation';
import get from '../services/get';
import { GET_DESTINIES_FILTER, GET_DESTINY_BY_ID } from '../services/Routes';
import sleep from '../utils/sleep';
import SelectAjax from './SelectAjax';

const SelectDestinations = ({ value, onChange, onBlur }) => {
  const { language } = useAppTranslation();

  const [destiny, setDestiny] = useState(null);

  useEffect(() => {
    if (!value) {
      setDestiny(value);
      return;
    }

    let didCancel = false;
    const set = async () => {
      await sleep(50);
      if (didCancel) return;

      const newDestiny = JSON.parse(JSON.stringify(value));
      newDestiny.label = newDestiny[language] ?? newDestiny.label;
      setDestiny(newDestiny);
    };

    set();
    // eslint-disable-next-line consistent-return
    return () => {
      didCancel = true;
    };
  }, [value, language]);

  const handleChange = newDestiny => {
    if (!onChange) return;

    const newValue = JSON.parse(JSON.stringify(newDestiny));
    const update = async () => {
      if (newValue?.value && newValue?.label) {
        const [en, es] = await Promise.all(
          [1, 2].map(async languageId => {
            const url = GET_DESTINY_BY_ID({
              destinyId: newValue.value,
              language: languageId,
            });
            const res = await get(url);

            if (!res?.data) return newValue.label;

            const { name, code, state } = res.data;
            return `${name ?? code}${
              state?.country?.name?.length ? `, ${state.country.name}` : ''
            }`;
          }),
        );

        newValue.en = en;
        newValue.es = es;
      }

      onChange(newValue);
    };

    update();
  };

  return (
    <SelectAjax
      defaultValue={destiny}
      url={`${GET_DESTINIES_FILTER}?languageId={languageId}&q={inputValue}`}
      params={{
        languageId: language === 'es' ? 2 : 1,
      }}
      isMulti={false}
      handleChange={handleChange}
      handleBlur={onBlur}
      defaultOptions={[destiny]}
      value={destiny}
      valid
      isClearable
    />
  );
};

export default SelectDestinations;
