export default hotels => {
  const { rooms } = hotels[0];
  let result = false;

  rooms.forEach(elem => {
    if (
      elem.paxesInformation.filter(e => e.name === '').length > 0 ||
      elem.paxesInformation.filter(e => e.lastName === '').length > 0
    ) {
      result = true;
    }
  });
  return result;
};
