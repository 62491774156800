import React from 'react';

import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';
import Tooltip from './Tooltip';
import styles from './Rating.module.scss';

const Rating = ({ rating }) => {
  const stars = Math.trunc(rating);
  const isHalf = rating % 1 > 0;

  return (
    <div className={styles.rating}>
      <Tooltip message={rating}>
        <div>
          {Array.from({ length: 5 })
            .fill(0)
            .map((_, i) => {
              if (i < stars)
                return <BsStarFill key={i} className={styles.star} />;
              if (i < stars + 1 && isHalf)
                return <BsStarHalf key={i} className={styles.star} />;

              return <BsStar key={i} className={styles.star} />;
            })}
        </div>
      </Tooltip>
    </div>
  );
};

export default Rating;
