import React from 'react';

import { Card as CardBoostrap, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import PriceFormat from '../PriceFormat';
import RadioButton from '../RadioButton';
import FlightTimeDetail from './FlightTimeDetail';

const CardFlight = ({ itinerary, name }) => {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs="12" lg="3" className="px-0">
            <RadioButton name={name} value={itinerary.id}>
              <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-start">
                <img
                  src={itinerary.airline.image}
                  alt={itinerary.airline.name}
                  height={35}
                />
                <span>{itinerary.airline.name}</span>
              </div>
            </RadioButton>
          </Col>

          <Col>
            <FlightTimeDetail itinerary={itinerary} />
          </Col>

          {itinerary.price && (
            <Col
              xs="12"
              lg="3"
              className="d-flex flex-column align-items-center"
            >
              <h4 className="font-weight-bold text-primary">TOTAL</h4>

              <PriceFormat
                value={parseFloat(itinerary.price.total)}
                currency={itinerary.price.currency}
              >
                {valueText => <h4 className="font-weight-bold">{valueText}</h4>}
              </PriceFormat>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

const Card = styled(CardBoostrap)`
  border: 0px !important;
  border-bottom: 1px solid var(--button-primary) !important;

  &:first-of-type {
    border-top: 1px solid var(--button-primary) !important;
  }
`;

export default CardFlight;
